import { Grips } from "bluetooth/Bluetooth/Grips";

export const MINIMAL_SUPPORTED_FIRMWARE_VERSION = 170;
export const RECENT_FIRMWARE_VERSION = 190;
export const MAXIMAL_BOOTLOADER_FIX_VERSION = 200;

export const GRIPS_POST_17 = [
  Grips.kGripPower,
  Grips.kGripTripodClosed,
  Grips.kGripTripodOpen,
  Grips.kGripPrecisionOpen,
  Grips.kGripPrecisionClosed,
  Grips.kGripTrigger,
  Grips.kGripKey,
  Grips.kGripHook,
  Grips.kGripMouse,
  Grips.kGripFingerPoint,
  Grips.kGripRestOpp,
  Grips.kGripRestNopp,
  Grips.kGripFingerPointOpen,
];

export const OpposedGrips = [
  Grips.kGripPower,
  Grips.kGripTripodOpen,
  Grips.kGripTripodClosed,
  Grips.kGripPrecisionOpen,
  Grips.kGripPrecisionClosed,
  Grips.kGripTrigger,
  Grips.kGripRestOpp,
];

export const NonOpposedGrips = [
  Grips.kGripHook,
  Grips.kGripKey,
  Grips.kGripFingerPoint,
  Grips.kGripFingerPointOpen,
  Grips.kGripMouse,
  Grips.kGripRestNopp,
];

export const FIRMWARE_URL =
  "https://aetherfirmware.s3.eu-central-1.amazonaws.com";
export const ALLOW_MANUAL_UPDATE = true;
