import {
  GripSwitchingModes,
  InputOptions,
  InputSites,
  SpeedControlStrategies,
} from "../bluetooth/Bluetooth/Control";

const { Grips } = require("../bluetooth/Bluetooth/Grips");

const gripsTuples: any = [
  [Grips.kGripPower, { grip: "Power", option: null }],
  [Grips.kGripTripodOpen, { grip: "Tripod", option: "open" }],
  [Grips.kGripTripodClosed, { grip: "Tripod", option: "closed" }],
  [Grips.kGripTrigger, { grip: "Trigger", option: null }],
  [Grips.kGripPrecisionOpen, { grip: "Precision", option: "open" }],
  [Grips.kGripPrecisionClosed, { grip: "Precision", option: "closed" }],
  [Grips.kGripHook, { grip: "Hook", option: null }],
  [Grips.kGripKey, { grip: "Key", option: null }],
  [Grips.kGripFingerPoint, { grip: "FingerPoint", option: null }],
  [Grips.kGripMouse, { grip: "Mouse", option: null }],
  [Grips.kGripCamera, { grip: "Camera", option: null }],
  [Grips.kGripRestOpp, { grip: "Rest opp", option: null }],
  [Grips.kGripRestNopp, { grip: "Open Palm", option: null }],
  [Grips.kGripFingerPointOpen, { grip: "Active Index", option: null }],
];

const gripsOptionsTuples: any = [
  [Grips.kGripPower, "Power"],
  [Grips.kGripTripodOpen, "Tripod Open"],
  [Grips.kGripTripodClosed, "Tripod Closed"],
  [Grips.kGripTrigger, "Trigger"],
  [Grips.kGripPrecisionOpen, "Precision Open"],
  [Grips.kGripPrecisionClosed, "Precision Closed"],
  [Grips.kGripCamera, "Camera"],
  [Grips.kGripHook, "Hook"],
  [Grips.kGripKey, "Key"],
  [Grips.kGripFingerPoint, "Finger Point"],
  [Grips.kGripMouse, "Mouse"],
  [Grips.kGripRestOpp, "Rest opp"],
  [Grips.kGripRestNopp, "Open Palm"],
  [Grips.kGripFingerPointOpen, "Active Index"],
  [Grips.kGripTypeUnknown, "Disabled"],
];

const inputOptionTuples: any = [
  [InputOptions.kEMG, "EMG"],
  [InputOptions.kLinearTransducer, "Linear Tranducer"],
];

const inputSiteTuples: any = [
  [InputSites.kSingleElectrode, "Single"],
  [InputSites.kDualDirectElectrodes, "Dual direct"],
  [InputSites.kDualInvertedElectrodes, "Dual Inverted"],
];

const speedControlTuples: any = [
  [SpeedControlStrategies.kThreshold, "One speed"],
  [SpeedControlStrategies.kProportional, "Proportional"],
];

const gripSwitchingTuples: any = [
  [GripSwitchingModes.kCoContraction, "Co-contraction"],
  [GripSwitchingModes.kOpenOpen, "Open-open"],
  [GripSwitchingModes.kSingleGripSwitching, "Single electrode"],
  [GripSwitchingModes.kHoldOpen, "Hold-open"],
];

const genericSwitchingTuples: any = [
  [0, "Off"],
  [1, "On"],
];

export const Fingers = {
  Thumb: 0,
  Index: 1,
  Middle: 2,
  Ring: 3,
  Pinky: 4,
};

// eslint-disable-next-line no-unused-vars
export const inputOptionsMap: any = new Map(inputOptionTuples);
export const inputSiteMap: any = new Map(inputSiteTuples);
export const speedControlMap: any = new Map(speedControlTuples);
export const gripSwitchingMap: any = new Map(gripSwitchingTuples);
export const genericSwitchingMap: any = new Map(genericSwitchingTuples);

export const inputOptionsReversedMap = new Map(
  Array.from(inputOptionsMap, (a: any) => a.reverse())
);
export const inputSiteReversedMap = new Map(
  Array.from(inputSiteMap, (a: any) => a.reverse())
);
export const speedControlReversedMap = new Map(
  Array.from(speedControlMap, (a: any) => a.reverse())
);
export const gripSwitchingReversedMap = new Map(
  Array.from(gripSwitchingMap, (a: any) => a.reverse())
);
export const genericSwitchingReversedMap = new Map(
  Array.from(genericSwitchingMap, (a: any) => a.reverse())
);

export const gripsGroupsMap = new Map(gripsTuples);
export const gripsGroupsOptionsMap = new Map(gripsOptionsTuples);
export const gripsGroupsOptionsReversedMap = new Map(
  Array.from(gripsGroupsOptionsMap, (a: any) => a.reverse())
);
