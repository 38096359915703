/* eslint-disable no-underscore-dangle */

class TelemetryController {
  static _instance: any;
  private _gripInTransition: boolean = false;

  constructor() {
    if (TelemetryController._instance) {
      return TelemetryController._instance;
    }
    TelemetryController._instance = this;
  }

  public set gripInTransition(value: boolean) {
    this._gripInTransition = value;
  }

  public get gripInTransition() {
    return this._gripInTransition;
  }
}

export default TelemetryController;
