import React, { useEffect, useState } from "react";
import Card from "components/atoms/Card/Card";
import styled from "styled-components";
import DropdownImg from "components/atoms/Dropdown/DropdownImg";
import { GRIPS_POST_17 } from "consts/consts";
import { gripsImagesMap } from "utils/gripsImages";
import {
  gripsGroupsOptionsMap,
  gripsGroupsOptionsReversedMap,
} from "utils/definesLocal";
import { Grips } from "bluetooth/Bluetooth/Grips";
import Button from "components/atoms/Button/Button";
import useTelemetry from "hooks/bluetooth/useTelemetry";
import {
  postCurrentGrip,
  postGripSpeed,
} from "bluetooth-handler/bluetoothFunctions";
import { useSelector } from "react-redux";

export const Viewer = styled.div`
  width: 100%;
  padding: 20px 10px 0 10px;
  margin: 0 auto;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 10px;
`;

const GripsTester = () => {
  const [selectedGrip, setSelectedGrip] = useState<any>(Grips.kGripPower);
  const { prosthesisGrip } = useTelemetry(true);
  const { bluetoothMode } = useSelector((state: any) => state.bluetooth);

  const handleOptions = (option) => {
    const newGrip: any = gripsGroupsOptionsReversedMap.get(option);
    postCurrentGrip(newGrip, bluetoothMode);
  };

  useEffect(() => {
    if (prosthesisGrip !== undefined) {
      setSelectedGrip(prosthesisGrip);
    }
    console.log("Grip change", prosthesisGrip);
  }, [prosthesisGrip]);

  return (
    <Card>
      <DropdownImg
        options={GRIPS_POST_17.map((grip) => ({
          value: gripsGroupsOptionsMap.get(grip),
          img: gripsImagesMap.get(grip),
        }))}
        selected={{
          value: gripsGroupsOptionsMap.get(selectedGrip),
          img: gripsImagesMap.get(selectedGrip),
        }}
        onChange={(option) => handleOptions(option)}
        showImg
        label="Current grip"
      />
      <ButtonsWrapper>
        <Button onClick={() => postGripSpeed(-100, bluetoothMode)}>Open</Button>
        <Button onClick={() => postGripSpeed(100, bluetoothMode)}>Close</Button>
      </ButtonsWrapper>
    </Card>
  );
};

export default GripsTester;
