import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { HeaderM } from "components/atoms/Typography/Typography";
import Modal from "utils/Modal/Modal";
import ModalBase from "./ModalBase";

const HandDetailsWrapper = styled.div`
  width: 350px;
`;

const Header1 = styled.h3`
  ${HeaderM};
`;

const Regular = styled.span`
  font-weight: 400;
`;

const HandDetails = ({ handleClose }) => {
  const { device, versions } = useSelector((state) => state.bluetooth);
  return (
    <Modal>
      <ModalBase handleClick={handleClose} header="Hand details">
        <HandDetailsWrapper>
          <Header1>
            Serial number: <Regular>{device.serialNumber}</Regular>
          </Header1>
          <Header1>
            Firmware version: <Regular>{versions.current}</Regular>
          </Header1>
        </HandDetailsWrapper>
      </ModalBase>
    </Modal>
  );
};

HandDetails.propTypes = {
  handleClose: PropTypes.func.isRequired,
};

export default HandDetails;
