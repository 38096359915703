/* eslint-disable no-unused-vars */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import {
  getAutoGrasp,
  getCoContractionTimings,
  getControlConfig,
  getEmgGains,
  getEmgSpike,
  getEmgThresholds,
  getFingerStrength,
  getFirmwareVersion,
  getFreezeMode,
  getGripLimitPositions,
  getGripPositions,
  getGripsPairsConfig,
  getGripsSequentialConfig,
  getHoldOpen,
  getInterval,
  getPulseTimings,
  getSoftGrip,
  postFingerLimits,
  postInitialGripPositions,
  telemetryEnabled,
} from "bluetooth-handler/bluetoothFunctions";
import { Grips } from "bluetooth/Bluetooth/Grips";
import toast from "react-hot-toast";
import { arraysEqual } from "utils/funcs";
import { defaultConfig } from "consts/deviceConfig/deviceConfig";
import { SpeedControlStrategies } from "bluetooth/Bluetooth/Control";
import { emgThresholdsEntry } from "consts/deviceConfig/deviceConfig.types";

export const compareGripsPositions = (
  apiGripsPositions,
  localGripsPositions
) => {
  let positionsToSentApi = {};
  const localConfigCopy = { ...localGripsPositions };
  const localConfigCopy2 = { ...localConfigCopy };
  for (const key in localGripsPositions) {
    if (Object.prototype.hasOwnProperty.call(localConfigCopy2, key)) {
      const element = localConfigCopy2[key];
      if (
        !arraysEqual(element?.initial, apiGripsPositions[key]?.initial) ||
        (!arraysEqual(element?.limit, apiGripsPositions[key]?.limit) && element)
      ) {
        positionsToSentApi = { ...positionsToSentApi, [key]: element };
      }
      delete localConfigCopy[key];
    }
  }
  positionsToSentApi = { ...positionsToSentApi, ...localConfigCopy };
  return positionsToSentApi;
};

export const compareConfigs = (apiConfig, localConfig) => {
  let configToSentApi = {};
  const localConfigCopy = { ...localConfig };
  delete localConfigCopy.gripsConfiguration;
  const localConfigCopy2 = { ...localConfigCopy };
  for (const key in localConfig) {
    if (Object.prototype.hasOwnProperty.call(localConfigCopy2, key)) {
      const element = localConfigCopy2[key];
      if (!arraysEqual(element, apiConfig[key]) && element) {
        configToSentApi = { ...configToSentApi, [key]: element };
      }
      delete localConfigCopy[key];
    }
  }
  configToSentApi = { ...configToSentApi, ...localConfigCopy };
  const gripsCompared = compareGripsPositions(
    apiConfig.gripsPositions,
    localConfig.gripsPositions
  );
  return { ...configToSentApi, gripsPositions: gripsCompared };
};

export const loadConfig = (state, importConfig) => {
  for (const key in defaultConfig) {
    if (Object.prototype.hasOwnProperty.call(defaultConfig, key)) {
      if (importConfig[key]) {
        state.config[key] = importConfig[key];
      } else {
        state.config[key] = defaultConfig[key];
      }
    }
  }
  delete state.config.freezeMode;
  state.unsaved.initialLoaded = true;
};

export const selectWholeConfig = (state) => state.bluetooth.config;

const getGripsLimits = async (
  gripsList: Grips[],
  mode,
  fetchingPositionsToast
) => {
  const gripsPositionsObject = {};
  let iterator = 0;
  for (const grip of gripsList) {
    const gripPositions = await getGripPositions(grip, mode);
    const gripLimitPositions = await getGripLimitPositions(grip, mode);

    if (gripPositions && gripLimitPositions) {
      gripsPositionsObject[gripPositions[0]] = {
        initial: [
          gripPositions[1],
          gripPositions[2],
          gripPositions[3],
          gripPositions[4],
          gripPositions[5],
        ],
        limit: [
          gripLimitPositions[1],
          gripLimitPositions[2],
          gripLimitPositions[3],
          gripLimitPositions[4],
          gripLimitPositions[5],
        ],
      };
    }
    toast.loading(
      `Fetching grips positions... 3/3 (${iterator + 1}/${gripsList.length})`,
      {
        id: fetchingPositionsToast,
      }
    );
    iterator += 1;
  }
  return gripsPositionsObject;
};

export const getCoreConfig = async (
  bluetoothMode,
  initialConfigToast,
  wasTelemetryOn = false
) => {
  toast.loading("Fetching control config... 1/3", {
    id: initialConfigToast,
  });
  const controlConfig = await getControlConfig(bluetoothMode);
  toast.loading("Fetching prosthesis settings... 2/3", {
    id: initialConfigToast,
  });
  const interval = await getInterval(bluetoothMode);
  const gripPairsConfig = await getGripsPairsConfig(bluetoothMode);
  const gripSequentialConfig = await getGripsSequentialConfig(bluetoothMode);
  let gripSequentialConfigSanitized;
  if (gripSequentialConfig) {
    gripSequentialConfigSanitized = [
      ...gripSequentialConfig.slice(0, 5),
      255,
      ...gripSequentialConfig.slice(6, 11),
      255,
    ];
  }
  const emgThresholds = await getEmgThresholds(bluetoothMode);
  const softGrip = await getSoftGrip(bluetoothMode);
  const holdOpen = await getHoldOpen(bluetoothMode);
  const emgSpike = await getEmgSpike(bluetoothMode);
  const fingerStrength = await getFingerStrength(bluetoothMode);
  const pulseTimings = await getPulseTimings(bluetoothMode);
  const coContractionTimings = await getCoContractionTimings(bluetoothMode);
  const gripsToDownload = [
    Grips.kGripPower,
    Grips.kGripHook,
    Grips.kGripFingerPoint,
    Grips.kGripMouse,
    Grips.kGripKey,
    Grips.kGripTrigger,
    Grips.kGripTripodClosed,
    Grips.kGripPrecisionOpen,
    Grips.kGripRestOpp,
    Grips.kGripRestNopp,
    Grips.kGripPrecisionClosed,
    Grips.kGripTripodOpen,
    Grips.kGripFingerPointOpen,
  ];
  toast.loading("Fetching grips positions... 3/3", {
    id: initialConfigToast,
  });
  const gripsPositions = await getGripsLimits(
    gripsToDownload,
    bluetoothMode,
    initialConfigToast
  );
  const freezeMode = await getFreezeMode(bluetoothMode);
  const emgGains = await getEmgGains(bluetoothMode);
  if (wasTelemetryOn) {
    await telemetryEnabled(true, bluetoothMode);
  }
  toast.remove();

  return {
    controlConfig,
    interval,
    gripPairsConfig,
    gripSequentialConfigSanitized,
    emgThresholds,
    softGrip,
    holdOpen,
    emgSpike,
    fingerStrength,
    gripsPositions,
    freezeMode,
    emgGains,
    pulseTimings,
    coContractionTimings,
  };
};

export const sendFingersConfigHelper = async (
  grip,
  valuesInitial,
  valuesLimit,
  bluetoothMode
) => {
  await postInitialGripPositions(grip, valuesInitial, bluetoothMode);
  await postFingerLimits(grip, valuesLimit, bluetoothMode);
  const gripValuesSent = [...valuesInitial, ...valuesLimit];

  return { gripValuesSent };
};

export const sendAllFingersHelper = async (gripsPositions, bluetoothMode) => {
  for (const grip in gripsPositions) {
    if (Object.prototype.hasOwnProperty.call(gripsPositions, grip)) {
      const gripPositions = gripsPositions[grip];
      await sendFingersConfigHelper(
        grip,
        gripPositions.initial,
        gripPositions.limit,
        bluetoothMode
      );
    }
  }
};

export const checkEmgValidity = (
  speedControlStrategy: any,
  emgSettings: emgThresholdsEntry
) => {
  let speed1Opening = emgSettings[2];
  let speed2Opening = emgSettings[3];
  const speed3Opening = emgSettings[4];
  let speed1Closing = emgSettings[5];
  let speed2Closing = emgSettings[6];
  const speed3Closing = emgSettings[7];
  if (speedControlStrategy === SpeedControlStrategies.kProportional) {
    // Correct wrong values
    if (speed2Opening > speed3Opening) {
      speed2Opening = speed3Opening;
      toast.error(
        "Speed 3 must be bigger than speed 2, check 'Proportional' view in emg settings"
      );
    }
    if (speed1Opening > speed2Opening) {
      speed1Opening = speed2Opening;
      toast.error(
        "Speed 2 must be bigger than speed 1, check 'Proportional' view in emg settings"
      );
    }
    if (speed2Closing > speed3Closing) {
      speed2Closing = speed3Closing;
      toast.error(
        "Speed 3 must be bigger than speed 2, check 'Proportional' view in emg settings"
      );
    }
    if (speed1Closing > speed2Closing) {
      speed1Closing = speed2Closing;
      toast.error(
        "Speed 2 must be bigger than speed 1, check 'Proportional' view in emg settings"
      );
    }
  }
  const validatedEmg = [
    emgSettings[0],
    emgSettings[1],
    speed1Opening,
    speed2Opening,
    speed3Opening,
    speed1Closing,
    speed2Closing,
    speed3Closing,
    emgSettings[8],
    emgSettings[9],
  ];
  return validatedEmg;
};
