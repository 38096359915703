import {
  getBootloaderStatusTimed,
  postActiveMode,
  postCommunicateMode,
} from "bluetooth-handler/bluetoothFunctions";
import { BootloaderStates } from "bluetooth/Bluetooth/Defines";
import { delay } from "bluetooth/Bluetooth/Utilities";
import {
  MINIMAL_SUPPORTED_FIRMWARE_VERSION,
  RECENT_FIRMWARE_VERSION,
} from "consts/consts";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import {
  connectDevice,
  disconnectDevice,
  getFirmwareDevice,
  getInitialConfig,
  getPcbVersionDevice,
} from "reducers/bluetoothReducer/bluetoothHelpers/asyncThunks";
import { setComplexItem } from "reducers/bluetoothReducer/bluetoothReducer";
import { selectIsAfterUpdate } from "reducers/settingsReducer";
import { setItemUI } from "reducers/uiReducer";
import { versionSupport } from "types";
import { numericalFirmwareVersion } from "utils/funcs";

const checkIfUnsupportedFirmware = (firmwareVersionParsed): versionSupport => {
  const firmwareVersion = numericalFirmwareVersion(firmwareVersionParsed);
  if (firmwareVersion < MINIMAL_SUPPORTED_FIRMWARE_VERSION) {
    return versionSupport.unsupported;
  }
  if (firmwareVersion < RECENT_FIRMWARE_VERSION) {
    return versionSupport.outdated;
  }
  return versionSupport.newest;
};

const useBluetooth = () => {
  const isAfterUpdate = useSelector(selectIsAfterUpdate);
  const dispatch = useDispatch();
  const { bluetoothMode } = useSelector((state: any) => state.bluetooth);

  const bluetoothConnect = async () => {
    // @ts-ignore
    const device = await dispatch(connectDevice()).unwrap();
    const connected = device.status;
    if (!connected) return;
    const bootloaderStatus = await getBootloaderStatusTimed(bluetoothMode);
    if (bootloaderStatus[0] !== BootloaderStates.incative) {
      await dispatch(
        setItemUI({ type: "shownGlobalModal", payload: "bootloaderMode" })
      );
      return;
    }
    // @ts-ignore
    const firmwareDevice = await dispatch(getFirmwareDevice()).unwrap();
    await dispatch(getPcbVersionDevice());

    if (connected) {
      const firmwareVersionBluetooth = firmwareDevice.firmwareVersion
        .slice(0, 8)
        .map((item) => String.fromCharCode(item))
        .join("");
      const firmwareVersionBluetoothParsed = `${firmwareVersionBluetooth[1]}.${firmwareVersionBluetooth[4]}.${firmwareVersionBluetooth[7]}`;
      const supportedFirmware = checkIfUnsupportedFirmware(
        firmwareVersionBluetoothParsed
      );
      const numericalFirmware = numericalFirmwareVersion(
        firmwareVersionBluetoothParsed
      );

      if (numericalFirmware >= 170) {
        await postActiveMode(0, bluetoothMode);
        await delay(100);
        await postCommunicateMode(0, bluetoothMode);
        await delay(100);
      }

      await dispatch(getInitialConfig());

      dispatch(
        setComplexItem({
          type: "versions",
          payload: { supported: supportedFirmware },
        })
      );

      if (supportedFirmware !== versionSupport.newest) {
        if (supportedFirmware === versionSupport.unsupported)
          toast(
            `Current firmware version (${firmwareVersionBluetoothParsed}) is not supported, please update to latest version.`,
            { duration: 8000, icon: "⚠️" }
          );
        if (supportedFirmware === versionSupport.outdated)
          toast(
            `Current firmware version (${firmwareVersionBluetoothParsed}) is outdated, some features may not work correctly, please update to latest version.`,
            { duration: 8000, icon: "⚠️" }
          );
      }

      if (isAfterUpdate) {
        dispatch(
          setItemUI({ type: "shownGlobalModal", payload: "after_update" })
        );
      }
    }
  };

  const bluetoothDisconnect = async () => {
    // @ts-ignore
    dispatch(disconnectDevice({}));
  };

  return { bluetoothConnect, bluetoothDisconnect };
};

export default useBluetooth;
