/* eslint-disable no-bitwise */
/* eslint-disable no-unused-vars */
/* eslint-disable prefer-destructuring */
import BluetoothWebController from "bluetooth-handler/bluetoothWeb";
import TelemetryController from "bluetooth-handler/telemetryController";
import { Commands } from "bluetooth/Bluetooth/Defines";
import { delay } from "bluetooth/Bluetooth/Utilities";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import TelemetryParser from "utils/TelemetryParser";

const bluetooth = new BluetoothWebController();
const telemetryParser = new TelemetryParser();
const telemetryController = new TelemetryController();

const useTelemetry = (delayInit: boolean = false) => {
  const telemetryDataRef = useRef({
    graphData: [],
    gripInTransition: false,
  });
  const [prosthesisGrip, setProsthesisGrip] = useState();
  const { device, telemetryInitiated, bluetoothMode, localConfigFetched } =
    useSelector((state: any) => state.bluetooth);

  const parseTelemetry = (telemetryData: any) => {
    const [{ payload: telemetry }] = telemetryData.detail;
    const newData = telemetryParser.parseTelemetry(telemetryData.detail, 500);
    const binaryStates = telemetry[22];
    const newGrip = telemetry[23];
    const newGripInTransition = ((binaryStates >> 3) & 1) === 1;
    if (newGrip !== prosthesisGrip) {
      setProsthesisGrip(newGrip);
    }
    telemetryController.gripInTransition = newGripInTransition;
    // @ts-ignore
    telemetryDataRef.current.graphData = newData;
  };

  const telemetryOn = async () => {
    if (delayInit) {
      await delay(500);
    }
    bluetooth.telemetryOn(bluetoothMode);
    if (!telemetryInitiated) {
      window.addEventListener(
        `received${Commands.kTelemetryData}`,
        parseTelemetry
      );
    }
  };

  const telemetryOff = () => {
    bluetooth.telemetryOff(bluetoothMode);

    window.removeEventListener(
      `received${Commands.kTelemetryData}`,
      parseTelemetry
    );
  };

  // @ts-ignore
  useEffect(() => {
    if (device.connected && bluetooth.connected && localConfigFetched) {
      telemetryOn();
    }

    return async function clean() {
      window.removeEventListener(
        `received${Commands.kTelemetryData}`,
        parseTelemetry
      );
      if (device.connected && bluetooth.connected && localConfigFetched) {
        await telemetryOff();
      }
    };
  }, [localConfigFetched]);

  return {
    prosthesisGrip,
    graphData: telemetryDataRef,
  };
};

export default useTelemetry;
