import { useState } from "react";
import { useDispatch } from "react-redux";
import { setItemUI } from "reducers/uiReducer";
import { changeRoute } from "utils/funcs";

const useAuthorize = ({ history }) => {
  const [user, setUser] = useState();
  const [password, setPassword] = useState();
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const dispatch = useDispatch();

  const authorize = (e) => {
    e.preventDefault();
    if (user === "clinician" && password === "Zeus2021") {
      dispatch(setItemUI({ type: "isAuthorized", payload: true }));
      dispatch(setItemUI({ type: "shownGlobalModal", payload: null }));
      changeRoute(history, "/service-menu");
    } else {
      setShowError(true);
      setErrorMessage("Wrong username or password");
    }
  };

  const abortAuthorization = () => {
    setShowError(false);
    dispatch(setItemUI({ type: "shownGlobalModal", payload: null }));
  };

  return {
    setUser,
    setPassword,
    authorize,
    abortAuthorization,
    showError,
    errorMessage,
  };
};

export default useAuthorize;
