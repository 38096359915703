import { css } from "styled-components";

const HeaderXL = css`
  font-size: ${({ theme }) => theme.typography.fontSizes.m};
  color: ${({ color }) => color || "#000"};
  font-weight: ${({ theme }) => theme.typography.fontWeights.bold};
  font-family: ${({ theme }) => theme.typography.fonts.OpenSans};

  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    font-size: ${({ theme }) => theme.typography.fontSizes.l};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
    font-size: ${({ theme }) => theme.typography.fontSizes.xl};
  }
`;

const HeaderL = css`
  font-size: ${({ theme }) => theme.typography.fontSizes.s};
  color: ${({ color }) => color || "#000"};
  font-weight: ${({ theme }) => theme.typography.fontWeights.bold};
  font-family: ${({ theme }) => theme.typography.fonts.OpenSans};

  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    font-size: ${({ theme }) => theme.typography.fontSizes.m};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
    font-size: ${({ theme }) => theme.typography.fontSizes.l};
  }
`;

const HeaderM = css`
  font-size: ${({ theme }) => theme.typography.fontSizes.xs};
  color: ${({ color }) => color || "#000"};
  font-weight: ${({ theme }) => theme.typography.fontWeights.semiBold};
  font-family: ${({ theme }) => theme.typography.fonts.OpenSans};

  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    font-size: ${({ theme }) => theme.typography.fontSizes.s};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
    font-size: ${({ theme }) => theme.typography.fontSizes.m};
  }
`;

const HeaderS = css`
  font-size: ${({ theme }) => theme.typography.fontSizes.xxs};
  font-weight: ${({ theme }) => theme.typography.fontWeights.semiBold};
  font-family: ${({ theme }) => theme.typography.fonts.OpenSans};

  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    font-size: ${({ theme }) => theme.typography.fontSizes.xs};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
    font-size: ${({ theme }) => theme.typography.fontSizes.s};
  }
`;

const TextM = css`
  font-size: ${({ theme }) => theme.typography.fontSizes.xs};
  font-weight: ${({ theme }) => theme.typography.fontWeights.regular};
  font-family: ${({ theme }) => theme.typography.fonts.OpenSans};

  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    font-size: ${({ theme }) => theme.typography.fontSizes.s};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
    font-size: ${({ theme }) => theme.typography.fontSizes.m};
  }
`;

const TextS = css`
  font-size: ${({ theme }) => theme.typography.fontSizes.xxs};
  font-weight: ${({ theme }) => theme.typography.fontWeights.regular};
  font-family: ${({ theme }) => theme.typography.fonts.OpenSans};

  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    font-size: ${({ theme }) => theme.typography.fontSizes.xs};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
    font-size: ${({ theme }) => theme.typography.fontSizes.s};
  }
`;

const TextXS = css`
  font-size: ${({ theme }) => theme.typography.fontSizes.xxs};
  font-weight: ${({ theme }) => theme.typography.fontWeights.regular};
  font-family: ${({ theme }) => theme.typography.fonts.OpenSans};

  @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
    font-size: ${({ theme }) => theme.typography.fontSizes.xs};
  }
`;

export { HeaderXL, HeaderL, HeaderM, HeaderS, TextM, TextS, TextXS };
