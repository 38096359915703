import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

const AuthRoute = ({ children }) => {
  const { physician } = useSelector((state: any) => state.bluetooth);
  const history = useHistory();

  if (!physician) {
    history.push("/login");
  }

  return children;
};

export default AuthRoute;
