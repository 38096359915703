import React from "react";
import { NavLink, useHistory } from "react-router-dom";
import styled, { css } from "styled-components";
import { ReactComponent as HandRock } from "assets/hand-rock-solid.svg";
import { ReactComponent as ChartLine } from "assets/chart-line-solid.svg";
import { ReactComponent as Microchip } from "assets/microchip-solid.svg";
import { ReactComponent as Wrench } from "assets/wrench-solid.svg";
import { ReactComponent as HandPaper } from "assets/hand-paper-regular.svg";
import { ReactComponent as Clipboard } from "assets/clipboard-list-solid.svg";
import { ReactComponent as SignIn } from "assets/sign-in-alt-solid.svg";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { HeaderM, HeaderS } from "components/atoms/Typography/Typography";
import { setItemUI } from "reducers/uiReducer";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";

const Wrapper = styled.nav`
  width: 100%;
  a {
    text-decoration: none;
  }
  z-index: 1;
`;

const List = styled.ul`
  display: grid;
  grid-auto-flow: row;
`;

const InnerItem = styled.div`
  display: flex;
  margin: ${({ collapsed }) => (collapsed ? "0 auto" : "0 0 0 18px")};
  align-items: center;

  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    margin: ${({ collapsed }) => (collapsed ? "0 auto" : "0 0 0 28px")};
  }
`;

const Item = styled.li`
  display: flex;
  align-items: center;
  color: #fff;
  cursor: pointer;
  padding: 5px 0;

  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    padding: 7px 0;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
    padding: 10px 0;
  }

  &.active {
    background-color: ${({ theme }) => theme.colorSecondary};
  }
`;

const StyledIcon = css`
  width: ${({ collapsed }) => (collapsed ? "20px" : "10px")};

  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    width: 20px;
  }
`;

const StyledHandPaper = styled(HandPaper)`
  ${StyledIcon}
`;

const StyledChartLine = styled(ChartLine)`
  ${StyledIcon}
`;

const StyledHandRock = styled(HandRock)`
  ${StyledIcon}
`;

const StyledMicrochip = styled(Microchip)`
  ${StyledIcon}
`;

const StyledWrench = styled(Wrench)`
  ${StyledIcon}
`;

const StyledClipboard = styled(Clipboard)`
  ${StyledIcon}
`;

const StyledSignIn = styled(SignIn)`
  ${StyledIcon}
`;

const ItemText = styled.p`
  ${HeaderS};
  margin-left: 15px;
`;

const HeaderItem = styled(Item)`
  cursor: auto;
`;

const HeaderItemText = styled.p`
  ${HeaderM};
  color: #fff;
  cursor: auto;
`;

const Navbar = ({ collapsed }) => {
  const { t } = useTranslation();
  const { device, physician } = useSelector((state) => state.bluetooth);
  const { isAuthorized } = useSelector((state) => state.ui);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleServiceMenuRoute = (e) => {
    e.preventDefault();
    if (!isAuthorized) {
      dispatch(setItemUI({ type: "shownGlobalModal", payload: "authorize" }));
    } else {
      history.push("/service-menu");
    }
  };

  return (
    <Wrapper>
      <List>
        {!collapsed && !physician && (
          <HeaderItem>
            <InnerItem collapsed={collapsed}>
              <HeaderItemText>Patient View</HeaderItemText>
            </InnerItem>
          </HeaderItem>
        )}
        <Item
          as={NavLink}
          to="/choose-grips"
          activeClassName="active"
          data-tour-general="sidebar-grips"
        >
          <InnerItem collapsed={collapsed}>
            <StyledHandPaper collapsed={collapsed} />
            {!collapsed && <ItemText>{t("navbar.choose_grips")}</ItemText>}
          </InnerItem>
        </Item>
        <Item
          as={NavLink}
          to="/graph"
          activeClassName="active"
          data-tour-general="sidebar-emg"
        >
          <InnerItem collapsed={collapsed}>
            <StyledChartLine collapsed={collapsed} />
            {!collapsed && <ItemText>{t("navbar.emg_settings")}</ItemText>}
          </InnerItem>
        </Item>
        {physician && (
          <Item
            as={NavLink}
            to="/grips-configuration"
            activeClassName="active"
            data-tour-general="sidebar-grips-config"
          >
            <InnerItem collapsed={collapsed}>
              <StyledHandRock collapsed={collapsed} />
              {!collapsed && (
                <ItemText>{t("navbar.grips_configuration")}</ItemText>
              )}
            </InnerItem>
          </Item>
        )}
        <Item
          as={NavLink}
          to="/prosthesis-settings"
          activeClassName="active"
          data-tour-general="sidebar-settings"
        >
          <InnerItem collapsed={collapsed}>
            <StyledClipboard collapsed={collapsed} />
            {!collapsed && (
              <ItemText>{t("navbar.prosthesis_settings")}</ItemText>
            )}
          </InnerItem>
        </Item>
        {physician && (
          <Item
            data-tour-general="sidebar-firmware"
            onClick={
              device.connected
                ? () =>
                    dispatch(
                      setItemUI({
                        type: "shownGlobalModal",
                        payload: "firmware",
                      })
                    )
                : () => toast.error("Device not connected")
            }
          >
            <InnerItem collapsed={collapsed}>
              <StyledMicrochip collapsed={collapsed} />
              {!collapsed && <ItemText>{t("navbar.update_firmware")}</ItemText>}
            </InnerItem>
          </Item>
        )}
        {physician && (
          <Item
            as={NavLink}
            to="/service-menu"
            onClick={handleServiceMenuRoute}
            activeClassName="active"
          >
            <InnerItem collapsed={collapsed}>
              <StyledWrench collapsed={collapsed} />
              {!collapsed && <ItemText>{t("navbar.service_menu")}</ItemText>}
            </InnerItem>
          </Item>
        )}

        <Item
          as={NavLink}
          to="/login"
          activeClassName="active"
          data-tour-general="sidebar-login"
        >
          <InnerItem collapsed={collapsed}>
            <StyledSignIn collapsed={collapsed} />
            {!collapsed && (
              <ItemText>
                {physician ? t("navbar.logout") : t("navbar.login")}
              </ItemText>
            )}
          </InnerItem>
        </Item>
      </List>
    </Wrapper>
  );
};

Navbar.propTypes = {
  collapsed: PropTypes.bool.isRequired,
};

export default Navbar;
