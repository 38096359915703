import React from "react";
import styled from "styled-components";
import ServicePositions from "components/molecules/ServicePositions/ServicePositions";
import DefaultLayout from "layouts/DefaultLayout";
import { HeaderXL } from "components/atoms/Typography/Typography";
import GripsTester from "components/molecules/GripsTester/GripsTester";

const Header1 = styled.h2`
  ${HeaderXL};
  color: ${({ theme }) => theme.colorPrimary};
  margin-bottom: 20px;

  @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
    margin-bottom: 40px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

const GripsTesterWrapper = styled.div`
  max-width: 400px;
`;

const ServiceMenu = () => (
  <DefaultLayout>
    <Header1>Service menu</Header1>
    <Wrapper>
      <ServicePositions />
      <GripsTesterWrapper>
        <GripsTester />
      </GripsTesterWrapper>
    </Wrapper>
  </DefaultLayout>
);

export default ServiceMenu;
