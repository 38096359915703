/* eslint-disable class-methods-use-this */
/* eslint-disable lines-between-class-members */
/* eslint-disable no-bitwise */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
import BluetoothController from "./bluetoothInterface";
import BluetoothWebControllerLE from "./bluetoothLE";
import BluetoothWebControllerSerial from "./bluetoothSerial";

const bluetoothLE = new BluetoothWebControllerLE();
const bluetoothSerial = new BluetoothWebControllerSerial();

class BluetoothWebController implements BluetoothController {
  static _instance: any;
  connected: boolean = false;
  telemetryEnabled: boolean = false;
  device: any;
  controller: any;

  constructor() {
    if (BluetoothWebController._instance) {
      return BluetoothWebController._instance;
    }
    BluetoothWebController._instance = this;
    this.connected = false;
  }

  async initiateBluetooth(mode = "classic"): Promise<boolean> {
    let result;
    if (mode === "classic") {
      result = await bluetoothSerial.initiateBluetooth();
    } else {
      const resultble = await bluetoothLE.initiateBluetooth();
      result = resultble.status;
      this.device = resultble.device;
    }
    this.connected = true;
    this.controller = new AbortController();
    return result;
  }

  async disconnectBluetooth(mode = "classic") {
    let status = false;
    if (this.controller) {
      this.controller.abort();
    }
    if (mode === "classic") {
      status = await bluetoothSerial.disconnectBluetooth();
    } else {
      status = await bluetoothLE.disconnectBluetooth();
    }
    this.connected = false;
    return status;
  }

  async readWeb(mode = "classic") {
    if (mode === "classic") {
      bluetoothSerial.readWeb();
    } else {
      bluetoothLE.readWeb();
    }
    return true;
  }

  async writeWeb(command, commandData: number[], mode = "classic") {
    let status;
    if (mode === "classic") {
      status = await bluetoothSerial.writeWeb(command, commandData);
    } else {
      status = await bluetoothLE.writeWeb(command, commandData);
    }
    return status;
  }

  async queryResponseCommand(
    command,
    commandData,
    commandAwaited,
    mode = "classic"
  ): Promise<any> {
    let response;
    if (mode === "classic") {
      response = await bluetoothSerial.queryResponseCommand(
        command,
        commandData,
        commandAwaited
      );
    } else {
      response = await bluetoothLE.queryResponseCommand(
        command,
        commandData,
        commandAwaited
      );
    }
    return response;
  }

  async telemetryOn(mode = "classic") {
    let status;
    this.telemetryEnabled = true;
    if (mode === "classic") {
      status = await bluetoothSerial.telemetryOn();
    } else {
      status = await bluetoothLE.telemetryOn();
    }
    return status;
  }

  async telemetryOff(mode = "classic") {
    let status;
    this.telemetryEnabled = false;
    if (mode === "classic") {
      status = await bluetoothSerial.telemetryOff();
    } else {
      status = await bluetoothLE.telemetryOff();
    }
    return status;
  }
}

export default BluetoothWebController;
