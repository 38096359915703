/* eslint-disable prefer-destructuring */
import {
  ControlModes,
  GripSwitchingModes,
  InputSites,
  SpeedControlStrategies,
} from "bluetooth/Bluetooth/Control";
import { Fingers } from "bluetooth/Bluetooth/Defines";
import { Grips } from "bluetooth/Bluetooth/Grips";
import toast from "react-hot-toast";
import { PayloadAction } from "@reduxjs/toolkit";
import { defaultConfig } from "consts/deviceConfig/deviceConfig";
import {
  gripSequentialConfigEntry,
  gripPairsConfigEntry,
  emgThresholdsEntry,
  emgGainsEntry,
} from "consts/deviceConfig/deviceConfig.types";
import { checkEmgValidity, loadConfig } from "./bluetoothHelpers";

export const resetGripsConfigurationReducer = (state) => {
  state.config.gripsPositions = defaultConfig.gripsPositions;
  toast.success("Default grips configuration restored");
};

export const resetGripConfigurationReducer = (
  state,
  action: PayloadAction<{ grip: Grips }>
) => {
  state.config.gripsPositions[action.payload.grip] =
    defaultConfig.gripsPositions[action.payload.grip];
  toast.success("Default grip configuration restored");
};

export const importConfigReducer = (state, action: PayloadAction<any>) => {
  loadConfig(state, action.payload.config);
  state.unsaved.unsavedChanges = true;
  toast.success("Config imported");
};

export const setControlConfigReducer = (
  state,
  action: PayloadAction<{ payload: any }>
) => {
  const newControlConfig = action.payload.payload;
  const oldInputSite = state.config.controlConfig[1];
  let newInputSite = newControlConfig[1];
  let newGripSwitchingMode = newControlConfig[4];

  if (newInputSite === InputSites.kSingleElectrode) {
    newGripSwitchingMode = GripSwitchingModes.kSingleGripSwitching;
  } else if (oldInputSite === InputSites.kSingleElectrode) {
    newGripSwitchingMode = GripSwitchingModes.kCoContraction;
  }
  if (newGripSwitchingMode === GripSwitchingModes.kSingleGripSwitching) {
    newInputSite = InputSites.kSingleElectrode;
  }
  const newEmg = checkEmgValidity(
    newControlConfig[3],
    state.config.emgThresholds
  );

  state.config.controlConfig = [
    newControlConfig[0],
    newInputSite,
    newControlConfig[2],
    newControlConfig[3],
    newGripSwitchingMode,
  ];
  state.config.emgThresholds = newEmg;
};

export const consumeHistoryProsthesisSettingsReducer = (state) => {
  const providerLength = state.prosthesisSettingsHistory.length;
  if (providerLength > 0) {
    const providerCopy = state.prosthesisSettingsHistory;
    const providerCopyLastElement = providerCopy.pop();
    if (providerCopyLastElement) {
      const [
        newControlConfig,
        newEMGSpike,
        newHoldOpen,
        newSoftGrip,
        newInterval,
        newPulseTimings,
        newCoContractionTimings,
      ] = providerCopyLastElement;
      state.config.controlConfig = newControlConfig;
      state.config.emgSpike = newEMGSpike;
      state.config.holdOpen = newHoldOpen;
      state.config.softGrip = newSoftGrip;
      state.config.interval = newInterval;
      state.config.pulseTimings = newPulseTimings;
      state.config.coContractionTimings = newCoContractionTimings;
    }
  }
};

export const consumeHistoryEmgSettingsReducer = (state) => {
  const providerLength = state.emgSettingsHistory.length;
  if (providerLength > 0) {
    const providerCopy = state.emgSettingsHistory;
    const providerCopyLastElement = providerCopy.pop();
    if (providerCopyLastElement) {
      const { emgThresholds, emgGains } = providerCopyLastElement;
      state.config.emgThresholds = emgThresholds;
      state.config.emgGains = emgGains;
    }
  }
};

export const consumeHistoryChooseGripsReducer = (state) => {
  const providerLength = state.chooseGripsHistory.length;
  if (providerLength > 0) {
    const providerCopy = state.chooseGripsHistory;
    const providerCopyLastElement = providerCopy.pop();
    if (providerCopyLastElement) {
      const { gripPairsConfig, controlConfig, gripSequentialConfig } =
        providerCopyLastElement;
      state.config.gripPairsConfig = gripPairsConfig;
      state.config.controlConfig = controlConfig;
      state.config.gripSequentialConfig = gripSequentialConfig;
    }
  }
};

export const consumeHistoryGripsConfigurationReducer = (state) => {
  const providerLength =
    state.gripsConfiguration.gripsConfigurationHistory.length;
  if (providerLength > 0) {
    const providerCopy = state.gripsConfiguration.gripsConfigurationHistory;
    const providerCopyLastElement = providerCopy.pop();
    if (providerCopyLastElement) {
      const { selectedGrip, values } = providerCopyLastElement;
      if (selectedGrip !== Grips.kGripTypeUnknown) {
        state.gripsConfiguration.currentGrip = selectedGrip;
        state.config.gripsPositions = values;
      }
    }
  }
};

export const toggleComplexItemReducer = (
  state,
  action: PayloadAction<{ type: string; item: string }>
) => {
  const newState = {
    [action.payload.item]: !state[action.payload.type][action.payload.item],
  };
  state[action.payload.type] = {
    ...state[action.payload.type],
    ...newState,
  };
};

export const addHistoryReducer = (
  state,
  action: PayloadAction<{ type: string; payload: any }>
) => {
  const providerCopy = state[action.payload.type];
  providerCopy.push(action.payload.payload);
  state[action.payload.type] = providerCopy;
};

export const addHistoryComplexReducer = (
  state,
  action: PayloadAction<{ type: string; nestedType: string; payload: any }>
) => {
  const providerCopy = state[action.payload.type][action.payload.nestedType];
  providerCopy.push(action.payload.payload);
  state[action.payload.type][action.payload.nestedType] = providerCopy;
};

export const consumeHistoryReducer = (state, action) => {
  const providerLength = state[action.payload.provider].length;
  if (providerLength > 0) {
    const providerCopy = state[action.payload.provider];
    const providerCopyLastElement = providerCopy.pop();
    state[action.payload.consumer] = {
      ...state[action.payload.consumer],
      ...providerCopyLastElement,
    };
    state[action.payload.provider] = state[action.payload.provider].slice(
      0,
      providerLength - 1
    );
  }
};

export const setUnsavedReducer = (
  state: any,
  action: PayloadAction<{ type: string; payload: any }>
) => {
  if (state.device.connected) {
    state[action.payload.type] = {
      ...state[action.payload.type],
      ...action.payload.payload,
    };
  }
};

export const setGripsSequentialReducer = (
  state: any,
  action: PayloadAction<gripSequentialConfigEntry>
) => {
  state.config.gripSequentialConfig = action.payload;
};

export const setGripsPairsReducer = (
  state: any,
  action: PayloadAction<gripPairsConfigEntry>
) => {
  state.config.gripPairsConfig = action.payload;
};

export const setGripsPositionsReducer = (
  state: any,
  action: PayloadAction<any>
) => {
  state.config.gripsPositions = action.payload;
};

export const setEmgThresholdsReducer = (
  state: any,
  action: PayloadAction<emgThresholdsEntry>
) => {
  const newEmg = checkEmgValidity(
    state.config.controlConfig[3],
    action.payload
  );
  state.config.emgThresholds = newEmg;
};

export const setEmgGainsReducer = (
  state: any,
  action: PayloadAction<emgGainsEntry>
) => {
  state.config.emgGains = action.payload;
};

export const setInputSitesReducer = (
  state: any,
  action: PayloadAction<InputSites>
) => {
  state.config.controlConfig[1] = action.payload;
};

export const setControlModesReducer = (
  state: any,
  action: PayloadAction<ControlModes>
) => {
  state.config.controlConfig[2] = action.payload;
};

export const setSpeedControlStrategyReducer = (
  state: any,
  action: PayloadAction<SpeedControlStrategies>
) => {
  state.config.controlConfig[3] = action.payload;
};

export const setGripSwitchingModesReducer = (
  state: any,
  action: PayloadAction<GripSwitchingModes>
) => {
  state.config.controlConfig[4] = action.payload;
};

export const setIntervalReducer = (
  state: any,
  action: PayloadAction<number>
) => {
  state.config.interval = [action.payload];
};

export const setSoftGripReducer = (
  state: any,
  action: PayloadAction<0 | 1>
) => {
  state.config.softGrip = [action.payload];
};

export const setFingerStrengthReducer = (
  state: any,
  action: PayloadAction<{ finger: Fingers; strength: number }>
) => {
  state.config.fingerStrength = [
    action.payload.finger,
    action.payload.strength,
  ];
};

export const setEmgSpikeReducer = (
  state: any,
  action: PayloadAction<{ status: 0 | 1; time: number }>
) => {
  state.config.emgSpike = [action.payload.status, action.payload.time];
};

export const setAutograspReducer = (
  state: any,
  action: PayloadAction<{ status: 0 | 1; current: number }>
) => {
  state.config.autoGrasp = [action.payload.status, action.payload.current];
};

export const setHoldOpenReducer = (
  state: any,
  action: PayloadAction<{ short: number; long: number }>
) => {
  state.config.holdOpen = [action.payload.short, action.payload.long];
};

export const setPulseTimingsReducer = (
  state: any,
  action: PayloadAction<{
    minimumPulse: number;
    maximumPulse: number;
    minimumBetweenPulses: number;
    maximumBetweenPulses: number;
  }>
) => {
  state.config.pulseTimings = [
    action.payload.minimumPulse,
    action.payload.maximumPulse,
    action.payload.minimumBetweenPulses,
    action.payload.maximumBetweenPulses,
  ];
};

export const setCoContractionTimingsReducer = (
  state: any,
  action: PayloadAction<{
    time: number;
    offset: number;
  }>
) => {
  state.config.coContractionTimings = [
    action.payload.time,
    action.payload.offset,
  ];
};
