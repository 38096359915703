import React from "react";
import styled from "styled-components";
import RadioInput from "components/atoms/RadioInput/RadioInput";
import uniqid from "uniqid";
import { HeaderM, TextXS } from "components/atoms/Typography/Typography";
import { DisableFunctionality } from "utils/disableFunctionalityModifiier";

const StyledCard = styled.div`
  display: grid;
  grid-template-columns: 70% 30%;
`;

const Header = styled.h3`
  ${HeaderM};
  color: ${({ theme }) => theme.typography.colors.secondary};
`;
const Description = styled.p`
  ${TextXS};
  width: 60%;
  color: ${({ theme }) => theme.typography.colors.subtitle};
  margin-top: 15px;
  margin-left: 10px;

  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    margin-top: 15px;
    margin-left: 20px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
    margin-top: 15px;
    margin-left: 30px;
  }
`;

const OptionsList = styled.ul`
  display: grid;
  grid-gap: 10px;
  ${({ disable }) => (disable ? DisableFunctionality : true)}
`;

type InputOptionsProps = {
  header: string;
  options: string[];
  id: string;
  onChange?: Function;
  value: string;
  description?: string;
  disable?: boolean;
};

const InputOptions = ({
  header,
  options,
  id,
  onChange = () => true,
  value,
  description = "",
  disable = false,
  ...props
}: InputOptionsProps) => {
  const updateSelectedOption = (option) => {
    onChange(id, option);
  };

  return (
    <StyledCard padding="20px" {...props}>
      <div>
        <Header>{header}</Header>
        {description && <Description>{description}</Description>}
      </div>
      <OptionsList disable={disable}>
        {options.map((option) => (
          <RadioInput
            key={uniqid()}
            // eslint-disable-next-line eqeqeq
            checked={value == option}
            onClick={() => updateSelectedOption(option)}
            as="li"
            label={option}
            id={option}
            name={option}
          />
        ))}
      </OptionsList>
    </StyledCard>
  );
};

export default InputOptions;
