import { HeaderL, HeaderXL } from "components/atoms/Typography/Typography";
import styled from "styled-components";

export const InnerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
    margin-bottom: 40px;
  }
`;

export const Header1 = styled.h2`
  ${HeaderXL};
  color: ${({ theme }) => theme.colorPrimary};
  margin-bottom: 20px;

  @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
    margin-bottom: 40px;
  }
`;

export const Header2Wrapper = styled.div`
  margin-bottom: 20px;

  @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
    margin-bottom: 40px;
  }
`;

export const Header2 = styled.h3`
  ${HeaderL};
  color: ${({ theme }) => theme.colorSecondary};
`;
