import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Button from "components/atoms/Button/Button";
import Divider from "components/atoms/Divider/Divider";
import FormField from "components/atoms/FormField/FormField";
import Modal from "utils/Modal/Modal";
import ModalBase from "./ModalBase";

const StyledModalBase = styled(ModalBase)`
  width: 450px;
`;

const AuthorizeModal = ({
  handleAbort,
  handleUser,
  handlePassword,
  handleAuthorize,
  showError,
  errorMessage,
}) => (
  <Modal>
    <StyledModalBase header="Authorization" handleClick={handleAbort}>
      <form onSubmit={handleAuthorize}>
        <Divider margin="20px" />
        <FormField
          onChange={handleUser}
          name="user"
          label="User"
          type="text"
          showError={showError}
          errorMessage={errorMessage}
        />
        <Divider margin="10px" />
        <FormField
          onChange={handlePassword}
          name="password"
          label="Password"
          type="password"
          showError={showError}
          errorMessage={errorMessage}
        />
        <Divider margin="20px" />
        <Button type="submit">Enter</Button>
      </form>
    </StyledModalBase>
  </Modal>
);

AuthorizeModal.defaultProps = {
  showError: false,
  errorMessage: "",
};

AuthorizeModal.propTypes = {
  handleAbort: PropTypes.func.isRequired,
  handleUser: PropTypes.func.isRequired,
  handlePassword: PropTypes.func.isRequired,
  handleAuthorize: PropTypes.func.isRequired,
  showError: PropTypes.bool,
  errorMessage: PropTypes.string,
};

export default AuthorizeModal;
