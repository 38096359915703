import { configureStore } from "@reduxjs/toolkit";
import bluetoothReducer from "reducers/bluetoothReducer/bluetoothReducer";
import settingsReducer from "reducers/settingsReducer";
import uiReducer from "reducers/uiReducer";
import versionsReducer from "reducers/versionsReducer";
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistSettingsConfig = {
  key: "settings",
  version: 1,
  storage,
};

const persistUiConfig = {
  key: "ui",
  storage,
  blacklist: ["shownGlobalModal"],
};

const persistBluetoothConfig = {
  key: "bluetooth",
  storage,
  whitelist: ["bluetoothMode", "physician"],
};

const persistedSettingsReducer = persistReducer(
  persistSettingsConfig,
  settingsReducer
);
const persistedUiReducer = persistReducer(persistUiConfig, uiReducer);
const persistedBluetoothReducer = persistReducer(
  persistBluetoothConfig,
  bluetoothReducer
);

export default configureStore({
  reducer: {
    bluetooth: persistedBluetoothReducer,
    ui: persistedUiReducer,
    versions: versionsReducer,
    settings: persistedSettingsReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});
