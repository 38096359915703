import React, { useState, useEffect } from "react";
import styled from "styled-components";
import FormField from "components/atoms/FormField/FormField";
import LoginPhoto from "assets/login-photo.jpg";
import Button from "components/atoms/Button/Button";
import AetherLogo from "assets/aether_logo_dark.png";
import { useDispatch } from "react-redux";
import { setItem } from "reducers/bluetoothReducer/bluetoothReducer";
import { useHistory } from "react-router-dom";
import Error from "components/atoms/Error/Error";

const Background = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
`;

const Wrapper = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
`;

const Logo = styled.img`
  width: 200px;
  margin: 60px 0;
`;

const Header = styled.h1`
  width: 100%;
  margin-bottom: 20px;
  font-weight: 600;
  font-size: 28px;
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
  width: 350px;
  padding: 0 30px;
`;

const ImageWrapper = styled.div`
  flex: 1;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const ButtonsWrapper = styled.div`
  margin-top: 20px;
  display: grid;
  grid-gap: 20px;
  width: 100%;
`;

const CustomError = styled(Error)`
  margin-top: 20px;
  width: 100%;
`;

const Login = () => {
  // eslint-disable-next-line no-unused-vars
  const [password, setPassword] = useState(null);
  const [showError, setShowError] = useState(false);
  const [showBrowserError, setShowBrowserError] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    const browserAgent = window.navigator.userAgent.toLowerCase();
    const browserDetect = (agent) => {
      switch (true) {
        case agent.indexOf("edge") > -1:
          return false;
        case agent.indexOf("edg") > -1:
          return true;
        case agent.indexOf("opr") > -1 && !!window.opr:
          return false;
        case agent.indexOf("chrome") > -1 && !!window.chrome:
          return true;
        case agent.indexOf("trident") > -1:
          return false;
        case agent.indexOf("firefox") > -1:
          return false;
        case agent.indexOf("safari") > -1:
          return false;
        default:
          return false;
      }
    };
    if (!browserDetect(browserAgent)) {
      setShowBrowserError(true);
    }
  }, []);

  useEffect(() => {
    const image = document.querySelector("#login-photo");
    image.src = LoginPhoto;
  }, []);

  const login = () => {
    history.push("/choose-grips");
  };

  const checkKey = (e) => {
    if (e.code === "Enter") {
      authorizePhysician();
    }
  };

  const authorizePhysician = () => {
    if (password === "Zeus2021") {
      dispatch(setItem({ type: "physician", payload: true }));
      login();
    } else {
      setShowError(true);
    }
  };

  return (
    <Background>
      <Wrapper>
        <FormWrapper onKeyPress={checkKey}>
          <Logo src={AetherLogo} alt="Aether Biomedical logo" />
          <Header>Login</Header>
          <FormField
            label="Password"
            name="password"
            type="password"
            onChange={(e) => setPassword(e.target.value)}
            showError={showError}
            errorMessage="Wrong password"
          />
          <ButtonsWrapper>
            <Button onClick={authorizePhysician}>Next</Button>
          </ButtonsWrapper>
          {showBrowserError && (
            <CustomError>
              Your browser doesn&apos;t support web bluetooth, please use latest
              Chrome or Edge.
            </CustomError>
          )}
        </FormWrapper>
        <ImageWrapper>
          <img id="login-photo" alt="Zeus hand" />
        </ImageWrapper>
      </Wrapper>
    </Background>
  );
};

export default Login;
