import React, { useEffect } from "react";
import {
  Switch,
  Route,
  Redirect,
  useLocation,
  useHistory,
} from "react-router-dom";
import useAuthorize from "hooks/useAuthorize";
import { useLastLocation } from "react-router-last-location";
import AuthorizeModal from "components/molecules/Modals/AuthrorizeModal";
import FirmwareModal from "components/molecules/Modals/FirmwareModal";
import { useDispatch, useSelector } from "react-redux";
import { selectShownGlobalModalUI, setItemUI } from "reducers/uiReducer";
import About from "components/molecules/Modals/AboutModal";
import HandDetails from "components/molecules/Modals/HandDetails";
import VersionsModal from "components/molecules/Modals/VersionsModal";
import AfterUpdate from "components/molecules/Modals/AfterUpdateModal";
import BootloaderController from "bluetooth-handler/bootloaderController";
import BootloaderModeModal from "components/molecules/Modals/BootloaderModeModal";
import useBluetooth from "hooks/bluetooth/useConnect";
import AuthRoute from "hoc/AuthRoute";
import ChooseGrips from "./ChooseGrips/ChooseGrips";
import ProsthesisSettings from "./ProsthesisSettings/ProsthesisSettings";
import ApplicationSettings from "./ApplicationSettings";
import GripsConfiguration from "./GripsConfiguration/GripsConfiguration";
import Graph from "./Graph/Graph";
import ServiceMenu from "./ServiceMenu";
import Login from "./Login";

const Bootloader = new BootloaderController();

const Root = () => {
  const { device } = useSelector((state) => state.bluetooth);
  const location = useLocation();
  const history = useHistory();
  const lastLocation = useLastLocation();
  /* const { setSteps, setCurrentStep } = useTour(); */
  const dispatch = useDispatch();
  const {
    setUser,
    setPassword,
    authorize,
    abortAuthorization,
    showError,
    errorMessage,
  } = useAuthorize({ location, history, lastLocation });
  const { bluetoothDisconnect } = useBluetooth();

  const shownGlobalModal = useSelector(selectShownGlobalModalUI);

  useEffect(() => {
    Bootloader.listenBootloaderStatus();

    return function clearConnection() {
      if (device.connected) {
        bluetoothDisconnect();
      }
    };
  }, []);

  /* useEffect(() => {
    setCurrentStep(0);
    if (location.pathname === "/choose-grips") {
      if (physician) {
        setSteps(generalTour);
      } else {
        setSteps(generalTourPatient);
      }
    } else if (location.pathname === "/graph") {
      if (physician) {
        setSteps(emgTour);
      } else {
        setSteps(emgTourPatient);
      }
    } else if (location.pathname === "/grips-configuration") {
      setSteps(gripsConfigTour);
    } else if (location.pathname === "/prosthesis-settings") {
      if (physician) {
        setSteps(prosthesisSettingsTour);
      } else {
        setSteps(prosthesisSettingsTourPatient);
      }
    } else {
      setSteps(generalTour);
    }
  }, [location.pathname]); */

  return (
    <>
      {shownGlobalModal === "authorize" && (
        <AuthorizeModal
          handleAbort={abortAuthorization}
          handleUser={(e) => setUser(e.target.value)}
          handlePassword={(e) => setPassword(e.target.value)}
          handleAuthorize={(e) => authorize(e)}
          showError={showError}
          errorMessage={errorMessage}
        />
      )}
      {shownGlobalModal === "firmware" && (
        <FirmwareModal
          handleClose={() =>
            dispatch(setItemUI({ type: "shownGlobalModal", payload: null }))
          }
        />
      )}
      {shownGlobalModal === "about" && (
        <About
          handleClose={() =>
            dispatch(setItemUI({ type: "shownGlobalModal", payload: null }))
          }
        />
      )}
      {shownGlobalModal === "versions" && (
        <VersionsModal
          handleClose={() =>
            dispatch(setItemUI({ type: "shownGlobalModal", payload: null }))
          }
        />
      )}
      {shownGlobalModal === "info" && (
        <HandDetails
          handleClose={() =>
            dispatch(setItemUI({ type: "shownGlobalModal", payload: null }))
          }
        />
      )}
      {shownGlobalModal === "after_update" && (
        <AfterUpdate
          handleClose={() =>
            dispatch(setItemUI({ type: "shownGlobalModal", payload: null }))
          }
        />
      )}
      {shownGlobalModal === "bootloaderMode" && (
        <BootloaderModeModal
          handleClose={() =>
            dispatch(setItemUI({ type: "shownGlobalModal", payload: null }))
          }
        />
      )}
      <Switch>
        <Route exact path="/">
          <Redirect to="/login" />
        </Route>
        <Route exact path="/login" component={Login} />
        <AuthRoute>
          <Route exact path="/choose-grips" component={ChooseGrips} />
          <Route
            exact
            path="/prosthesis-settings"
            component={ProsthesisSettings}
          />
          <Route
            exact
            path="/application-settings"
            component={ApplicationSettings}
          />
          <Route
            exact
            path="/grips-configuration"
            component={GripsConfiguration}
          />
          <Route exact path="/graph" component={Graph} />
          <Route exact path="/service-menu" component={ServiceMenu} />
        </AuthRoute>
      </Switch>
    </>
  );
};

export default Root;
