import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { TextS, TextXS } from "../Typography/Typography";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  margin-bottom: 10px;
  ${TextS};
`;

const Input = styled.input`
  height: 50px;
  padding: 10px;
  ${TextS};
  border: 1px solid ${({ theme }) => theme.colorPrimary};
  border-radius: 5px;
`;

const ErrorMessage = styled.p`
  margin-top: 5px;
  ${TextXS};
  color: red;
`;

const FormField = ({
  label,
  type,
  name,
  onChange,
  showError,
  errorMessage,
}) => (
  <Wrapper>
    <Label htmlFor={name}>{label}</Label>
    <Input onChange={onChange} type={type} name={name} id={name} />
    {showError && <ErrorMessage>{errorMessage}</ErrorMessage>}
  </Wrapper>
);

FormField.defaultProps = {
  onChange: () => true,
  showError: false,
  errorMessage: "",
};

FormField.propTypes = {
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  showError: PropTypes.bool,
  errorMessage: PropTypes.string,
};

export default FormField;
