import React from "react";
import styled from "styled-components";
import Card from "components/atoms/Card/Card";
import { ReactComponent as Times } from "assets/times-solid.svg";
import PropTypes from "prop-types";
import { HeaderXL } from "components/atoms/Typography/Typography";
import { OpacityAnimation } from "components/atoms/Animations/Animations";

const Header1 = styled.h3`
  ${HeaderXL};
  color: ${({ theme }) => theme.colorPrimary};
`;

const StyledCard = styled(Card)`
  z-index: 999;
  animation: ${OpacityAnimation} 0.5s;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
`;

const InnerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledTimes = styled(Times)`
  width: 35px;
  cursor: pointer;
  color: ${({ theme }) => theme.colorPrimary};

  &&:hover {
    transform: scale(1.1, 1.1);
    transition: transform 0.2s;
  }
`;

const ModalBase = ({
  handleClick,
  header,
  children,
  hideCloseButton = false,
  ...props
}) => (
  <StyledCard padding="30px" {...props}>
    <InnerWrapper>
      <Header1>{header}</Header1>
      {!hideCloseButton && <StyledTimes onClick={handleClick} />}
    </InnerWrapper>
    {children}
  </StyledCard>
);

ModalBase.propTypes = {
  handleClick: PropTypes.func.isRequired,
  header: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default ModalBase;
