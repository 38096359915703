import { HeaderXL } from "components/atoms/Typography/Typography";
import styled, { css } from "styled-components";
import { DisableFunctionality } from "utils/disableFunctionalityModifiier";

export const graphBreakpoints = {
  hdSmall: "1180px",
  hdSmallNum: 1180,
};

export const Header1 = styled.h2`
  ${HeaderXL};
  color: ${({ theme }) => theme.colorPrimary};
  margin-bottom: 20px;

  @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
    margin-bottom: 40px;
  }
`;

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr 10px 10px 1fr auto;

  ${({ graphMode }) =>
    graphMode === "Combined" &&
    css`
      grid-template-rows: 27vh 27vh auto auto;
      grid-template-areas:
        "activation chart chart chart chart contraction"
        "activation chart chart chart chart contraction"
        "controls controls controls controls controls controls"
        "buttons buttons buttons buttons buttons buttons";
    `};

  ${({ graphMode }) =>
    graphMode === "Separate" &&
    css`
      grid-template-rows: 40vh 40vh auto auto;
      grid-template-areas:
        "activationSeparate1 chartSeparate1 chartSeparate1 chartSeparate1 chartSeparate1 contractionSeparate1"
        "activationSeparate2 chartSeparate2 chartSeparate2 chartSeparate2 chartSeparate2 contractionSeparate2"
        "controls controls controls controls controls controls"
        "buttons buttons buttons buttons buttons buttons";
    `};

  ${({ graphMode }) =>
    graphMode === "Proportional" &&
    css`
      grid-template-rows: 40vh auto auto auto auto;
      grid-template-areas:
        "chartProportional chartProportional chartProportional chartProportional chartProportional chartProportional"
        "buttons buttons buttons buttons buttons buttons"
        "controls controls controls controls controls controls"
        "adjuster adjuster adjuster adjuster adjuster adjuster"
        "finger finger finger finger finger finger";
      @media (min-width: 1150px) {
        grid-template-columns: 1fr 1fr 1fr 10px 10px 1fr 1fr 1fr;
        grid-template-areas:
          "chartProportional chartProportional chartProportional chartProportional chartProportional chartProportional adjuster adjuster"
          "chartProportional chartProportional chartProportional chartProportional chartProportional chartProportional adjuster adjuster"
          "chartProportional chartProportional chartProportional chartProportional chartProportional chartProportional finger finger"
          "chartProportional chartProportional chartProportional chartProportional chartProportional chartProportional finger finger"
          "controls controls controls controls controls controls controls controls"
          "buttons buttons buttons buttons buttons buttons buttons buttons";
      }
    `};
`;

export const ChartWrapper = styled.div`
  grid-area: chart;
  width: 100%;
`;

export const ChartWrapperSeparate1 = styled.div`
  grid-area: chartSeparate1;
  width: 100%;
`;

export const ChartWrapperSeparate2 = styled.div`
  grid-area: chartSeparate2;
  width: 100%;
`;

export const ChartProportionalWrapper = styled.div`
  grid-area: chartProportional;
  width: 100%;
`;

export const AdjusterWrapper = styled.div`
  grid-area: adjuster;
  padding-left: 0;
  margin-top: 10px;
  max-width: 400px;

  @media (min-width: 1150px) {
    width: 375px;
    justify-self: flex-end;
  }
`;

export const ActivationWrapper = styled.div`
  margin-right: 10px;
  grid-area: activation;

  @media (min-width: ${graphBreakpoints.hdSmall}) {
    margin-right: 20px;
  }
`;

export const ActivationSeparate1Wrapper = styled.div`
  margin-right: 10px;
  grid-area: activationSeparate1;

  @media (min-width: ${graphBreakpoints.hdSmall}) {
    margin-right: 20px;
  }
`;

export const ActivationSeparate2Wrapper = styled.div`
  margin: 10px 10px 0 0;
  grid-area: activationSeparate2;

  @media (min-width: ${graphBreakpoints.hdSmall}) {
    margin-right: 10px 20px 0 0;
  }
`;

export const ContractionWrapper = styled.div`
  margin-left: 10px;
  grid-area: contraction;

  @media (min-width: ${graphBreakpoints.hdSmall}) {
    margin-left: 20px;
  }
`;

export const ContractionSeparate1Wrapper = styled.div`
  margin-left: 10px;
  grid-area: contractionSeparate1;

  @media (min-width: ${graphBreakpoints.hdSmall}) {
    margin-left: 20px;
  }
`;

export const ContractionSeparate2Wrapper = styled.div`
  margin: 10px 0 0 10px;
  grid-area: contractionSeparate2;

  @media (min-width: ${graphBreakpoints.hdSmall}) {
    margin: 10px 0 0 20px;
  }
`;

export const ButtonsnWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 10px;
  grid-area: buttons;
  margin-top: 10px;
  @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
    margin-top: 20px;
    grid-gap: 20px;
  }
`;

export const Spacer = styled.div`
  grid-area: spacer;
`;

export const ControlsWrapper = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 1fr;
  grid-area: controls;
  margin-top: 10px;

  @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
    margin-top: 20px;
  }
`;

export const GainsSlidersWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  ${({ emgGains }) => (emgGains ? true : DisableFunctionality)}
  z-index: 0;
`;
