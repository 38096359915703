import { TextS } from "components/atoms/Typography/Typography";
import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;

export const TextVertical = styled.div`
  ${TextS};
  text-align: center;
  display: flex;
  justify-content: space-around;
  align-content: center;
  grid-area: vertical;
  margin-bottom: 10px;

  h4 {
    width: 100%;
  }
`;

export const GridContainer = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-gap: 10px;
  @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
    grid-gap: 20px;
  }
`;

export const UpperBox = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  grid-area: box;
`;

export const AdjustBox = styled.div`
  width: 100%;
  &:first-child {
    margin-right: 10px;
  }
`;

export const PositionBox = styled.input`
  ${TextS};
  color: ${({ theme }) => theme.colorPrimary};
  border: 1px solid #00000033;
  text-align: center;
  width: 100%;
`;

export const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    "vertical vertical"
    "box box";
  align-items: center;

  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    grid-template-columns: 1fr 1fr;
  }
`;

export const InnerLabel = styled.h4`
  text-align: center;
  margin-bottom: 10px;
`;
