/* eslint-disable no-unused-vars */
import React from "react";
import styled from "styled-components";
import { ReactComponent as Check } from "assets/check-solid.svg";
import { ReactComponent as Times } from "assets/times-solid.svg";
import { useDispatch, useSelector } from "react-redux";
import { DisableFunctionality } from "utils/disableFunctionalityModifiier";
import { sendFreeze } from "reducers/bluetoothReducer/bluetoothHelpers/asyncThunks";
import CustomToggle from "../Toggle/Toggle";
import { TextXS } from "../Typography/Typography";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  ${({ freezeMode }) => (freezeMode ? true : DisableFunctionality)}
`;

const Label = styled.div`
  ${TextXS}
  font-weight: 600;
`;

const ToggleWrapper = styled.div`
  display: flex;
  cursor: pointer;
  margin-right: 13px;
`;

const StyledTimes = styled(Times)`
  width: 25px;
  height: auto;
  color: ${({ theme }) => theme.typography.colors.red};
`;

const StyledCheck = styled(Check)`
  width: 100%;
  height: 100%;
  color: ${({ theme }) => theme.typography.colors.green};
`;

const FreezeModeActivator = (props) => {
  const { freezeMode } = useSelector((state) => state.bluetooth);
  const dispatch = useDispatch();

  const toggleFreezeMode = () => {
    let newFreezeMode;
    if (freezeMode[0] === 0) {
      newFreezeMode = [1];
    } else {
      newFreezeMode = [0];
    }
    dispatch(sendFreeze({ newFreezeMode }));
  };

  return (
    <Wrapper {...props} freezeMode={freezeMode}>
      <ToggleWrapper>
        <CustomToggle
          onChange={toggleFreezeMode}
          checked={freezeMode[0]}
          icons={{
            checked: <StyledCheck />,
            unchecked: <StyledTimes />,
          }}
        />
      </ToggleWrapper>
      <Label>Freeze Mode</Label>
    </Wrapper>
  );
};

export default FreezeModeActivator;
