import React from "react";
import styled from "styled-components";
import Divider from "components/atoms/Divider/Divider";
import { HeaderM } from "components/atoms/Typography/Typography";
import Modal from "utils/Modal/Modal";
import ModalBase from "./ModalBase";

const StyledModalBase = styled(ModalBase)`
  width: 550px;
`;

const ParagraphBold = styled.p`
  ${HeaderM}
`;

type AboutProps = {
  handleClose: Function;
};

const About = ({ handleClose }: AboutProps) => (
  <Modal>
    <StyledModalBase handleClick={handleClose} header="About">
      <ParagraphBold>Zeus Control Center v. 1.3.2</ParagraphBold>
      <ParagraphBold>Hand Prosthesis Library v. 1.1.0</ParagraphBold>
      <Divider margin="20px" />
      <p>Copyright (C) 2019-2021 Aether Biomedical Sp. z o.o.</p>
      <Divider margin="20px" />
      <p>
        The program is provided AS IS with NO WARRANTY OF ANY KIND,INCLUDING THE
        WARRANTY OF DESIGN, MERCHANTABILITY AND FITNESS FOR A PARTICULAR
        PURPOSE.
      </p>
    </StyledModalBase>
  </Modal>
);

export default About;
