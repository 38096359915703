import { Grips } from "bluetooth/Bluetooth/Grips";
import { defaultConfig } from "consts/deviceConfig/deviceConfig";
import { versionSupport } from "types";

type SliceState = {
  device: {};
  bluetoothMode: "classic" | "ble";
  config: {
    gripPairsConfig: number[];
    controlConfig: number[];
    gripSequentialConfig: number[];
    emgThresholds: number[] | null | false;
    interval: number[];
    gripsPositions: {};
    fingerStrength: number[] | false;
    emgSpike: number[] | false;
    holdOpen: number[] | false;
    softGrip: number[] | false;
    emgGains: number[] | false;
    pulseTimings: number[] | false;
    coContractionTimings: number[] | false;
  };
  trackedConfig: {
    gripPairsConfig: number[];
    controlConfig: number[];
    gripSequentialConfig: number[];
    emgThresholds: number[] | null | false;
    interval: number[];
    gripsPositions: {};
    fingerStrength: number[] | false;
    emgSpike: number[] | false;
    holdOpen: number[] | false;
    softGrip: number[] | false;
    emgGains: number[] | false;
    pulseTimings: number[] | false;
    coContractionTimings: number[] | false;
  } | null;
  chooseGripsHistory: number[][];
  gripsConfiguration: {
    currentGrip: Grips;
    gripsConfigurationHistory: { grip: Grips; values: number[][] }[];
    handMovementAllowed: Boolean;
  };
  emgSettingsHistory: number[][];
  emgThresholdsHistory: number[][] | null;
  gripPairsConfigHistory: number[][];
  gripSequentialConfigHistory: number[][];
  controlConfigHistory: number[][];
  prosthesisSettingsHistory: number[][][];
  emgGainsHistory: number[][];
  telemetryInitiated: boolean;
  updateFilesReady: boolean;
  updateStatus: number;
  freezeMode: number[] | false;
  procedureReply: number[] | null;
  maxParts: number;
  newGrips: boolean;
  versions: {
    current: string | null;
    minimal: string | null;
    recent: string;
    supported: versionSupport;
    numerical: number | null;
  };
  physician: boolean;
  unsaved: {
    initialLoaded: boolean;
    unsavedChanges: boolean;
  };
  localConfigFetched: boolean;
  pcbVersion: string | false;
};

export const initialState: SliceState = {
  device: {
    connected: false,
    serialNumber: "",
  },
  config: defaultConfig,
  trackedConfig: null,
  bluetoothMode: "ble",
  emgThresholdsHistory: [],
  gripsConfiguration: {
    currentGrip: Grips.kGripTypeUnknown,
    gripsConfigurationHistory: [],
    handMovementAllowed: false,
  },
  telemetryInitiated: false,
  chooseGripsHistory: [],
  gripPairsConfigHistory: [],
  gripSequentialConfigHistory: [],
  controlConfigHistory: [],
  prosthesisSettingsHistory: [],
  emgSettingsHistory: [],
  emgGainsHistory: [],
  updateFilesReady: false,
  updateStatus: 0,
  freezeMode: [0],
  procedureReply: null,
  maxParts: 0,
  newGrips: false,
  versions: {
    current: null,
    minimal: null,
    recent: process.env.REACT_APP_RECENT_FIRMWARE as string,
    supported: versionSupport.newest,
    numerical: null,
  },
  physician: false,
  unsaved: { initialLoaded: false, unsavedChanges: false },
  localConfigFetched: false,
  pcbVersion: false,
};
