import styled from "styled-components";

const Card = styled.div`
  box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.25);
  border-radius: ${({ borderRadius }) => borderRadius || "20px"};
  background: ${({ theme }) => theme.colorSupporting};
  padding: 20px;

  @media (min-width: ${({ theme }) => theme.breakpoints.small}) {
    padding: ${({ padding }) => padding || "40px"};
  }
`;

export default Card;
