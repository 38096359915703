/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import Sidebar from "components/organisms/Sidebar";
import UtilityBar from "components/molecules/UtilityBar/UtilityBar";
import { useSelector } from "react-redux";
import { Toaster } from "react-hot-toast";

const Wrapper = styled.div`
  display: flex;
  background-color: #fff;
`;

const OuterWrapper = styled.div`
  flex: 1;
  transform: translateZ(0);
  overflow-x: hidden;
`;

const InnerWrapper = styled.div`
  padding: 20px 25px;
  height: 100vh;
  overflow-y: scroll;
  @media (min-width: ${({ theme }) => theme.breakpoints.xsmall}) {
    padding: 50px 65px;
  }
`;

const Main = styled.div`
  margin: 80px auto 0 auto;
  max-width: 1300px;
  margin-top: 10px;
  padding: ${({ padding }) => padding || "0px"};
  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    margin-top: 50px;
  }
`;

const StyledUtilityBar = styled(UtilityBar)`
  margin: 0 auto;
  max-width: 1300px;
`;

const DefaultLayout = ({ children, padding }) => {
  const { device } = useSelector((state: any) => state.bluetooth);

  return (
    <Wrapper>
      <Sidebar />
      <OuterWrapper>
        <InnerWrapper>
          <StyledUtilityBar device={device.connected} />
          <Main id="main" padding={padding}>
            {children}
          </Main>
        </InnerWrapper>
        <Toaster
          toastOptions={{
            className: "",
            style: {
              marginTop: "30px",
            },
          }}
        />
      </OuterWrapper>
    </Wrapper>
  );
};

DefaultLayout.defaultProps = {
  padding: "0px",
};

DefaultLayout.propTypes = {
  children: PropTypes.node.isRequired,
  padding: PropTypes.string,
};

export default DefaultLayout;
