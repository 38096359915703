/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { ReactComponent as AngleDown } from "assets/angle-down-solid.svg";
import uniqid from "uniqid";
import { TextM, TextS } from "../Typography/Typography";
import { OpacityAnimation } from "../Animations/Animations";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
    min-width: 180px;
  }
`;

const LabelImage = styled.img`
  width: 70px;
  height: 70px;
  padding: 6%;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colorPrimaryShades[5]};
  margin-bottom: 10px;

  @media (min-width: ${({ theme }) => theme.breakpoints.small}) {
    width: 90px;
    height: 90px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    width: 120px;
    height: 120px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
    width: 140px;
    height: 140px;
    padding: 10px;
  }
`;

const Box = styled.div`
  position: relative;
  min-height: 30px;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colorPrimary};
  border-radius: 5px;
  ${TextS};
  background-color: ${({ theme }) => theme.colorFill};
  cursor: pointer;

  @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
    min-height: 50px;
    padding: 5px;
  }
`;

const Choosen = styled.div`
  padding: 10px;
`;

const Label = styled.div`
  ${TextM};
  margin-bottom: 15px;
`;

const Select = styled.ul`
  position: absolute;
  width: max-content;
  display: grid;
  grid-template-columns: repeat(3, 100px);
  border: 1px solid ${({ theme }) => theme.colorPrimary};
  padding: 5px;
  left: 0;
  list-style: none;
  z-index: 999;
  background-color: ${({ theme }) => theme.colorFill};
  animation: ${OpacityAnimation} 0.3s linear;
  border-radius: 5px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    grid-template-columns: repeat(3, 125px);
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
    grid-template-columns: repeat(3, 165px);
  }
`;

const Option = styled.li`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 7%;

  &&:hover {
    .dropdown-image {
      transform: scale(1.03);
    }

    .dropdown-value {
      border-color: ${({ theme }) => theme.colorPrimary};
    }
  }
  &&:active {
    .dropdown-image {
      transform: scale(0.97);
    }
  }
`;

const OptionImage = styled.img`
  width: 50px;
  height: 50px;
  padding: 6%;
  border-radius: 50%;
  margin-bottom: 10px;
  background-color: ${({ theme }) => theme.colorPrimaryShades[5]};
  transition: transform 0.2s;

  @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
    padding: 7px;
    width: 70px;
    height: 70px;
  }
`;

const OptionValue = styled.div`
  border-bottom: 1px solid transparent;
  transition: border 0.4s;
`;

const IconDown = styled(AngleDown)`
  position: absolute;
  color: ${({ theme }) => theme.colorPrimary};
  right: 5px;
  top: 9px;
  width: 12px;

  @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
    width: 24px;
    right: 20px;
    top: 8px;
  }
`;

type DropdownProps = {
  options: any;
  label?: string;
  onChange: Function;
  selected: any;
  responsive?: boolean;
  isOpen?: boolean;
  directControl?: boolean;
  setOpenParent?: Function;
  active?: boolean;
  showImg?: boolean;
};

const DropdownImg = ({
  options,
  label = "",
  onChange,
  selected,
  responsive = false,
  isOpen = false,
  directControl = false,
  setOpenParent = () => false,
  active = true,
  showImg = true,
  ...props
}: DropdownProps) => {
  const [open, setOpen] = useState(false);
  const ref = useRef<any>(null);
  const refBox = useRef<any>(null);

  const updateOption = (option) => {
    onChange(option, open);
  };

  useEffect(() => {
    if (isOpen || open) {
      const viewPort = document.querySelector("body")!;
      const viewPortWidth = viewPort.getBoundingClientRect().width;
      const viewPortHeight = viewPort.getBoundingClientRect().height;

      const dropdown = ref.current.getBoundingClientRect();
      const dropdownRight = dropdown.right;
      const dropdownBottom = dropdown.bottom;

      if (viewPortWidth - dropdownRight < 70) {
        ref.current.style.right = 0;
        ref.current.style.left = "auto";
      }

      if (viewPortHeight - dropdownBottom < -400) {
        ref.current.style.bottom = 0;
        ref.current.style.top = "auto";
      }
    }
  }, [isOpen, open]);

  const updateOpen = () => {
    setOpen((prev) => !prev);
  };

  return (
    <Wrapper
      id="dropdown-select-parent"
      open={directControl ? isOpen : open}
      responsive={responsive ? 1 : 0}
      {...props}
    >
      {showImg && <LabelImage src={selected.img} />}
      <Label>{label}</Label>
      <Box
        responsive={responsive ? 1 : 0}
        onClick={directControl ? setOpenParent : updateOpen}
        ref={refBox}
        data-testid="select-dropdownimg"
      >
        <Choosen data-testid="select-dropdownimg-value">
          {selected.value}
        </Choosen>
        <IconDown responsive={responsive ? 1 : 0} />
        {(directControl ? isOpen && active : open) && (
          <Select id="dropdown-select" ref={ref}>
            {options.map((option, index) => (
              <Option
                onClick={() => {
                  updateOption(option.value);
                }}
                value="option"
                key={uniqid()}
                data-testid={`select-dropdownimg-option${index}`}
              >
                <OptionImage
                  className="dropdown-image"
                  src={option.img}
                  alt="Grip gif"
                />
                <OptionValue className="dropdown-value">
                  {option.value}
                </OptionValue>
              </Option>
            ))}
          </Select>
        )}
      </Box>
    </Wrapper>
  );
};

export default DropdownImg;
