/* eslint-disable no-unused-vars */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DefaultLayout from "layouts/DefaultLayout";
import Card from "components/atoms/Card/Card";
import useMounted from "hooks/useMounted";
import { useDispatch, useSelector } from "react-redux";
import {
  gripsGroupsOptionsMap,
  gripsGroupsOptionsReversedMap,
} from "utils/definesLocal";
import {
  addHistoryComplex,
  consumeHistoryGripsConfiguration,
  setComplexItem,
  toggleComplexItem,
  setUnsaved,
  resetGripConfiguration,
  setGripsPositions,
} from "reducers/bluetoothReducer/bluetoothReducer";
import IconsSidebar from "components/organisms/IconsSidebar.js/IconsSidebar";
import { debounce } from "lodash";
import Checkbox from "components/atoms/Checkbox/Checkbox";
import { gripsImagesMap } from "utils/gripsImages";
import {
  postCurrentGrip,
  postJointTargetPosition,
} from "bluetooth-handler/bluetoothFunctions";
import { GRIPS_POST_17 } from "consts/consts";
import {
  resetToDefault,
  sendAllFingers,
  sendWholeConfig,
} from "reducers/bluetoothReducer/bluetoothHelpers/asyncThunks";
import toast from "react-hot-toast";
import DropdownImg from "components/atoms/Dropdown/DropdownImg";
import useUnsaved from "hooks/useUnsaved";
import { delay } from "bluetooth/Bluetooth/Utilities";
import useTelemetry from "hooks/bluetooth/useTelemetry";
import { timeoutCommandCustom } from "utils/funcs";
import TelemetryController from "bluetooth-handler/telemetryController";
import { Grips } from "../../bluetooth/Bluetooth/Grips";
import {
  AllowText,
  CardsContainer,
  GripImage,
  GripWrapper,
  HandMovementCheckboxWrapper,
  Header1,
  StyledError,
  StyledPositionAdjuster,
  Viewer,
  ViewersWrapper,
} from "./styled";

const sendFingers = async (
  value,
  index,
  { index: fingerIndex },
  bluetoothMode
) => {
  await postJointTargetPosition(fingerIndex, value, bluetoothMode);
};

const sendFingersThrottled = debounce(sendFingers, 100);
const telemetryController = new TelemetryController();

const GripsConfiguration = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isUnsaved } = useUnsaved();
  const isMounted = useMounted();
  const {
    device,
    config: { gripsPositions },
    gripsConfiguration: { currentGrip, handMovementAllowed },
    bluetoothMode,
    unsaved: { unsavedChanges, initialLoaded },
    versions: { supported },
  } = useSelector((state: any) => state.bluetooth);
  const [grips, setGrips] = useState<any>(GRIPS_POST_17);
  const [disableInput, setDisableInput] = useState(true);
  const [selectedGrip, setSelectedGrip] = useState(Grips.kGripTypeUnknown);
  const { prosthesisGrip } = useTelemetry(true);
  const [values, setValues] = useState(() => {
    const initial = {};
    for (const key in Grips) {
      if (Object.hasOwnProperty.call(Grips, key)) {
        initial[Grips[key]] = {
          initial: [0, 0, 0, 0, 0],
          limit: [0, 0, 0, 0, 0],
        };
      }
    }
    return initial;
  });

  useEffect(() => {
    if (
      (prosthesisGrip || prosthesisGrip === 0) &&
      handMovementAllowed &&
      prosthesisGrip !== 255
    ) {
      dispatch(
        setComplexItem({
          type: "gripsConfiguration",
          payload: { currentGrip: prosthesisGrip },
        })
      );
    }
  }, [prosthesisGrip]);

  const handleUnsaved = () => {
    if (initialLoaded) {
      dispatch(
        setUnsaved({
          type: "unsaved",
          payload: { initialLoaded: false },
        })
      );
    } else {
      dispatch(
        setUnsaved({
          type: "unsaved",
          payload: { unsavedChanges: true },
        })
      );
    }
  };

  // Set grip and finger positions on view enter
  useEffect(() => {
    const setHandPosition = async () => {
      await setHandGripPositions(gripsPositions[currentGrip]);
    };
    if (handMovementAllowed) {
      setHandPosition();
    }
  }, []);

  // Update grips positions from store
  useEffect(() => {
    if (Object.keys(gripsPositions).length > 0) {
      setValues(gripsPositions);
      setGrips(
        Object.keys(gripsPositions)
          .map((position) => parseInt(position, 10))
          .filter(
            (position) =>
              position !== Grips.kGripCamera &&
              position !== Grips.kGripTypeUnknown
          )
      );
    }
    if (!unsavedChanges && isMounted) handleUnsaved();
  }, [gripsPositions]);

  // Disable input if grip is undefined
  useEffect(() => {
    const setHandPosition = async () => {
      let breakLoop = false;
      const waitForTransitionEnd = async () => {
        while (telemetryController.gripInTransition && !breakLoop) {
          await delay(100);
        }
        return true;
      };
      await postCurrentGrip(currentGrip, bluetoothMode);
      await delay(300);
      await timeoutCommandCustom(() => waitForTransitionEnd(), 5000);
      await setHandGripPositions(gripsPositions[currentGrip]);
      breakLoop = true;
    };

    if (currentGrip || currentGrip === 0) {
      if (currentGrip !== Grips.kGripTypeUnknown) {
        setDisableInput(false);
      }
      setSelectedGrip(currentGrip);
      if (handMovementAllowed) {
        setHandPosition();
      }
    }
  }, [currentGrip]);

  const setHandGripPositions = async (gripValues) => {
    if (gripValues?.initial && gripValues?.limit) {
      await postJointTargetPosition(0, gripValues.initial[0], bluetoothMode);
      await postJointTargetPosition(1, gripValues.initial[1], bluetoothMode);
      await postJointTargetPosition(2, gripValues.initial[2], bluetoothMode);
      await postJointTargetPosition(3, gripValues.initial[3], bluetoothMode);
      await postJointTargetPosition(4, gripValues.initial[4], bluetoothMode);
    }
  };

  const handleOptions = async (option) => {
    dispatch(
      addHistoryComplex({
        type: "gripsConfiguration",
        nestedType: "gripsConfigurationHistory",
        payload: {
          selectedGrip,
          values,
        },
      })
    );
    const newGrip: any = gripsGroupsOptionsReversedMap.get(option);
    dispatch(
      setComplexItem({
        type: "gripsConfiguration",
        payload: { currentGrip: newGrip },
      })
    );
  };

  const handleSliderChange = async (
    value,
    index,
    { index: fingerIndex, sliderType, min, max }
  ) => {
    let valueSanitized = value;
    if (typeof valueSanitized === "string") {
      valueSanitized = parseInt(value, 10);
      if (Number.isNaN(valueSanitized)) valueSanitized = min;
    }
    if (value > max) {
      valueSanitized = max;
    }
    if (value < min) {
      valueSanitized = min;
    }
    let newInitial = values[selectedGrip].initial;
    let newLimit = values[selectedGrip].limit;
    if (sliderType === "primary") {
      newInitial = newInitial.map((v, i) => {
        if (i === fingerIndex) v = valueSanitized;
        return v;
      });
    } else {
      newLimit = newLimit.map((v, i) => {
        if (i === fingerIndex) v = valueSanitized;
        return v;
      });
    }
    const newGripValues = {
      initial: newInitial,
      limit: newLimit,
    };
    setValues((prev) => ({
      ...prev,
      [selectedGrip]: newGripValues,
    }));
  };

  const handleSliderChangeThrottled = debounce(handleSliderChange, 10);

  // Handle sending data to store
  const handleOnAfterChange = async (...args) => {
    // eslint-disable-next-line no-unused-vars
    const [newValue, _, { index, sliderType }] = args;
    const initialValue = values[selectedGrip].initial[index];
    const limitValue = values[selectedGrip].limit[index];
    const errorMessage =
      "Initial position is greater than the limit position, finger won't be able to move back to initial position";
    if (sliderType === "primary") {
      if (newValue > limitValue) {
        toast.error(errorMessage);
      }
    } else if (newValue < initialValue) {
      toast.error(errorMessage);
    }
    dispatch(setGripsPositions(values));
  };

  const handleSubmit = () => {
    dispatch(sendWholeConfig());
  };

  const handleOnBeforeChange = () => {
    dispatch(
      addHistoryComplex({
        type: "gripsConfiguration",
        nestedType: "gripsConfigurationHistory",
        payload: {
          selectedGrip,
          values,
        },
      })
    );
  };

  const handleRestoreDefaultGripsConfig = async () => {
    await dispatch(resetGripConfiguration({ grip: currentGrip }));
    if (device.connected) {
      await delay(500);
      dispatch(sendAllFingers());
    }
  };

  return (
    <DefaultLayout>
      <IconsSidebar
        saveFunc={disableInput ? false : handleSubmit}
        resetFunc={() => dispatch(resetToDefault())}
        undoFunc={() => dispatch(consumeHistoryGripsConfiguration())}
        unsaved={isUnsaved}
      />
      <Header1>{t("views.grips_configuration")}</Header1>
      <CardsContainer>
        <GripWrapper as={Card} padding="30px" data-tour-gripsConfig="wrapper">
          <StyledPositionAdjuster
            values={{
              columnPrimary: disableInput
                ? [0, 0, 0, 0, 0]
                : values[selectedGrip].initial,
              columnSecondary: disableInput
                ? [0, 0, 0, 0, 0]
                : values[selectedGrip].limit,
            }}
            handleSliderChange={(...args) => {
              // @ts-ignore
              handleSliderChangeThrottled(...args);
              if (handMovementAllowed)
                // @ts-ignore
                sendFingersThrottled(...args, bluetoothMode);
            }}
            handleInputChange={handleSliderChange}
            handleOnAfterChange={handleOnAfterChange}
            handleOnAfterChangeInput={(...args) => {
              handleOnAfterChange(...args);
              if (handMovementAllowed)
                // @ts-ignore
                sendFingersThrottled(...args, bluetoothMode);
            }}
            handleOnBeforeChange={handleOnBeforeChange}
            rows={["Thumb", "Index", "Middle", "Ring", "Pinky"]}
            columns={["Initial position", "Position limit"]}
            hideButton
            limits={[
              { min: 0, max: 1000 },
              { min: 0, max: 1000 },
              { min: 0, max: 1000 },
              { min: 0, max: 1000 },
              { min: 0, max: 1000 },
            ]}
            disableInput={disableInput}
            buttonText="Restore default grips configuration"
            handleActionButton={handleRestoreDefaultGripsConfig}
          />
        </GripWrapper>
        <div>
          <ViewersWrapper as={Card} padding="30px">
            <DropdownImg
              options={grips.map((grip) => ({
                value: gripsGroupsOptionsMap.get(grip),
                img: gripsImagesMap.get(grip),
              }))}
              selected={{
                value: gripsGroupsOptionsMap.get(selectedGrip),
                img: gripsImagesMap.get(selectedGrip),
              }}
              onChange={(option) => handleOptions(option)}
              showImg={false}
              label="Current grip"
            />
            <Viewer data-tour-gripsConfig="viewer">
              <GripImage
                data-tour-gripsConfig="viewer"
                src={gripsImagesMap.get(selectedGrip)}
                alt="Graph viewer"
              />
            </Viewer>
            {Boolean(device.connected && !disableInput && supported) && (
              <HandMovementCheckboxWrapper>
                <div data-tour-gripsConfig="movement">
                  <AllowText>Allow hand movement</AllowText>
                  <Checkbox
                    onClick={() =>
                      dispatch(
                        toggleComplexItem({
                          type: "gripsConfiguration",
                          item: "handMovementAllowed",
                        })
                      )
                    }
                    id="movement"
                    name="movement"
                    checked={handMovementAllowed}
                  />
                </div>
                <StyledError show={handMovementAllowed}>
                  Make sure hand is in safe position!
                </StyledError>
              </HandMovementCheckboxWrapper>
            )}
          </ViewersWrapper>
        </div>
      </CardsContainer>
    </DefaultLayout>
  );
};

export default GripsConfiguration;
