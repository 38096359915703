/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-empty-pattern */
/* eslint-disable no-unused-vars */
import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getSerialNumber,
  postFreezeMode,
  telemetryEnabled,
  statusTypeFreeze,
  runProcedure,
  postAppReceivedProcedure,
  ConfigToSendFunctionMapping,
  postSaveSettings,
  getFirmwareVersion,
  getPcbVersion,
} from "bluetooth-handler/bluetoothFunctions";
import BluetoothWebController from "bluetooth-handler/bluetoothWeb";
import { Commands } from "bluetooth/Bluetooth/Defines";
import { delay } from "bluetooth/Bluetooth/Utilities";
import toast from "react-hot-toast";
import { numericalFirmwareVersion, parseBluetoothFirmware } from "utils/funcs";
import {
  compareConfigs,
  compareGripsPositions,
  getCoreConfig,
  sendAllFingersHelper,
} from "./bluetoothHelpers";

const bluetooth = new BluetoothWebController();

export const disconnectDevice = createAsyncThunk<any>(
  "bluetooth/disconnectDevice",
  async (arg = undefined, { getState }: any) => {
    const { bluetoothMode } = getState().bluetooth;
    toast.loading("Disconnecting device...");
    try {
      await bluetooth.disconnectBluetooth(bluetoothMode);
      toast.remove();
      return true;
    } catch (err) {
      toast.remove();
      console.log(err);
      return false;
    }
  }
);

export const resetToDefault = createAsyncThunk<any>(
  "bluetooth/resetToDefault",
  async (arg = undefined, { getState }: any) => {
    const resetToast = toast.loading("Setting default config...");
    try {
      const wasTelemetryOn = bluetooth.telemetryEnabled;
      if (bluetooth.connected) {
        const { bluetoothMode } = getState().bluetooth;
        await telemetryEnabled(false, bluetoothMode);
        await bluetooth.writeWeb(Commands.kResetToDefaults, [], bluetoothMode);

        const {
          controlConfig,
          interval,
          gripPairsConfig,
          gripSequentialConfigSanitized,
          emgThresholds,
          softGrip,
          holdOpen,
          emgSpike,
          fingerStrength,
          gripsPositions,
          freezeMode,
          emgGains,
          pulseTimings,
          coContractionTimings,
        } = await getCoreConfig(bluetoothMode, resetToast, wasTelemetryOn);

        return {
          gripPairsConfig,
          gripSequentialConfig: gripSequentialConfigSanitized,
          controlConfig,
          gripsPositions,
          emgThresholds,
          holdOpen,
          emgSpike,
          fingerStrength,
          softGrip,
          interval,
          freezeMode,
          emgGains,
          pulseTimings,
          coContractionTimings,
        };
      }
      toast.remove();
      return false;
    } catch (err) {
      toast.remove();
      console.log(err);
      return false;
    }
  }
);

export const getFirmwareDevice = createAsyncThunk<any>(
  "bluetooth/getFirmwareDevice",
  async (arg = undefined, { getState }: any) => {
    const {
      bluetoothMode,
      device: { connected },
    } = getState().bluetooth;
    toast.loading("Checking firmware...");
    try {
      if (connected) {
        console.log("FIRMWARE");
        const firmwareVersion = await getFirmwareVersion(bluetoothMode);
        toast.remove();
        return { firmwareVersion };
      }
      toast.remove();
      return false;
    } catch (err) {
      toast.remove();
      console.log(err);
      return false;
    }
  }
);

export const getPcbVersionDevice = createAsyncThunk<any>(
  "bluetooth/getPcbVersionDevice",
  async (arg = undefined, { getState }: any) => {
    const {
      bluetoothMode,
      device: { connected },
    } = getState().bluetooth;
    try {
      if (connected) {
        let pcbVersion = await getPcbVersion(bluetoothMode);
        let serialNumber = await getSerialNumber(bluetoothMode);
        if (pcbVersion) pcbVersion = pcbVersion[0] + 1;
        if (serialNumber)
          serialNumber = serialNumber
            .map((item) => String.fromCharCode(item))
            .join("")
            .replace("\0", "")
            .replace(/\0/g, "")
            .trim();
        return { pcbVersion, serialNumber };
      }
      return false;
    } catch (err) {
      console.log(err);
      return false;
    }
  }
);

export const connectDevice = createAsyncThunk(
  "bluetooth/connectDevice",
  async (arg = undefined, { dispatch, getState }: any) => {
    const { bluetoothMode } = getState().bluetooth;
    const infoMessage = toast.loading("Connecting to the hand...");
    try {
      const status = await bluetooth.initiateBluetooth(bluetoothMode);
      if (status) {
        console.log("TELEMETRY");
        await telemetryEnabled(false, bluetoothMode);
        if (bluetoothMode === "ble") {
          bluetooth.device.addEventListener(
            "gattserverdisconnected",
            async () => {
              await dispatch(disconnectDevice());
            },
            {
              once: true,
              signal: bluetooth.controller.signal,
            }
          );
        }
        toast.dismiss(infoMessage);
        return { status };
      }
      toast.dismiss(infoMessage);
      return false;
    } catch (err: any) {
      console.log(err, "Bad connection, disconnecting");
      toast.dismiss(infoMessage);
      await bluetooth.disconnectBluetooth(bluetoothMode);
      return err.message;
    }
  }
);

export const getInitialConfig = createAsyncThunk(
  "bluetooth/getInitialConfig",
  async (arg = undefined, { dispatch, getState }: any) => {
    const { bluetoothMode } = getState().bluetooth;
    const initialConfigToast = toast.loading("Connecting to the hand...");
    try {
      const serialNumber = await getSerialNumber(bluetoothMode);
      if (bluetoothMode === "ble") {
        bluetooth.device.addEventListener(
          "gattserverdisconnected",
          () => dispatch(disconnectDevice()),
          {
            once: true,
            signal: bluetooth.controller.signal,
          }
        );
      }

      const {
        controlConfig,
        interval,
        gripPairsConfig,
        gripSequentialConfigSanitized,
        emgThresholds,
        softGrip,
        holdOpen,
        emgSpike,
        fingerStrength,
        gripsPositions,
        freezeMode,
        emgGains,
        pulseTimings,
        coContractionTimings,
      } = await getCoreConfig(bluetoothMode, initialConfigToast);

      return {
        device: {
          serialNumber,
        },
        gripPairsConfig,
        gripSequentialConfig: gripSequentialConfigSanitized,
        controlConfig,
        gripsPositions,
        emgThresholds,
        interval,
        fingerStrength,
        emgSpike,
        holdOpen,
        softGrip,
        freezeMode,
        emgGains,
        pulseTimings,
        coContractionTimings,
      };
    } catch (err: any) {
      console.log(err, "Bad connection, disconnecting");
      toast.remove();
      await bluetooth.disconnectBluetooth(bluetoothMode);
      return err.message;
    }
  }
);

export const sendFreeze = createAsyncThunk(
  "bluetooth/sendFreeze",
  async (
    {
      newFreezeMode,
    }: {
      newFreezeMode: statusTypeFreeze;
    },
    { getState }: any
  ) => {
    try {
      if (bluetooth.connected) {
        const { bluetoothMode, freezeMode } = getState().bluetooth;
        if (freezeMode) {
          await postFreezeMode(newFreezeMode, bluetoothMode);
        }
        return {
          newFreezeMode,
        };
      }
      return false;
    } catch (err) {
      return err;
    }
  }
);

export const handleProcedure = createAsyncThunk(
  "bluetooth/handleProcedure",
  async (
    {
      procedureNumber,
    }: {
      procedureNumber: number;
    },
    { getState }: any
  ) => {
    try {
      if (bluetooth.connected) {
        toast.loading("Running procedure...");
        const { bluetoothMode } = getState().bluetooth;
        const procedureReply = await runProcedure(
          procedureNumber,
          bluetoothMode
        );
        await postAppReceivedProcedure(
          Commands.kFrameTypeProcedureReply,
          bluetoothMode
        );
        toast.remove();
        return {
          procedureReply,
        };
      }
      toast.remove();
      return false;
    } catch (err) {
      toast.remove();
      return err;
    }
  }
);

export const sendAllFingers = createAsyncThunk(
  "bluetooth/sendAllFingers",
  async (_: void, { getState }: any) => {
    try {
      if (bluetooth.connected) {
        toast.loading("Sending grips configuration...");
        const {
          bluetoothMode,
          config: { gripsPositions },
          trackedConfig: { gripsPositions: gripsPositionsTracked },
        } = getState().bluetooth;
        const gripsToSend = compareGripsPositions(
          gripsPositionsTracked,
          gripsPositions
        );
        await sendAllFingersHelper(gripsToSend, bluetoothMode);
        toast.remove();
        return true;
      }
      toast.remove();
      return false;
    } catch (err) {
      toast.remove();
      return err;
    }
  }
);

export const sendWholeConfig = createAsyncThunk(
  "bluetooth/sendWholeConfig",
  async (_: void, { getState }: any) => {
    try {
      if (bluetooth.connected) {
        const {
          config,
          trackedConfig,
          bluetoothMode,
          versions: { current },
        } = getState().bluetooth;
        const configToSend = compareConfigs(trackedConfig, config);
        toast.loading("Sending changes...");
        for (const key in configToSend) {
          if (Object.prototype.hasOwnProperty.call(configToSend, key)) {
            await ConfigToSendFunctionMapping[key](
              configToSend[key],
              bluetoothMode,
              config.controlConfig
            );
            await delay(100);
          }
        }
        if (numericalFirmwareVersion(parseBluetoothFirmware(current)) >= 180) {
          await postSaveSettings(bluetoothMode);
        }
        console.log(configToSend, "TO SEND");
        toast.remove();
        return true;
      }
      toast.remove();
      return false;
    } catch (err) {
      toast.remove();
      return err;
    }
  }
);
