import React from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { HeaderM } from "components/atoms/Typography/Typography";
import Button from "components/atoms/Button/Button";
import Modal from "utils/Modal/Modal";
import {
  resetAfterConfigImport,
  selectLastSettings,
} from "reducers/settingsReducer";
import { importConfig } from "reducers/bluetoothReducer/bluetoothReducer";
import { sendWholeConfig } from "reducers/bluetoothReducer/bluetoothHelpers/asyncThunks";
import ModalBase from "./ModalBase";

const StyledModalBase = styled(ModalBase)`
  width: 550px;
`;

const ParagraphBold = styled.p`
  ${HeaderM}
`;

const ButtonsWrapper = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 20px;
`;
type AboutProps = {
  handleClose: Function;
};

const AfterUpdate = ({ handleClose }: AboutProps) => {
  const dispatch = useDispatch();
  const bluetoothConfig = useSelector(selectLastSettings);

  const accepted = async () => {
    handleClose();
    await dispatch(importConfig({ config: bluetoothConfig }));
    await dispatch(sendWholeConfig());
    // Clear saved config after successful last config import
    dispatch(resetAfterConfigImport());
  };

  const denied = () => {
    dispatch(resetAfterConfigImport());
    handleClose();
  };

  return (
    <Modal>
      <StyledModalBase handleClick={handleClose} header="After update">
        <ParagraphBold>
          Firmware has been updated, do you want to load previous configuration?
        </ParagraphBold>
        <ButtonsWrapper>
          <Button onClick={accepted}>Yes</Button>
          <Button secondary onClick={denied}>
            No
          </Button>
        </ButtonsWrapper>
      </StyledModalBase>
    </Modal>
  );
};

export default AfterUpdate;
