import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const useUnsaved = () => {
  const {
    unsaved: { unsavedChanges },
  } = useSelector((state: any) => state.bluetooth);
  const [isUnsaved, setIsUnsaved] = useState(false);
  useEffect(() => {
    if (unsavedChanges) {
      setIsUnsaved(true);
    } else {
      setIsUnsaved(false);
    }
  }, [unsavedChanges]);

  return { isUnsaved };
};

export default useUnsaved;
