/* eslint-disable react/prop-types */
/* eslint-disable react/require-default-props */
import React, { memo } from "react";
import styled, { css } from "styled-components";
import Slider from "components/atoms/Slider/Slider";
import { TextS } from "components/atoms/Typography/Typography";

const Wrapper = styled.div`
  text-align: center;
  ${({ labelHorizontal }) =>
    labelHorizontal &&
    css`
      display: flex;
      flex-direction: row;
      align-items: center;
      text-align: left;
    `};
`;

const Label = styled.div`
  ${TextS};
  margin-bottom: 7px;
  @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
    margin-bottom: 15px;
  }
  ${({ labelHorizontal }) =>
    labelHorizontal &&
    css`
      margin: 0;
    `};
`;

const InnerWrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.colorPrimary};
  padding: 5px 15px;
  @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
    padding: 10px 30px;
  }
  width: 100%;
  .track.track-default.track-default-1 {
    background: #3788b3;
  }
  .track-default.track-default-0 {
    background: #aaaaaa;
  }
  .track-default.track-default-2 {
    background: #aaaaaa;
  }
`;

const LabelsWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 50px;
`;

interface HorizontalSliderDoubleProps {
  labels: string[];
  handleChange?: any;
  handleOnAfterChange?: any;
  handleOnBeforeChange?: any;
  data?: any;
  value?: any;
  showSliderValue?: boolean;
  limits: any;
  disableSliders?: boolean;
  trackClass?: any;
}

const HorizontalSliderDouble = memo(
  ({
    labels,
    handleChange,
    handleOnAfterChange,
    handleOnBeforeChange,
    data,
    value,
    showSliderValue = true,
    limits,
    disableSliders,
    trackClass,
    ...props
  }: HorizontalSliderDoubleProps) => (
    <Wrapper labelHorizontal={false}>
      {showSliderValue ? (
        <LabelsWrapper>
          {labels.map((label) => (
            <Label labelHorizontal={false}>{label}</Label>
          ))}
        </LabelsWrapper>
      ) : null}
      <InnerWrapper>
        <Slider
          handleChange={handleChange}
          handleOnAfterChange={handleOnAfterChange}
          handleOnBeforeChange={handleOnBeforeChange}
          data={data}
          value={value}
          min={limits.min}
          max={limits.max}
          disable={disableSliders}
          trackClass={trackClass || "track-default"}
          // @ts-ignore
          pearling
          {...props}
        />
      </InnerWrapper>
    </Wrapper>
  )
);

export default HorizontalSliderDouble;
