import React from "react";
import { emgColors } from "theme/emgColors/emgColors";
import ChartJS from "components/atoms/ChartJS/ChartJS";
import ChartJSSeparate1 from "components/atoms/ChartJS/ChartJSSeparate1";
import ChartJSSeparate2 from "components/atoms/ChartJS/ChartJSSeparate2";
import ChartProportionalGraph from "components/atoms/ChartJS/ChartProportional";
import {
  ChartProportionalWrapper,
  ChartWrapper,
  ChartWrapperSeparate1,
  ChartWrapperSeparate2,
} from "./styled";

export const ChartCombined = ({ graphContainer }: any) => (
  <ChartWrapper>
    <ChartJS
      id="combined"
      emgData={[
        {
          label: "EMG extension",
          backgroundColor: emgColors.emgExtension,
          borderColor: emgColors.emgExtension,
          borderWidth: 4,
          fill: false,
          pointRadius: 0,
        },
        {
          label: "EMG flexion",
          backgroundColor: emgColors.emgFlexion,
          borderColor: emgColors.emgFlexion,
          borderWidth: 4,
          fill: false,
          pointRadius: 0,
        },
      ]}
      thresholdsData={[
        {
          label: "CS Open",
          backgroundColor: emgColors.csOpen,
          borderColor: emgColors.csOpen,
          borderWidth: 4,
          fill: false,
          hidden: false,
          pointRadius: 0,
        },
        {
          label: "CS Close",
          backgroundColor: emgColors.csClose,
          borderColor: emgColors.csClose,
          borderWidth: 4,
          fill: false,
          hidden: false,
          pointRadius: 0,
        },
        {
          label: "SCS Open",
          backgroundColor: emgColors.activationOpen,
          borderColor: emgColors.activationOpen,
          borderWidth: 4,
          fill: false,
          hidden: false,
          pointRadius: 0,
          borderDash: [5, 2],
        },
        {
          label: "SCS Close",
          backgroundColor: emgColors.activationClose,
          borderColor: emgColors.activationClose,
          borderWidth: 4,
          fill: false,
          hidden: false,
          pointRadius: 0,
          borderDash: [5, 2],
        },
      ]}
      ref={graphContainer}
    />
  </ChartWrapper>
);

export const ChartSeparate = ({ graphContainer1, graphContainer2 }: any) => (
  <>
    <ChartWrapperSeparate1>
      <ChartJSSeparate1
        id="separate1"
        emgData={[
          {
            label: "EMG extension",
            backgroundColor: emgColors.emgExtension,
            borderColor: emgColors.emgExtension,
            borderWidth: 4,
            fill: false,
            pointRadius: 0,
          },
        ]}
        thresholdsData={[
          {
            label: "CS Open",
            backgroundColor: emgColors.csOpen,
            borderColor: emgColors.csOpen,
            borderWidth: 4,
            fill: false,
            hidden: false,
            pointRadius: 0,
          },
          {
            label: "SCS Open",
            backgroundColor: emgColors.activationOpen,
            borderColor: emgColors.activationOpen,
            borderWidth: 4,
            fill: false,
            hidden: false,
            pointRadius: 0,
            borderDash: [5, 2],
          },
        ]}
        ref={graphContainer1}
      />
    </ChartWrapperSeparate1>
    <ChartWrapperSeparate2>
      <ChartJSSeparate2
        id="separate2"
        emgData={[
          {
            label: "EMG flexion",
            backgroundColor: emgColors.emgFlexion,
            borderColor: emgColors.emgFlexion,
            borderWidth: 4,
            fill: false,
            pointRadius: 0,
          },
        ]}
        thresholdsData={[
          {
            label: "CS Close",
            backgroundColor: emgColors.csClose,
            borderColor: emgColors.csClose,
            borderWidth: 4,
            fill: false,
            hidden: false,
            pointRadius: 0,
          },
          {
            label: "SCS Close",
            backgroundColor: emgColors.activationClose,
            borderColor: emgColors.activationClose,
            borderWidth: 4,
            fill: false,
            hidden: false,
            pointRadius: 0,
            borderDash: [5, 2],
          },
        ]}
        ref={graphContainer2}
      />
    </ChartWrapperSeparate2>
  </>
);

export const ChartProportional = ({ graphContainer }: any) => (
  <>
    <ChartProportionalWrapper>
      <ChartProportionalGraph
        id="proportional"
        emgData={[
          {
            label: "EMG extension",
            backgroundColor: emgColors.emgExtension,
            borderColor: emgColors.emgExtension,
            borderWidth: 4,
            fill: false,
            pointRadius: 0,
          },
          {
            label: "EMG flexion",
            backgroundColor: emgColors.emgFlexion,
            borderColor: emgColors.emgFlexion,
            borderWidth: 4,
            fill: false,
            pointRadius: 0,
          },
        ]}
        thresholdsData={[
          {
            label: "Power Closing",
            backgroundColor: emgColors.powerClose,
            borderColor: emgColors.powerClose,
            borderWidth: 4,
            fill: false,
            hidden: false,
            pointRadius: 0,
          },
          {
            label: "Speed 3 Opening",
            backgroundColor: emgColors.speed3Open,
            borderColor: emgColors.speed3Open,
            borderWidth: 4,
            fill: false,
            hidden: false,
            pointRadius: 0,
            borderDash: [5, 10],
          },
          {
            label: "Speed 3 Closing",
            backgroundColor: emgColors.speed3Close,
            borderColor: emgColors.speed3Close,
            borderWidth: 4,
            fill: false,
            hidden: false,
            pointRadius: 0,
            borderDash: [5, 10],
          },
          {
            label: "Speed 2 Opening",
            backgroundColor: emgColors.speed2Open,
            borderColor: emgColors.speed2Open,
            borderWidth: 4,
            fill: false,
            hidden: false,
            pointRadius: 0,
            borderDash: [5, 5],
          },
          {
            label: "Speed 2 Closing",
            backgroundColor: emgColors.speed2Close,
            borderColor: emgColors.speed2Close,
            borderWidth: 4,
            fill: false,
            hidden: false,
            pointRadius: 0,
            borderDash: [5, 5],
          },
          {
            label: "Speed 1 Opening",
            backgroundColor: emgColors.activationOpen,
            borderColor: emgColors.activationOpen,
            borderWidth: 4,
            fill: false,
            hidden: false,
            pointRadius: 0,
            borderDash: [5, 2],
          },
          {
            label: "Speed 1 Closing",
            backgroundColor: emgColors.activationClose,
            borderColor: emgColors.activationClose,
            borderWidth: 4,
            fill: false,
            hidden: false,
            pointRadius: 0,
            borderDash: [5, 2],
          },
          {
            label: "SCS Closing",
            backgroundColor: emgColors.csOpen,
            borderColor: emgColors.csOpen,
            borderWidth: 4,
            fill: false,
            hidden: false,
            pointRadius: 0,
          },
          {
            label: "SCS Opening",
            backgroundColor: emgColors.csClose,
            borderColor: emgColors.csClose,
            borderWidth: 4,
            fill: false,
            hidden: false,
            pointRadius: 0,
          },
        ]}
        ref={graphContainer}
      />
    </ChartProportionalWrapper>
  </>
);
