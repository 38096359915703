import Error from "components/atoms/Error/Error";
import { HeaderXL, TextS } from "components/atoms/Typography/Typography";
import PositionsAdjusterGrips from "components/molecules/PositionsAdjuster/PositionsAdjusterGrips";
import styled from "styled-components";

export const Header1 = styled.h2`
  ${HeaderXL};
  color: ${({ theme }) => theme.colorPrimary};
  margin-bottom: 20px;

  @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
    margin-bottom: 40px;
  }
`;

export const GripWrapper = styled.div`
  width: 100%;
  height: 100%;
  grid-area: configuration;
`;

export const CardsContainer = styled.div`
  display: grid;
  grid-template-areas:
    "viewers"
    "configuration";
  gap: 40px;
  grid-template-columns: 80%;

  @media (min-width: ${({ theme }) => theme.breakpoints.small}) {
    grid-template-areas:
      "viewers"
      "configuration";
    grid-template-columns: 60%;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    grid-template-areas: "configuration viewers";
    grid-template-columns: 50% 40%;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
    grid-template-areas: " configuration viewers";
    grid-template-columns: 40% 40%;
  }
`;

export const StyledPositionAdjuster = styled(PositionsAdjusterGrips)`
  height: 100%;
`;

export const ViewersWrapper = styled.div`
  grid-area: viewers;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
`;

export const Viewer = styled.div`
  width: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
`;

export const GripImage = styled.img`
  width: 100%;
  padding: 10px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colorPrimaryShades[5]};
  object-fit: contain;
`;

export const HandMovementCheckboxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: auto;

  div {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
`;

export const AllowText = styled.span`
  ${TextS};
  @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
    font-size: 16px;
  }
  margin-right: 0.9em;
`;

export const StyledError = styled(Error)`
  visibility: ${({ show }) => (show ? "visible" : "hidden")};
  text-align: right;
`;
