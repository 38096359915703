import React, { useState } from "react";

function useMounted() {
  const [isMounted, setIsMounted] = useState(false);

  React.useEffect(() => {
    setIsMounted(true);
  }, []);
  return isMounted;
}

export default useMounted;
