import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const Wrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &&:hover {
    transform: scale(1.04, 1.04);
    transition: transform 0.2s;
  }
`;

const StyledIcon = styled.div`
  height: 20px;
  width: 20px;
  object-fit: fill;
  color: ${({ theme }) => theme.colorPrimary};

  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    height: 30px;
    width: 30px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
    height: 40px;
    width: 40px;
  }
  svg {
    width: 100%;
    height: 100%;
  }
`;

const IconDescribed = ({ children, description, ...props }) => (
  <Wrapper {...props}>
    <StyledIcon>{children}</StyledIcon>
  </Wrapper>
);

IconDescribed.propTypes = {
  children: PropTypes.node.isRequired,
  description: PropTypes.string.isRequired,
};

export default IconDescribed;
