import React from "react";
/* import { useLocation } from "react-router-dom"; */
import styled from "styled-components";
import PropTypes from "prop-types";
import LanguageDropdown from "components/atoms/LanguageDropdown/LanguageDropdown";
import { HeaderS, TextS } from "components/atoms/Typography/Typography";
/* import TourActivator from "components/atoms/TourActivator/TourActivator";
import { tourLocations } from "utils/Tour/Tour"; */
import { useDispatch } from "react-redux";
import { setItemUI } from "reducers/uiReducer";
import { ReactComponent as Info } from "assets/info-circle-solid.svg";
import FreezeModeActivator from "components/atoms/FreezeModeActivator/FreezeModeActivator";
import { useTranslation } from "react-i18next";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const InnerWrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 20px;
`;

const DeviceText = styled.div`
  ${HeaderS};
  color: red;
`;

const StyledIconInfo = styled(Info)`
  width: 25px;
  margin-left: 15px;
  cursor: pointer;
`;

const Device = styled.div`
  color: ${({ theme }) => theme.colorPrimary};
  border-radius: 5px;
  text-align: center;
  ${TextS};
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: left;

  i {
    margin-left: 5px;
    cursor: pointer;
  }
`;

const UtilityBar = ({ device, ...props }) => {
  const { t } = useTranslation();
  /* const location = useLocation(); */
  /*  const [isTourAvaiable, setIsTourAvaiable] = useState(false); */
  const dispatch = useDispatch();

  /* useEffect(() => {
    if (tourLocations.includes(location.pathname)) {
      setIsTourAvaiable(true);
    } else {
      setIsTourAvaiable(false);
    }
  }, [location]); */

  return (
    <Wrapper {...props}>
      <DeviceText>
        {device ? (
          <>
            <Device>
              <p>{t("utils.connected")}</p>
              <StyledIconInfo
                onClick={() =>
                  dispatch(
                    setItemUI({ type: "shownGlobalModal", payload: "info" })
                  )
                }
              />
            </Device>
          </>
        ) : (
          t("utils.disconnected")
        )}
      </DeviceText>
      <InnerWrapper>
        <FreezeModeActivator data-tour="grip-freeze" />
        {/* {isTourAvaiable && (
          <TourActivator
            location={location.pathname}
            data-tour-general="utility-tour"
          />
        )} */}
        <LanguageDropdown data-tour-general="utility-language" />
      </InnerWrapper>
    </Wrapper>
  );
};

UtilityBar.propTypes = {
  device: PropTypes.bool.isRequired,
};

export default UtilityBar;
