/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DefaultLayout from "layouts/DefaultLayout";
import GripsChooser2 from "components/molecules/GripsChooser2/GripsChooser2";
import Divider from "components/atoms/Divider/Divider";
import ButtonToggle from "components/atoms/ButtonToggle/ButtonToggle";
import GraphChooserTree from "components/molecules/GripsChooserTree/GripsChooserTree";
import { useDispatch, useSelector } from "react-redux";
import {
  addHistory,
  consumeHistoryChooseGrips,
  setControlModes,
  setGripSequential,
  setGripsPairs,
  setUnsaved,
} from "reducers/bluetoothReducer/bluetoothReducer";
import { gripsImagesMap } from "utils/gripsImages";
import IconsSidebar from "components/organisms/IconsSidebar.js/IconsSidebar";
import useMounted from "hooks/useMounted";
import useUnsaved from "hooks/useUnsaved";
import { OpposedGrips, NonOpposedGrips } from "consts/consts";
import {
  resetToDefault,
  sendWholeConfig,
} from "reducers/bluetoothReducer/bluetoothHelpers/asyncThunks";
import { DisableFunctionalityWrapper } from "views/ProsthesisSettings/styled";
import { versionSupport } from "types";
import { Grips } from "../../bluetooth/Bluetooth/Grips";
import { ControlModes } from "../../bluetooth/Bluetooth/Control";
import { Header1, Header2, Header2Wrapper, InnerWrapper } from "./styled";

const ChooseGrips = () => {
  const [sequentialMode, setSequentialMode] = useState(false);
  const [opened, setOpened] = useState<number | null>(null);
  const [gripsOpposed, setGripsOpposed] = useState<Grips[]>(OpposedGrips);
  const [gripsNonOpposed, setGripsNonOpposed] =
    useState<Grips[]>(NonOpposedGrips);
  const dispatch = useDispatch();
  const {
    config: {
      gripPairsConfig,
      controlConfig,
      gripSequentialConfig,
      gripsPositions,
    },
    physician,
    unsaved: { unsavedChanges, initialLoaded },
    versions: { supported },
  } = useSelector((state: any) => state.bluetooth);
  const isMounted = useMounted();
  const { t } = useTranslation();
  const { isUnsaved } = useUnsaved();
  const pairsOpposed = [
    gripPairsConfig[0],
    gripPairsConfig[1],
    gripPairsConfig[2],
    gripPairsConfig[3],
  ];
  const pairsNonOpposed = [
    gripPairsConfig[4],
    gripPairsConfig[5],
    gripPairsConfig[6],
    gripPairsConfig[7],
  ];
  const sequentialOpposed = [
    gripSequentialConfig[0],
    gripSequentialConfig[1],
    gripSequentialConfig[2],
    gripSequentialConfig[3],
    gripSequentialConfig[4],
    gripSequentialConfig[5],
  ];
  const sequentialNonOpposed = [
    gripSequentialConfig[6],
    gripSequentialConfig[7],
    gripSequentialConfig[8],
    gripSequentialConfig[9],
    gripSequentialConfig[10],
    gripSequentialConfig[11],
  ];

  useEffect(() => {
    if (Object.keys(gripsPositions).length > 0) {
      setGripsOpposed(
        Object.keys(gripsPositions)
          .map((position) => parseInt(position, 10))
          .filter(
            (position) =>
              position !== Grips.kGripCamera &&
              position !== Grips.kGripTypeUnknown &&
              OpposedGrips.includes(position)
          )
      );
      setGripsNonOpposed(
        Object.keys(gripsPositions)
          .map((position) => parseInt(position, 10))
          .filter(
            (position) =>
              position !== Grips.kGripCamera &&
              position !== Grips.kGripTypeUnknown &&
              NonOpposedGrips.includes(position)
          )
      );
    }
  }, [gripsPositions]);

  const handleUnsaved = () => {
    if (initialLoaded) {
      dispatch(
        setUnsaved({
          type: "unsaved",
          payload: { initialLoaded: false },
        })
      );
    } else {
      dispatch(
        setUnsaved({
          type: "unsaved",
          payload: { unsavedChanges: true },
        })
      );
    }
  };

  const updateHistory = () => {
    const historyPayload = {
      gripPairsConfig: [...pairsOpposed, ...pairsNonOpposed],
      gripSequentialConfig: [...sequentialOpposed, ...sequentialNonOpposed],
      controlConfig,
    };

    dispatch(
      addHistory({
        type: "chooseGripsHistory",
        payload: historyPayload,
      })
    );
  };

  useEffect(() => {
    if (gripPairsConfig || gripSequentialConfig) {
      if (!unsavedChanges && isMounted) handleUnsaved();
    }
  }, [gripPairsConfig, gripSequentialConfig]);

  useEffect(() => {
    if (controlConfig) {
      setSequentialMode(controlConfig[2] === 1);
      setOpened(null);
      if (!unsavedChanges && isMounted) handleUnsaved();
    }
  }, [controlConfig]);

  const updateMode = () => {
    updateHistory();
    dispatch(
      setControlModes(
        sequentialMode ? ControlModes.kGripPairs : ControlModes.kGripSequence
      )
    );
  };

  const updatePairGrips = (option, gripOrder, isNonOpposed) => {
    let nonOpposed;
    let opposed;

    if (sequentialMode) {
      nonOpposed = sequentialNonOpposed;
      opposed = sequentialOpposed;
    } else {
      nonOpposed = pairsNonOpposed;
      opposed = pairsOpposed;
    }
    updateHistory();
    let newGripsArray: Grips[] = [];
    let oldGripsArray: Grips[] = [];
    if (isNonOpposed) {
      oldGripsArray = nonOpposed;
    } else {
      oldGripsArray = opposed;
    }
    newGripsArray = oldGripsArray.map((element, index) => {
      if (index === gripOrder) {
        element = option;
      }
      return element;
    });

    let gripsArray: Grips[] = [];

    if (isNonOpposed) {
      gripsArray = [...opposed, ...newGripsArray];
    } else {
      gripsArray = [...newGripsArray, ...nonOpposed];
    }
    if (sequentialMode) {
      dispatch(
        // @ts-ignore
        setGripSequential(gripsArray)
      );
    } else {
      dispatch(
        // @ts-ignore
        setGripsPairs(gripsArray)
      );
    }
  };

  const updateOpened = (index) => {
    if (index === opened) {
      setOpened(null);
    } else {
      setOpened(index);
    }
  };

  return (
    <DefaultLayout padding="0 0 260px 0">
      <IconsSidebar
        saveFunc={() => dispatch(sendWholeConfig())}
        resetFunc={physician ? () => dispatch(resetToDefault()) : false}
        undoFunc={() => dispatch(consumeHistoryChooseGrips())}
        unsaved={isUnsaved}
      />
      <Header1>{t("views.choose_grips")}</Header1>
      <InnerWrapper>
        <Header2>{t("prosthesis_settings.opposed")}</Header2>
        <DisableFunctionalityWrapper
          disableFunctionality={supported === versionSupport.unsupported}
        >
          <ButtonToggle
            data-tour-general="gripToggle"
            onClick={updateMode}
            mode1={t("prosthesis_settings.pairing_mode")}
            mode2={t("prosthesis_settings.sequential_mode")}
            toggled={sequentialMode}
          />
        </DisableFunctionalityWrapper>
      </InnerWrapper>
      {sequentialMode ? (
        <GripsChooser2
          data-tour-general="opposed"
          grips={gripsOpposed}
          gripsImgs={gripsImagesMap}
          values={sequentialOpposed}
          opened={opened}
          indexes={[0, 1, 2, 3, 4]}
          nonOpposed={false}
          onChange={updatePairGrips}
          updateOpened={updateOpened}
        />
      ) : (
        <GraphChooserTree
          data-tour-general="opposed"
          grips={gripsOpposed}
          gripsImgs={gripsImagesMap}
          values={pairsOpposed}
          onChange={updatePairGrips}
          updateOpened={updateOpened}
          opened={opened}
          indexes={[0, 1, 2, 3]}
        />
      )}
      <Divider margin="40px" />
      <Header2Wrapper>
        <Header2 medium>{t("prosthesis_settings.non_opposed")}</Header2>
      </Header2Wrapper>
      {sequentialMode ? (
        <GripsChooser2
          grips={gripsNonOpposed}
          gripsImgs={gripsImagesMap}
          nonOpposed
          opened={opened}
          indexes={[5, 6, 7, 8, 9]}
          values={sequentialNonOpposed}
          updateOpened={updateOpened}
          onChange={updatePairGrips}
        />
      ) : (
        <GraphChooserTree
          grips={gripsNonOpposed}
          gripsImgs={gripsImagesMap}
          values={pairsNonOpposed}
          nonOpposed
          onChange={updatePairGrips}
          updateOpened={updateOpened}
          opened={opened}
          indexes={[4, 5, 6, 7]}
        />
      )}
    </DefaultLayout>
  );
};

export default ChooseGrips;
