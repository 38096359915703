import React from "react";
import styled, { css } from "styled-components";
import PropTypes from "prop-types";
import { HeaderS } from "../Typography/Typography";

const Wrapper = styled.button`
  display: grid;
  align-items: center;
  grid-template-columns: 50% 50%;
  background-image: linear-gradient(
    255deg,
    #fff 0%,
    #fff 49.8%,
    #4fc1ff 50.2%,
    #4fc1ff 100%
  );
  ${HeaderS};
  border: none;
  border-radius: 5px;
  cursor: pointer;
  filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.25));
  text-align: center;
  width: 200px;
  height: 30px;

  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    width: 320px;
    height: 40px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
    width: 350px;
    height: 50px;
  }

  ${({ toggled }) =>
    toggled &&
    css`
      background-image: linear-gradient(
        105deg,
        #fff 0%,
        #fff 49.8%,
        #4fc1ff 50.2%,
        #4fc1ff 100%
      );
    `};
`;

const Mode1 = styled.div`
  color: ${({ theme }) => theme.colorSecondary};
  transition: color 0.3s;
  ${({ toggled }) =>
    !toggled &&
    css`
      color: ${({ theme }) => theme.typography.colors.primary};
    `};
`;

const Mode2 = styled.div`
  color: ${({ theme }) => theme.typography.colors.primary};
  transition: color 0.3s;
  ${({ toggled }) =>
    !toggled &&
    css`
      color: ${({ theme }) => theme.colorSecondary};
    `};
`;

const ButtonToggle = ({ mode1, mode2, toggled, ...props }) => (
  <Wrapper toggled={toggled} {...props}>
    <Mode1 toggled={toggled}>{mode1}</Mode1>
    <Mode2 toggled={toggled}>{mode2}</Mode2>
  </Wrapper>
);

ButtonToggle.defaultProps = {
  toggled: false,
};

ButtonToggle.propTypes = {
  mode1: PropTypes.string.isRequired,
  mode2: PropTypes.string.isRequired,
  toggled: PropTypes.bool,
};

export default ButtonToggle;
