import { PayloadAction } from "@reduxjs/toolkit";

export const updateComplexItem = (
  state,
  action: PayloadAction<{ type: string; payload: any }>
) => {
  state[action.payload.type] = {
    ...state[action.payload.type],
    ...action.payload.payload,
  };
};

export const updateItem = (
  state,
  action: PayloadAction<{ type: string; payload: any }>
) => {
  state[action.payload.type] = action.payload.payload;
};
