import { Grips } from "../../bluetooth/Bluetooth/Grips";
import { DeviceConfigTemplate } from "./deviceConfig.types";

export const defaultConfig: DeviceConfigTemplate = {
  gripPairsConfig: [1, 4, 2, 3, 6, 7, 9, 8],
  controlConfig: [0, 1, 0, 0, 0],
  gripSequentialConfig: [1, 2, 4, 3, 0, 255, 6, 7, 9, 8, 255, 255],
  emgThresholds: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  gripsPositions: {
    [Grips.kGripRestOpp]: {
      initial: [700, 100, 100, 100, 100],
      limit: [900, 900, 900, 900, 900],
    },
    [Grips.kGripPower]: {
      initial: [200, 100, 100, 100, 100],
      limit: [720, 900, 900, 900, 900],
    },
    [Grips.kGripTripodClosed]: {
      initial: [650, 100, 100, 900, 900],
      limit: [900, 450, 450, 900, 900],
    },
    [Grips.kGripTrigger]: {
      initial: [350, 100, 100, 900, 900],
      limit: [900, 900, 900, 900, 900],
    },
    [Grips.kGripPrecisionOpen]: {
      initial: [620, 100, 100, 100, 100],
      limit: [900, 350, 900, 900, 900],
    },
    [Grips.kGripRestNopp]: {
      initial: [680, 100, 100, 100, 100],
      limit: [900, 900, 900, 900, 900],
    },
    [Grips.kGripHook]: {
      initial: [580, 100, 100, 100, 100],
      limit: [750, 900, 900, 900, 900],
    },
    [Grips.kGripKey]: {
      initial: [500, 450, 500, 500, 500],
      limit: [900, 900, 900, 900, 900],
    },
    [Grips.kGripMouse]: {
      initial: [700, 150, 150, 150, 300],
      limit: [900, 900, 900, 900, 900],
    },
    [Grips.kGripFingerPoint]: {
      initial: [650, 10, 900, 900, 900],
      limit: [900, 900, 900, 900, 900],
    },
    [Grips.kGripCamera]: {
      initial: [350, 200, 200, 100, 100],
      limit: [900, 900, 900, 900, 900],
    },
    [Grips.kGripTripodOpen]: {
      initial: [650, 100, 100, 100, 100],
      limit: [900, 900, 900, 900, 900],
    },
    [Grips.kGripPrecisionClosed]: {
      initial: [620, 100, 900, 900, 900],
      limit: [900, 900, 900, 900, 900],
    },
    [Grips.kGripFingerPointOpen]: {
      initial: [650, 10, 950, 950, 950],
      limit: [950, 950, 950, 950, 950],
    },
    [Grips.kGripTypeUnknown]: {
      initial: [0, 0, 0, 0, 0],
      limit: [0, 0, 0, 0, 0],
    },
  },
  interval: [500],
  fingerStrength: [1, 100],
  emgSpike: [0, 300],
  holdOpen: [2000, 2000],
  softGrip: [0],
  freezeMode: [0],
  emgGains: [100, 100],
  pulseTimings: [500, 1000, 500, 1000],
  coContractionTimings: [500, 200],
};
