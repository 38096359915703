const theme = {
  colorPrimary: "hsl(201, 53%, 46%)",
  colorPrimaryShades: [
    "hsl(201, 53%, 32%)",
    "hsl(201, 55%, 40%)",
    "hsl(201, 57%, 48%)",
    "hsl(201, 59%, 56%)",
    "hsl(201, 61%, 64%)",
    "hsl(201, 63%, 72%)",
    "hsl(201, 65%, 80%)",
  ],
  colorSecondary: "hsl(201, 100%, 65%)",
  colorSupporting: "#FFFFFF",
  colorFill: "#F2F2F2",
  fontFamilyPrimary: "'Open Sans', sans-serif",
  breakpoints: {
    hdWidth: "1450px",
    hdWidthNum: 1450,
    large: "1450px",
    largeNum: 1450,
    hdWidthSmall: "1124px",
    hdWidthSmallNum: 1124,
    hdHeight: "800px",
    hdHeightNum: 800,
    medium: "1124px",
    mediumNum: 1124,
    small: "924px",
    smallNum: 924,
    xsmall: "724px",
    xsmallNum: 724,
    mediumHeight: "730px",
    mediumHeightNum: 730,
    smallHeight: "520px",
    smallHeightNum: 520,
  },
  typography: {
    fonts: {
      OpenSans: "'Open Sans', sans-serif",
    },
    fontSizes: {
      xxs: "10px",
      xs: "14px",
      s: "18px",
      m: "24px",
      l: "28px",
      xl: "36px",
    },
    fontWeights: {
      light: 300,
      regular: 400,
      semiBold: 600,
      bold: 700,
    },
    colors: {
      primary: "#FFFFFF",
      secondary: "#393939",
      tetiary: "#4FC1FF",
      green: "#378805",
      red: "hsl(1, 100%, 50%)",
      subtitle: "hsl(0, 0%, 50%)",
    },
  },
};

export default theme;
