/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import styled, { css, keyframes } from "styled-components";
import PropTypes from "prop-types";
import Button from "components/atoms/Button/Button";
import Modal from "utils/Modal/Modal";
import { useDispatch, useSelector } from "react-redux";
import Divider from "components/atoms/Divider/Divider";
import BootloaderController from "bluetooth-handler/bootloaderController";
import { setItem } from "reducers/bluetoothReducer/bluetoothReducer";
import { disconnectDevice } from "reducers/bluetoothReducer/bluetoothHelpers/asyncThunks";
import { ReactComponent as Check } from "assets/check-solid.svg";
import { ReactComponent as Times } from "assets/times-solid.svg";
import ProgressBar from "@ramonak/react-progress-bar";
import { useHistory } from "react-router-dom";
import { HeaderM, TextS } from "components/atoms/Typography/Typography";
import { setItemSettings } from "reducers/settingsReducer";
import { numericalFirmwareVersion } from "hooks/bluetooth/useConnect";
import { selectWholeConfig } from "reducers/bluetoothReducer/bluetoothHelpers/bluetoothHelpers";
import { initialState } from "reducers/bluetoothReducer/bluetoothHelpers/initialState";
import { delay } from "bluetooth/Bluetooth/Utilities";
import {
  ALLOW_MANUAL_UPDATE,
  FIRMWARE_URL,
  MAXIMAL_BOOTLOADER_FIX_VERSION,
} from "consts/consts";
import ModalBase from "./ModalBase";

const StyledModalBase = styled(ModalBase)`
  width: 450px;
`;

const Header1 = styled.h3`
  ${HeaderM};
`;

const Paragraph = styled.p`
  ${TextS}
`;

const Regular = styled.span`
  font-weight: 400;
`;

const StyledIcon = css`
  width: 20px;
  margin-right: 5px;

  @media (max-height: 924px) {
    width: 15px;
  }
`;

const StyledCheck = styled(Check)`
  color: #16c60c;
  ${StyledIcon};
`;

const StyledTimes = styled(Times)`
  color: #ee0000;
  ${StyledIcon};
`;

const InfoWrapper = styled.div`
  display: flex;
`;

const loaderAnimation = keyframes`
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
  `;

const Loader = styled.div`
  width: 20px;
  height: 20px;
  z-index: 999;
  margin-right: 5px;

  &::after {
    content: " ";
    display: block;
    width: 20px;
    height: 20px;
    margin: 2px;
    border-radius: 50%;
    border: 2px solid ${({ theme }) => theme.colorPrimary};
    border-color: ${({ theme }) => theme.colorPrimary} transparent
      ${({ theme }) => theme.colorPrimary} transparent;
    animation: ${loaderAnimation} 1.2s linear infinite;
  }
`;

const Bootloader = new BootloaderController();

const UpdateStates = {
  notInititated: 0,
  fetchSuccesful: 1,
  failedToFetch: 2,
  inProgress: 3,
  succesful: 4,
  failed: 5,
  inProgressFix: 6,
  fixSuccessful: 7,
  fixFailed: 8,
};

const FirmwareModal = ({ handleClose }) => {
  const { versions, updateStatus, maxParts, device, pcbVersion } = useSelector(
    (state) => state.bluetooth
  );

  const [message, setMessage] = useState(null);
  const [currentPart, setCurrentPart] = useState(0);
  const currentConfig = useSelector(selectWholeConfig);
  const dispatch = useDispatch();
  const history = useHistory();

  const { bluetoothMode } = useSelector((state) => state.bluetooth);
  const recentFirmware = `${FIRMWARE_URL}/file_firmware_v${pcbVersion}.bin`;
  const recentBootloader = `${FIRMWARE_URL}/file_bootloader_v${pcbVersion}.bin`;

  useEffect(() => {
    dispatch(
      setItem({
        type: "updateStatus",
        payload: initialState.updateStatus,
      })
    );
    dispatch(
      setItem({
        type: "updateFilesReady",
        payload: initialState.updateFilesReady,
      })
    );
  }, []);

  const updateFirmwareWeb = async (firmwareURL, duringFix = false) => {
    try {
      const request = await fetch(firmwareURL);
      if (request.ok) {
        const bytes = await request.arrayBuffer();
        Bootloader.updateBootloaderParts(bytes);
        const max = Bootloader.getMaxParts();
        setCurrentPart(0);
        await dispatch(setItem({ type: "maxParts", payload: max }));
        return true;
      }
      throw new Error("Bad response status");
    } catch (err) {
      await dispatch(
        setItem({ type: "updateStatus", payload: UpdateStates.failedToFetch })
      );
      return false;
    }
  };

  const update = async () => {
    dispatch(
      setItem({ type: "updateStatus", payload: UpdateStates.inProgress })
    );
    const status = await Bootloader.updateFirmware(bluetoothMode);
    dispatch(
      setItem({
        type: "updateStatus",
        payload: status === 2 ? UpdateStates.succesful : UpdateStates.failed,
      })
    );
    // If update is successful, set isAfterUpdate and copy current settings
    if (status === 2) {
      dispatch(setItemSettings({ type: "isAfterUpdate", payload: true }));
      dispatch(
        setItemSettings({ type: "lastSettings", payload: currentConfig })
      );
    }
    dispatch(disconnectDevice({}));
  };

  const updateWithFix = async (firmwareFileUrl, bootloaderFileUrl) => {
    const fetchStatus = await updateFirmwareWeb(firmwareFileUrl);
    if (fetchStatus) {
      let statusRecent;
      dispatch(
        setItem({ type: "updateStatus", payload: UpdateStates.inProgress })
      );
      const bootloaderVersion = await Bootloader.checkBootloaderVersion(
        bluetoothMode
      );
      if (bootloaderVersion < MAXIMAL_BOOTLOADER_FIX_VERSION) {
        dispatch(
          setItem({ type: "updateStatus", payload: UpdateStates.inProgressFix })
        );
        await updateFirmwareWeb(bootloaderFileUrl, true);
        const statusFix = await Bootloader.updateFirmware(bluetoothMode);
        dispatch(
          setItem({
            type: "updateStatus",
            payload:
              statusFix === 2
                ? UpdateStates.fixSuccessful
                : UpdateStates.fixFailed,
          })
        );
      }
      if (updateStatus !== UpdateStates.fixFailed) {
        await delay(2000);
        await updateFirmwareWeb(firmwareFileUrl);
        statusRecent = await Bootloader.updateFirmwareNew(bluetoothMode);
        dispatch(
          setItem({
            type: "updateStatus",
            payload:
              statusRecent === 2 || statusRecent === false
                ? UpdateStates.succesful
                : UpdateStates.failed,
          })
        );
      }
      // If update is successful, set isAfterUpdate and copy current settings
      if (statusRecent === 2 || statusRecent === false) {
        dispatch(setItemSettings({ type: "isAfterUpdate", payload: true }));
        dispatch(
          setItemSettings({ type: "lastSettings", payload: currentConfig })
        );
      }
      dispatch(disconnectDevice({}));
    }
  };

  const loadFile = async () => {
    const firmware = document.querySelector("#firmwareInput").files[0];
    await updateFirmwareWeb(window.URL.createObjectURL(firmware));
    update();
  };

  useEffect(() => {
    switch (updateStatus) {
      case UpdateStates.notInititated:
        setMessage(null);
        break;
      case UpdateStates.fetchSuccesful:
        setMessage("Files ready, click update to proceed");
        break;
      case UpdateStates.failedToFetch:
        setMessage("Failed to download files");
        break;
      case UpdateStates.inProgress:
        setMessage("Update in progress");
        break;
      case UpdateStates.succesful:
        setMessage("Update succesfull, reconnect the device");
        break;
      case UpdateStates.failed:
        setMessage("Update failed, reconnect the device");
        break;
      case UpdateStates.inProgressFix:
        setMessage("Bootloader update in progress");
        break;
      case UpdateStates.fixSuccessful:
        setMessage(
          "Bootloader updated successfully, wait for the firmware update to finish"
        );
        break;
      case UpdateStates.fixFailed:
        setMessage("Bootloader update failed");
        break;
      default:
        break;
    }
  }, [updateStatus]);

  const updateCurrentPart = (data) => {
    setCurrentPart(data.detail);
  };

  useEffect(() => {
    history.push("choose-grips");
    window.addEventListener(`bootloaderProgressUpdate`, updateCurrentPart);

    return function clean() {
      window.removeEventListener(`bootloaderProgressUpdate`, updateCurrentPart);
    };
  }, []);

  const isInProgress =
    updateStatus === UpdateStates.inProgress ||
    updateStatus === UpdateStates.succesful ||
    updateStatus === UpdateStates.failed ||
    updateStatus === UpdateStates.inProgressFix ||
    updateStatus === UpdateStates.fixSuccessful;

  const isInProgressStrict =
    updateStatus === UpdateStates.inProgress ||
    updateStatus === UpdateStates.inProgressFix ||
    updateStatus === UpdateStates.fixSuccessful;

  const updateStatesSucessfull =
    updateStatus === UpdateStates.fetchSuccesful ||
    updateStatus === UpdateStates.succesful;

  const updateStatesFailed =
    updateStatus === UpdateStates.failedToFetch ||
    updateStatus === UpdateStates.failed;

  const InBootloaderMode = !versions?.current;

  return (
    <Modal>
      <StyledModalBase
        header="Update firmware"
        handleClick={isInProgressStrict ? () => true : handleClose}
        hideCloseButton={InBootloaderMode && device.connected}
      >
        {!InBootloaderMode && (
          <Header1>
            Device version: <Regular>{versions?.current}</Regular>
          </Header1>
        )}
        <Header1>
          Newest version: <Regular>{versions?.recent}</Regular>
        </Header1>
        <Divider margin="10px" />
        {message && (
          <InfoWrapper>
            {updateStatesSucessfull && <StyledCheck />}
            {updateStatus === UpdateStates.inProgress && <Loader />}
            {updateStatesFailed && <StyledTimes />}
            <Paragraph>{message}</Paragraph>
          </InfoWrapper>
        )}
        {(updateStatus === UpdateStates.notInititated ||
          updateStatus === UpdateStates.failedToFetch) && (
          <>
            <Divider margin="10px" />
            {pcbVersion ? (
              <Button
                onClick={() => updateWithFix(recentFirmware, recentBootloader)}
              >
                Update
              </Button>
            ) : (
              <span style={{ color: "red" }}>
                PCB version can&rsquo;t be determined. Firmware update
                can&rsquo;t be performed. Please contact Aether support.
              </span>
            )}
            {ALLOW_MANUAL_UPDATE && (
              <>
                <Divider margin="10px" />
                <input
                  type="file"
                  id="firmwareInput"
                  accept=".bin"
                  onChange={loadFile}
                  style={{ display: "none" }}
                />
                <Button
                  onClick={() =>
                    document.querySelector("#firmwareInput").click()
                  }
                >
                  Update from file
                </Button>
              </>
            )}
          </>
        )}
        {isInProgress && (
          <>
            <Divider margin="10px" />
            <ProgressBar
              completed={
                maxParts ? Math.ceil((currentPart / maxParts) * 100) : 0
              }
              bgColor="#3788B3"
              transitionDuration="0.3s"
            />
            <Divider margin="10px" />
            {isInProgressStrict && (
              <span style={{ color: "red" }}>
                Do not turn off the device, or close the application!
              </span>
            )}
          </>
        )}
      </StyledModalBase>
    </Modal>
  );
};

FirmwareModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
};

export default FirmwareModal;
