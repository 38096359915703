/* eslint-disable no-restricted-syntax */
/* eslint-disable prefer-destructuring */
import toast from "react-hot-toast";
import { numericalFirmwareVersion } from "utils/funcs";
import { loadConfig } from "./bluetoothHelpers";
import { initialState } from "./initialState";

export const resetToDefaultFulfilled = (state, action) => {
  if (action.payload) {
    loadConfig(state, action.payload);
    state.unsaved.unsavedChanges = true;
    toast.success("Default configuration restored");
  } else {
    toast.error("Device not connected");
  }
};

export const disconnectDeviceFulfilled = (state: any, action) => {
  if (action.payload) {
    if (state.device.serialNumber !== "") {
      toast("Device disconnected", { icon: "⚠️" });
    }
    state.device = initialState.device;
    state.newGrips = initialState.newGrips;
    state.gripsConfiguration.handMovementAllowed =
      initialState.gripsConfiguration.handMovementAllowed;
    state.localConfigFetched = false;
    state.versions = initialState.versions;
  } else {
    toast.error("Couldn't disconnect the device");
  }
};

export const connectDeviceFulfilled = (state, action) => {
  if (action.payload.status) {
    state.device = { ...state.device, connected: action.payload.status };
    toast.success("Device connected");
  } else if (action.payload === false) {
    toast.error("Could not connect to the prosthesis");
  } else {
    toast.error(action.payload);
  }
};

export const getFirmwareDeviceFulfilled = (state, action) => {
  if (action.payload.firmwareVersion) {
    const currentVersion = action.payload.firmwareVersion
      .slice(0, 8)
      .map((item) => String.fromCharCode(item))
      .join("");
    const minimalVersion = action.payload.firmwareVersion
      .slice(8, 16)
      .map((item) => String.fromCharCode(item))
      .join("");
    state.versions.current = currentVersion;
    state.versions.minimal = minimalVersion;
    const firmwareVersionCurrentParsed = `${currentVersion[1]}.${currentVersion[4]}.${currentVersion[7]}`;
    state.versions.numerical = numericalFirmwareVersion(
      firmwareVersionCurrentParsed
    );
  } else {
    toast.error(action.payload);
  }
};

export const getInitialConfigFulfilled = (state, action) => {
  state.updateStatus = 0;
  state.updateFilesReady = false;
  state.maxParts = 0;
  if (action.payload.device.serialNumber) {
    const serialNumberSanitized = action.payload.device.serialNumber
      .map((item) => String.fromCharCode(item))
      .join("");
    state.device = {
      ...state.device,
      serialNumber: serialNumberSanitized,
    };
  }
  toast.success("Device connected");

  loadConfig(state, action.payload);
  state.trackedConfig = state.config;
  if (action.payload.gripsPositions) {
    if (action.payload.gripsPositions["12"]) state.newGrips = true;
  }
  state.unsaved.unsavedChanges = false;
  state.localConfigFetched = true;
};

export const sendFreezeFulfilled = (state, action: any) => {
  if (action.payload) {
    state.freezeMode[0] = action.payload.newFreezeMode[0];
  } else {
    toast.error("Device not connected");
  }
};

export const handleProcedureFulfilled = (state, action: any) => {
  if (action.payload.procedureReply) {
    state.procedureReply = action.payload.procedureReply;
    toast.success("Procedure successful");
  } else {
    toast.error("Procedure failed");
  }
};

export const sendWholeConfigFulfilled = (state, action: any) => {
  if (action.payload) {
    state.unsaved.unsavedChanges = false;
    state.trackedConfig = state.config;
    toast.success("Config sent");
  } else {
    toast.error("Device not connected");
  }
};

export const sendAllFingersConfigFulfilled = (state, action) => {
  if (action.payload) {
    toast.success("Fingers config sent");
    state.unsaved.unsavedChanges = false;
  } else if (action.payload === false) {
    toast.error("Device not connected");
  } else {
    toast.error(action.payload);
  }
};

export const getPcbVersionDeviceFulfilled = (state, action) => {
  state.pcbVersion = action.payload.pcbVersion;
  state.device = {
    ...state.device,
    serialNumber: action.payload.serialNumber,
  };
};
