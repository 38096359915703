/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
import React, { useState } from "react";
import styled from "styled-components";
import Card from "components/atoms/Card/Card";
import ukFlag from "assets/flag-united-kingdom.png";
import plFlag from "assets/flag-poland.png";
import { ReactComponent as CaretUp } from "assets/caret-up-solid.svg";
import { ReactComponent as Globe } from "assets/globe-solid.svg";
import { useDispatch } from "react-redux";
import { setItemUI } from "reducers/uiReducer";
import { useTranslation } from "react-i18next";
import { TextS, TextXS } from "../Typography/Typography";
import { OpacityAnimation } from "../Animations/Animations";

const Label = styled.div`
  ${TextXS};
  font-weight: 600;
`;

const StyledCard = styled(Card)`
  position: absolute;
  display: ${({ open }) => (open ? "block" : "none")};
  top: 150%;
  z-index: 1;
  padding: 15%;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
`;

const ListWrapper = styled.div`
  position: relative;
`;

const LanguagesList = styled.ul`
  list-style: none;
  animation: ${OpacityAnimation} 0.3s linear;
`;

const IconHeader = styled(Globe)`
  width: 20px;
  color: ${({ theme }) => theme.colorPrimary};
  margin-right: 13px;
`;

const StyledIcon = styled(CaretUp)`
  position: absolute;
  top: -31px;
  right: 40%;
  width: 18px;

  @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
    top: -40px !important;
    right: 40%;
    width: 24px;
  }
`;

const LanguagesListItem = styled.li`
  display: flex;
  align-items: center;
  ${TextS};
  font-weight: 300;
  cursor: pointer;

  padding: 5px 13px;

  &&:hover {
    background-color: ${({ theme }) => theme.colorPrimary};
    border-radius: 5px;
  }
`;

const FlagIcon = styled.img`
  width: 15px;
  height: 15px;

  @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
    width: 30px;
    height: 30px;
  }
`;

const Languagename = styled.div`
  margin-left: 12px;
`;

const languages = [
  { language: "en", flag: ukFlag, language_long: "English" },
  { language: "pl", flag: plFlag, language_long: "Polski" },
];

const LanguageDropdown = ({ ...props }) => {
  const [open, setOpen] = useState(false);
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const setLanguage = (language) => {
    console.log("CHANGE LANGUAGE", language);
    i18n.changeLanguage(language);
    dispatch(setItemUI({ type: "language", payload: language }));
  };

  return (
    <Header onClick={() => setOpen((prev) => !prev)} {...props}>
      <IconHeader />
      <Label>Language</Label>
      <StyledCard open={open}>
        <ListWrapper>
          <StyledIcon />
          <LanguagesList>
            {languages.map((language) => (
              <LanguagesListItem
                onClick={() => setLanguage(language.language)}
                key={language.language}
              >
                <FlagIcon src={language.flag} />
                <Languagename>{language.language_long}</Languagename>
              </LanguagesListItem>
            ))}
          </LanguagesList>
        </ListWrapper>
      </StyledCard>
    </Header>
  );
};

export default LanguageDropdown;
