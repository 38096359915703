export const TRANSLATIONS_EN = {
  navbar: {
    choose_grips: "Choose grips",
    emg_settings: "EMG settings",
    grips_configuration: "Grips configuration",
    prosthesis_settings: "Prosthesis settings",
    update_firmware: "Update firmware",
    service_menu: "Service menu",
    logout: "Logout",
    login: "Login page",
  },
  views: {
    choose_grips: "Choose grips",
    emg_settings: "EMG settings",
    grips_configuration: "Grips configuration",
    prosthesis_settings: "Prosthesis settings",
    update_firmware: "Update firmware",
    service_menu: "Service menu",
  },
  utils: {
    send: "Send to prosthesis",
    send_unsaved: "(unsaved changes)",
    versions: "Versions",
    restore: "Restore default",
    undo: "Undo",
    help: "Help - Tour",
    language: "Language",
    disconnected: "Device disconnected",
    connected: "Device connected",
  },
  emg: {
    combined: "Combined",
    separate: "Separate",
    proportional: "Proportional",
  },
  disconnect: "Disconnect",
  connect: "Connect",
  settings: {
    self: "Settings",
    app_settings: "Application settings",
    bluetooth_mode: "Bluetooth mode",
  },
  prosthesis_settings: {
    opposed: "Opposed",
    non_opposed: "Non-opposed",
    pairing_mode: "Pairing mode",
    sequential_mode: "Sequential mode",
    freeze_mode: "Freeze Mode",
    grip: "Grip",
    input_options: {
      self: "Input options",
      emg: "EMG",
    },
    input_sites: {
      self: "Input sites",
      single: "Single",
      dual_direct: "Dual direct",
      dual_inverted: "Dual inverted",
    },
    control_strategy: {
      self: "Control strategy",
      threshold: "One speed",
      proportional: "Proportional",
    },
    grip_switching_modes: {
      self: "Grip switching modes",
      co_contraction: "Co-contraction",
      open_open: "Open-open",
      single_electrode: "Single electrode",
      hold_open: "Hold-open",
    },
    soft_grip: {
      self: "Soft-grip",
      finger_strength: "Finger strength",
      calibration: "Calibration procedure",
    },
    emg_spikes: {
      self: "Ignore EMG spikes",
    },
    autograsp: {
      self: "Autograsp",
      force: "Force",
    },
    hold_open: {
      self: "Hold-open grip switching mode settings",
      primary: "Primary hold open time",
      secondary: "Secondary hold open time",
    },
    interval: {
      self: "Interval",
    },
  },
  generic_switch: {
    on: "On",
    off: "Off",
  },
  seconds: "seconds",
};
