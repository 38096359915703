/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useMemo } from "react";
import DefaultLayout from "layouts/DefaultLayout";
import InputOptions from "components/molecules/InputOptions/InputOptions";
import { useDispatch, useSelector } from "react-redux";
import {
  inputSiteMap,
  speedControlMap,
  gripSwitchingMap,
  inputSiteReversedMap,
  gripSwitchingReversedMap,
  speedControlReversedMap,
  genericSwitchingMap,
  genericSwitchingReversedMap,
} from "utils/definesLocal";
import {
  addHistory,
  consumeHistoryProsthesisSettings,
  setAutograsp,
  setCoContractionTimings,
  setControlConfig,
  setEmgSpike,
  setHoldOpen,
  setInterval,
  setItem,
  setPulseTimings,
  setSoftGrip,
  setUnsaved,
  setFingerStrength,
} from "reducers/bluetoothReducer/bluetoothReducer";
import uniqid from "uniqid";
import RadioInput from "components/atoms/RadioInput/RadioInput";
import HorizontalGraphSlider from "components/molecules/HorizontalGraphSlider/HorizontalGraphSlider";
import IconsSidebar from "components/organisms/IconsSidebar.js/IconsSidebar";
import { debounce } from "lodash";
import useFingerStrengthStep from "hooks/useFingerStrengthStep";
import useProcedureReply from "hooks/useProcedure";
import useMounted from "hooks/useMounted";
import { GripSwitchingModes } from "bluetooth/Bluetooth/Control";
import useUnsaved from "hooks/useUnsaved";
import {
  handleProcedure,
  resetToDefault,
  sendWholeConfig,
} from "reducers/bluetoothReducer/bluetoothHelpers/asyncThunks";
import { ProcedureTypes } from "bluetooth/Bluetooth/Procedures";
import { numericalFirmwareVersion, parseBluetoothFirmware } from "utils/funcs";
import { versionSupport } from "types";
import HorizontalSliderDouble from "components/molecules/HorizontalGraphSlider/HorizontalSliderDouble";
import {
  AutograspWrapper,
  CalibrationWrapper,
  DisableFunctionalityWrapper,
  DoubleLabel,
  EMGIgnoreWrapper,
  Header1,
  Header2,
  HoldOpenWrapper,
  OptionsList,
  OptionsSliderDescription,
  OptionsWrapper,
  OptionsWrapperHoldOpen,
  ProcedureButton,
  ProcedureReplyWrapper,
  ProcedureRow,
  ProcedureStatusBox,
  ProcedureTable,
  ProcedureTableWrapper,
  SettingsContainer,
  SoftGrip,
} from "./styled";

const DisableFunctionalityWrapperComponent = ({ disable, children }) => (
  <DisableFunctionalityWrapper disableFunctionality={disable}>
    {children}
    {disable && <div style={{ marginTop: "8px" }}>Update firmware!</div>}
  </DisableFunctionalityWrapper>
);

const parseNumber = (number: number) => Number(number / 1000).toFixed(2);

const OptionsWithSlider = ({
  value,
  options,
  sliderValue,
  header,
  labelSlider,
  limits,
  handleChange,
  onChange,
  handleOnAfterChange,
  handleOnBeforeChange,
  description,
  ...props
}) => (
  <div {...props}>
    <Header2>{header}</Header2>
    <OptionsSliderDescription>{description}</OptionsSliderDescription>
    <OptionsWrapper>
      <OptionsList>
        {options.map((option) => (
          <RadioInput
            key={uniqid()}
            // eslint-disable-next-line eqeqeq
            checked={value == option}
            as="li"
            onClick={(...args) => onChange(option, ...args)}
            label={option}
            id={option}
            name={option}
          />
        ))}
      </OptionsList>
      <HorizontalGraphSlider
        // @ts-ignore
        value={sliderValue}
        limits={limits}
        label={labelSlider}
        handleChange={handleChange}
        handleOnAfterChange={handleOnAfterChange}
        handleOnBeforeChange={handleOnBeforeChange}
      />
    </OptionsWrapper>
  </div>
);

const HoldOpen = ({
  sliderValues,
  header,
  labelSliders,
  limits,
  handleChange,
  handleOnAfterChange = () => true,
  handleOnBeforeChange,
  description,
  ...props
}) => (
  <div {...props}>
    <Header2>{header}</Header2>
    <OptionsSliderDescription>{description}</OptionsSliderDescription>
    <OptionsWrapperHoldOpen>
      {limits.map((slider, index) => (
        <HorizontalGraphSlider
          // @ts-ignore
          value={sliderValues[index]}
          limits={limits[index]}
          label={labelSliders[index]}
          handleChange={(...args) => handleChange(...args, index)}
          handleOnAfterChange={handleOnAfterChange}
          handleOnBeforeChange={handleOnBeforeChange}
        />
      ))}
    </OptionsWrapperHoldOpen>
  </div>
);

const PushSlider = ({
  sliderValues,
  header,
  labelSlider,
  limits,
  handleChange,
  handleOnAfterChange = () => true,
  handleOnBeforeChange,
  description,
  pearling = false,
  trackClass = undefined,
  ...props
}) => (
  <div {...props}>
    <Header2>{header}</Header2>
    <OptionsSliderDescription>{description}</OptionsSliderDescription>
    <OptionsWrapperHoldOpen>
      {limits.map((slider, index) => (
        <HorizontalSliderDouble
          // @ts-ignore
          value={sliderValues[index]}
          limits={limits[index]}
          labels={labelSlider[index]}
          handleChange={(...args) => handleChange(...args, index)}
          handleOnAfterChange={handleOnAfterChange}
          handleOnBeforeChange={handleOnBeforeChange}
          trackClass={trackClass}
        />
      ))}
    </OptionsWrapperHoldOpen>
  </div>
);

const ProsthesisSettings = () => {
  const dispatch = useDispatch();
  const {
    device,
    config: {
      controlConfig: [
        inputDevice,
        inputSite,
        controlMode,
        speedControlStrategy,
        gripSwitchingMode,
      ],
      controlConfig,
      emgSpike: [emgSpikeStatus, emgSpikeTime],
      emgSpike,
      holdOpen: [holdOpenShort, holdOpenLong],
      holdOpen,
      softGrip: [softGripStatus],
      softGrip,
      interval,
      pulseTimings,
      pulseTimings: [
        minimumPulse,
        maximumPulse,
        minimumBetweenPulses,
        maximumBetweenPulses,
      ],
      coContractionTimings,
      coContractionTimings: [longCoContraction, coContractionOffset],
    },
    physician,
    unsaved: { unsavedChanges, initialLoaded },
    versions: { supported, current },
  } = useSelector((state: any) => state.bluetooth);
  const { isUnsaved } = useUnsaved();
  const { fingerStep, fingerStrengthCurrent, setFingerStep, fingerStrength } =
    useFingerStrengthStep();

  const { procedure } = useProcedureReply();
  const isMounted = useMounted();

  const handleUnsaved = () => {
    if (initialLoaded) {
      dispatch(
        setUnsaved({
          type: "unsaved",
          payload: { initialLoaded: false },
        })
      );
    } else {
      dispatch(
        setUnsaved({
          type: "unsaved",
          payload: { unsavedChanges: true },
        })
      );
    }
  };

  useEffect(() => {
    if (
      controlConfig ||
      emgSpike ||
      interval ||
      holdOpen ||
      softGripStatus ||
      pulseTimings ||
      coContractionTimings
    ) {
      if (!unsavedChanges && isMounted) handleUnsaved();
    }
  }, [
    controlConfig,
    emgSpike,
    interval,
    holdOpen,
    softGripStatus,
    pulseTimings,
    coContractionTimings,
  ]);

  const addHistoryProsthesis = async () => {
    await dispatch(
      addHistory({
        type: "prosthesisSettingsHistory",
        payload: [
          controlConfig,
          emgSpike,
          holdOpen,
          softGrip,
          interval,
          pulseTimings,
          coContractionTimings,
        ],
      })
    );
  };

  const handleFingerChange = (value) => {
    setFingerStep(value);
  };

  const handleonAfterChangeFinger = () => {
    // @ts-ignore
    dispatch(setFingerStrength({ finger: 1, strength: fingerStrengthCurrent }));
    if (!unsavedChanges) handleUnsaved();
  };

  const handleSpeedControl = async (type, value) => {
    await addHistoryProsthesis();
    const newControlConfig = [
      controlConfig[0],
      controlConfig[1],
      controlConfig[2],
      speedControlReversedMap.get(value),
      controlConfig[4],
    ];
    dispatch(
      setControlConfig({
        payload: newControlConfig,
      })
    );
  };

  const handleInputSites = async (type, value) => {
    await addHistoryProsthesis();
    const newControlConfig = [
      controlConfig[0],
      inputSiteReversedMap.get(value),
      controlConfig[2],
      controlConfig[3],
      controlConfig[4],
    ];
    dispatch(
      setControlConfig({
        payload: newControlConfig,
      })
    );
  };

  const handleGripSwitchingModes = async (type, value) => {
    await addHistoryProsthesis();
    const newControlConfig = [
      controlConfig[0],
      controlConfig[1],
      controlConfig[2],
      controlConfig[3],
      gripSwitchingReversedMap.get(value),
    ];
    dispatch(
      setControlConfig({
        payload: newControlConfig,
      })
    );
  };

  const handleEmgSlider = (value) => {
    dispatch(setEmgSpike({ status: emgSpikeStatus, time: value }));
  };

  const handleEmgStatus = async (value) => {
    await addHistoryProsthesis();
    dispatch(
      // @ts-ignore
      setEmgSpike({ status: value, time: emgSpikeTime })
    );
  };

  const handleHoldOpenSliders = (...args) => {
    const short = args[0][3] === 0;
    const value = args[0][0];
    let newHoldOpenLong = holdOpenLong;
    let newHoldOpenShort = holdOpenShort;
    if (short && value > holdOpenLong) {
      newHoldOpenLong = value;
    }
    if (!short && value < holdOpenShort) {
      newHoldOpenShort = value;
    }
    if (short) {
      dispatch(setHoldOpen({ short: value, long: newHoldOpenLong }));
    } else {
      dispatch(setHoldOpen({ short: newHoldOpenShort, long: value }));
    }
  };

  const handleIntervalSlider = (value) => {
    dispatch(setInterval(value));
  };

  const handleSoftGripStatus = async (value) => {
    await addHistoryProsthesis();
    dispatch(
      // @ts-ignore
      setSoftGrip(value)
    );
  };

  const handlePulseSlider = (...args) => {
    const between = args[0][3] === 1;
    const values = args[0][0];
    let newMinimumPulse = minimumPulse;
    let newMaximumPulse = maximumPulse;
    let newMinimumBetweenPulses = minimumBetweenPulses;
    let newMaximumBetweenPulses = maximumBetweenPulses;
    if (between) {
      [newMinimumBetweenPulses, newMaximumBetweenPulses] = values;
    } else {
      [newMinimumPulse, newMaximumPulse] = values;
    }
    dispatch(
      setPulseTimings({
        minimumPulse: newMinimumPulse,
        maximumPulse: newMaximumPulse,
        minimumBetweenPulses: newMinimumBetweenPulses,
        maximumBetweenPulses: newMaximumBetweenPulses,
      })
    );
  };

  const handleCoContractionSliders = (...args) => {
    const offset = args[0][3] === 1;
    const value = args[0][0];
    let newLongCoContraction = longCoContraction;
    let newOffset = coContractionOffset;
    if (offset && value > longCoContraction) {
      newLongCoContraction = value;
    }
    if (!offset && value < coContractionOffset) {
      newOffset = value;
    }
    if (offset) {
      dispatch(
        setCoContractionTimings({
          time: newLongCoContraction,
          offset: value,
        })
      );
    } else {
      dispatch(
        setCoContractionTimings({
          time: value,
          offset: newOffset,
        })
      );
    }
  };

  const handleLocalSlidersThrottled = debounce(handleEmgSlider, 10);

  const Checkboxes = useMemo(
    () => (
      <>
        {physician && (
          <>
            <InputOptions
              header="Input options"
              options={["EMG"]}
              id="options"
              value="EMG"
            />
            <DisableFunctionalityWrapper
              disableFunctionality={supported === versionSupport.unsupported}
            >
              <InputOptions
                header="Input sites"
                options={["Dual direct", "Dual Inverted", "Single"]}
                id="sites"
                onChange={handleInputSites}
                // @ts-ignore
                value={inputSiteMap.get(inputSite)}
                data-tour="inputSites"
              />
            </DisableFunctionalityWrapper>
            <DisableFunctionalityWrapper
              disableFunctionality={supported === versionSupport.unsupported}
            >
              <InputOptions
                header="Speed control strategy"
                data-tour="strategy"
                options={["One speed", "Proportional"]}
                id="strategy"
                onChange={handleSpeedControl}
                // @ts-ignore
                value={speedControlMap.get(speedControlStrategy)}
                description="Mode in which you operate the prosthesis"
              />
            </DisableFunctionalityWrapper>
            <DisableFunctionalityWrapper
              disableFunctionality={supported === versionSupport.unsupported}
            >
              <InputOptions
                header="Grip switching modes"
                data-tour="grip-switching"
                options={[
                  "Co-contraction",
                  "Open-open",
                  "Hold-open",
                  "Single electrode",
                ]}
                id="mode"
                onChange={handleGripSwitchingModes}
                // @ts-ignore
                value={gripSwitchingMap.get(gripSwitchingMode)}
                description="Different ways of generating Change Signal (CS) and Secondary Change Signal (SCS)"
                disable={
                  controlConfig[4] === GripSwitchingModes.kSingleGripSwitching
                }
              />
            </DisableFunctionalityWrapper>
          </>
        )}
      </>
    ),
    [controlConfig, procedure, supported]
  );

  const EMG = useMemo(
    () => (
      <OptionsWithSlider
        header="Ignore EMG spikes"
        options={["On", "Off"]}
        data-tour="emg-spikes"
        value={genericSwitchingMap.get(emgSpikeStatus)}
        sliderValue={emgSpikeTime}
        handleChange={(value) => handleEmgSlider(value)}
        limits={{ min: 0, max: 500 }}
        labelSlider={`Time ignored: ${parseNumber(emgSpikeTime)} s`}
        onChange={(value) =>
          handleEmgStatus(genericSwitchingReversedMap.get(value))
        }
        handleOnAfterChange={(value) => handleEmgSlider(value)}
        handleOnBeforeChange={addHistoryProsthesis}
        description="Ignore short EMG spikes of the specified length"
      />
    ),
    [emgSpike]
  );

  const Hold = useMemo(
    () => (
      // @ts-ignore
      <HoldOpen
        header="Hold-open timings"
        data-tour="hold"
        sliderValues={holdOpen}
        limits={[
          { min: 250, max: 5000 },
          { min: 300, max: 5000 },
        ]}
        handleChange={(...args) => handleHoldOpenSliders(args)}
        labelSliders={[
          `Primary hold open time: ${parseNumber(holdOpenShort)} s`,
          `Secondary hold open time: ${parseNumber(holdOpenLong)} s`,
        ]}
        handleOnBeforeChange={addHistoryProsthesis}
        description="Sets the short and long hold open times for grip switching"
      />
    ),
    [holdOpen]
  );

  const Interval = useMemo(
    () => (
      // @ts-ignore
      <HoldOpen
        header="Grip change signal time"
        sliderValues={interval}
        limits={[{ min: 200, max: 1000 }]}
        handleChange={(value) => handleIntervalSlider(value)}
        labelSliders={[`Time: ${parseNumber(interval)} s`]}
        handleOnBeforeChange={addHistoryProsthesis}
        description="Sets length of co-contraction signal and time for performing open-open 
        signal"
      />
    ),
    [interval]
  );

  return (
    <DefaultLayout>
      <IconsSidebar
        saveFunc={() => {
          dispatch(sendWholeConfig());
        }}
        resetFunc={physician ? () => dispatch(resetToDefault()) : false}
        undoFunc={() => dispatch(consumeHistoryProsthesisSettings())}
        unsaved={isUnsaved}
      />
      <Header1>Prosthesis settings</Header1>
      <SettingsContainer>
        {Checkboxes}
        {/* {physician && <div data-tour="interval">{Interval}</div>} */}
        {gripSwitchingMode === GripSwitchingModes.kCoContraction && (
          <DisableFunctionalityWrapper
            disableFunctionality={supported === versionSupport.unsupported}
          >
            <HoldOpen
              header="Co-contraction timings"
              data-tour="co-contraction"
              sliderValues={coContractionTimings}
              limits={[
                { min: 200, max: 2000 },
                { min: 20, max: 500 },
              ]}
              handleChange={(...args) => handleCoContractionSliders(args)}
              labelSliders={[
                `Long co-contraction time: ${parseNumber(longCoContraction)} s`,
                `Signal rise offset time: ${parseNumber(
                  coContractionOffset
                )} s`,
              ]}
              handleOnBeforeChange={addHistoryProsthesis}
              description="Sets length of co-contraction signal"
            />
          </DisableFunctionalityWrapper>
        )}
        {(gripSwitchingMode === GripSwitchingModes.kOpenOpen ||
          gripSwitchingMode === GripSwitchingModes.kSingleGripSwitching) && (
          <DisableFunctionalityWrapper
            disableFunctionality={supported === versionSupport.unsupported}
          >
            <PushSlider
              header="Pulse timings"
              sliderValues={[
                [minimumPulse, maximumPulse],
                [minimumBetweenPulses, maximumBetweenPulses],
              ]}
              limits={[
                { min: 10, max: 750 },
                { min: 50, max: 2000 },
              ]}
              handleChange={(...args) => handlePulseSlider(args)}
              labelSlider={[
                [
                  `Min pulse time: ${parseNumber(minimumPulse)} s`,
                  `Max pulse time: ${parseNumber(maximumPulse)} s`,
                ],
                [
                  `Min time between pulses: ${parseNumber(
                    minimumBetweenPulses
                  )} s`,
                  `Max time between pulses: ${parseNumber(
                    maximumBetweenPulses
                  )} s`,
                ],
              ]}
              handleOnBeforeChange={addHistoryProsthesis}
              description="Sets time for performing open-open 
        signal"
            />
          </DisableFunctionalityWrapper>
        )}
        {gripSwitchingMode === GripSwitchingModes.kHoldOpen && (
          <DisableFunctionalityWrapper
            disableFunctionality={supported === versionSupport.unsupported}
          >
            <HoldOpenWrapper holdOpen={holdOpen}>{Hold}</HoldOpenWrapper>
          </DisableFunctionalityWrapper>
        )}
        <SoftGrip softGrip={softGrip} data-tour="soft-grip">
          <OptionsWithSlider
            header="Soft-grip"
            options={["On", "Off"]}
            value={genericSwitchingMap.get(softGripStatus)}
            sliderValue={fingerStep}
            handleChange={handleFingerChange}
            limits={{ min: 0, max: 5 }}
            labelSlider={
              <DoubleLabel>
                <span>Gentle</span>
                <span>Strong</span>
              </DoubleLabel>
            }
            step={1}
            onChange={(value) =>
              handleSoftGripStatus(genericSwitchingReversedMap.get(value))
            }
            handleOnAfterChange={handleonAfterChangeFinger}
            handleOnBeforeChange={addHistoryProsthesis}
            description="Used to limit the strength of the fingers"
          />
          <CalibrationWrapper>
            {procedure && (
              <ProcedureReplyWrapper>
                <Header2 as="h4">Last run procedure information</Header2>
                <ProcedureTableWrapper>
                  <ProcedureTable>
                    <ProcedureRow>
                      <td>&nbsp;</td>
                      <td>Thumb</td>
                      <td>Index</td>
                      <td>Middle</td>
                      <td>Ring</td>
                      <td>Pinky</td>
                    </ProcedureRow>
                    <ProcedureRow>
                      <td colSpan={1}>Status</td>
                      {procedure.status!.map((status, index) => (
                        <ProcedureStatusBox
                          colSpan={1}
                          status={procedure.status![index]}
                        >
                          {status === 1 ? "OK" : "NOT OK"}
                        </ProcedureStatusBox>
                      ))}
                    </ProcedureRow>
                    <ProcedureRow>
                      <td colSpan={1}>Max current</td>
                      {procedure.maxCurrents!.map((_current) => (
                        <td colSpan={1}>{_current}</td>
                      ))}
                    </ProcedureRow>
                  </ProcedureTable>
                </ProcedureTableWrapper>
              </ProcedureReplyWrapper>
            )}
            {physician && (
              <ProcedureButton
                onClick={() =>
                  dispatch(
                    handleProcedure({
                      procedureNumber: ProcedureTypes.calibrateSoftGrip,
                    })
                  )
                }
              >
                Calibration procedure
              </ProcedureButton>
            )}
          </CalibrationWrapper>
        </SoftGrip>
        <EMGIgnoreWrapper emgSpike={emgSpike}>{EMG}</EMGIgnoreWrapper>
      </SettingsContainer>
    </DefaultLayout>
  );
};

export default ProsthesisSettings;
