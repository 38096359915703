import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { ThemeProvider } from "styled-components";
import GlobalStyle from "theme/globalStyles";
import theme from "theme/theme";
import Root from "views/Root";
import store from "store/store";
import { BrowserRouter } from "react-router-dom";
import { LastLocationProvider } from "react-router-last-location";
/* import { TourProvider } from "@reactour/tour"; */
/* import { generalTour } from "utils/Tour/Tour"; */
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import "utils/i18n/i18n";

const persistor = persistStore(store);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <GlobalStyle />
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <LastLocationProvider>
              {/*     <TourProvider steps={generalTour}> */}
              <Root />
              {/*               </TourProvider> */}
            </LastLocationProvider>
          </BrowserRouter>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
