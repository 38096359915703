/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { openDB } from "idb/with-async-ittr";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import Modal from "utils/Modal/Modal";
import { ReactComponent as Save } from "assets/save-solid.svg";
import { loadStorage, selectAllVersions } from "reducers/versionsReducer";
import { ReactComponent as Times } from "assets/times-solid.svg";
import Input from "components/atoms/Input/Input";
import Button from "components/atoms/Button/Button";
import { importConfig } from "reducers/bluetoothReducer/bluetoothReducer";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { selectWholeConfig } from "reducers/bluetoothReducer/bluetoothHelpers/bluetoothHelpers";
import { setItemUI } from "reducers/uiReducer";
import ModalBase from "./ModalBase";

dayjs.extend(customParseFormat);

const Wrapper = styled(ModalBase)`
  width: 720px;
`;

const WrapperTable = styled.table`
  width: 100%;
`;

const VersionTableHeader = styled.tr`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-items: flex-start;
  border-top: 2px solid #f2f2f2;
  border-left: 2px solid #f2f2f2;
  border-right: 2px solid #f2f2f2;
  margin-bottom: -2px;
  padding: 0 20px;
`;

const TableBody = styled.tbody`
  display: grid;
  max-height: 50vh;
  padding: 20px;
  overflow-y: scroll;
  grid-gap: 10px;
  background-color: ${({ theme }) => theme.colorFill};
  box-shadow: inset 0px 0px 4px 2px rgba(0, 0, 0, 0.25);
`;

const VersionBlockWrapper = styled.tr`
  display: grid;
  background-color: #fff;
  grid-template-columns: 1fr 1fr 1fr;
  box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.25);
  justify-items: flex-start;
  border-left: 4px solid ${({ theme }) => theme.colorSecondary};
`;

const RowItemHeader = styled.th`
  text-align: left;
  width: 100%;
  padding: 10px 0 10px 20px;

  &:not(:last-child) {
    border-right: 2px solid #f2f2f2;
  }
`;

const RowItemBlock = styled.th`
  text-align: left;
  font-weight: 400;
  width: 100%;
  padding: 10px 24px 10px 24px;

  input {
    width: 100%;
  }

  &:not(:last-child) {
    border-right: 2px solid #f2f2f2;
  }
`;

const VersionBlockWrapperSave = styled(VersionBlockWrapper)`
  grid-template-columns: 1fr;
`;

const VersionBlockWrapperActive = styled.tr`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-items: flex-start;
  cursor: pointer;
`;

const RowItemBlockIcon = styled(RowItemBlock)`
  display: flex;
  cursor: pointer;

  input {
    width: 100%;
  }
`;

const StyledButton = styled(Button)`
  width: min-content;
`;

const StyledSave = styled(Save)`
  width: 22px;
  height: 22px;
  margin-right: 10px;
`;

const StyledTimes = styled(Times)`
  width: 20px;
  height: 22px;
  margin-right: 10px;
  color: ${({ theme }) => theme.colorPrimary};
`;

type AboutProps = {
  handleClose: Function;
};

const ActiveWrapper = styled.div`
  box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.25);
  background-color: #fff;
  border-left: 4px solid ${({ theme }) => theme.colorSecondary};
`;

const NoteWrapper = styled.div`
  display: ${({ active }) => (active ? "block" : "none")};
  padding: 10px 24px 10px 24px;
  border-top: 2px solid #f2f2f2;
`;

const TextAreaWrapper = styled.div`
  display: flex;

  label {
    margin-right: 10px;
  }

  textarea {
    padding-left: 5px;
    font-family: ${({ theme }) => theme.typography.fonts.OpenSans};
    width: 100%;
    resize: vertical;
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-top: 10px;
`;

const ButtonsInnerWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

const Divider = styled.div`
  margin-top: 10px;
`;

type Details = {
  name: string;
  date: string;
  patient: string;
  note: string;
};

type VersionSaveBlockActiveProps = {
  handleClick: Function;
  handleChangeInput: Function;
  handleSave: Function;
  saveDetails?: any;
  info?: Details;
  editMode: boolean;
  newItem?: boolean;
};

const VersionSaveBlockActive = ({
  handleClick,
  handleChangeInput,
  handleSave,
  saveDetails,
  info = {
    name: "Version name",
    date: "",
    patient: "Medical record number",
    note: "",
  },
  editMode,
  newItem = false,
}: VersionSaveBlockActiveProps) => (
  <ActiveWrapper>
    <VersionBlockWrapperActive>
      <RowItemBlockIcon colSpan={1}>
        <StyledTimes onClick={handleClick} />
        <Input
          name="name"
          onChange={(e) => handleChangeInput(e, "name")}
          type="text"
          placeholder={info.name}
          required
          autoComplete="off"
          defaultValue={editMode ? info.name : undefined}
        />
      </RowItemBlockIcon>
      {newItem ? (
        <RowItemBlock colSpan={1}>
          <Input
            name="date"
            onChange={(e) => handleChangeInput(e, "date")}
            type="date"
            value={
              saveDetails.date === ""
                ? dayjs(new Date(Date.now())).format("YYYY-MM-DD")
                : dayjs(saveDetails.date, "DD/MM/YYYY").format("YYYY-MM-DD")
            }
          />
        </RowItemBlock>
      ) : (
        <RowItemBlock colSpan={1}>
          <Input
            name="date"
            onChange={(e) => handleChangeInput(e, "date")}
            type="date"
            value={
              editMode
                ? dayjs(info.date, "DD/MM/YYYY").format("YYYY-MM-DD")
                : undefined
            }
          />
        </RowItemBlock>
      )}
      <RowItemBlock colSpan={1}>
        <Input
          name="patient"
          onChange={(e) => handleChangeInput(e, "patient")}
          type="text"
          placeholder={info.patient}
          defaultValue={editMode ? info.patient : undefined}
        />
      </RowItemBlock>
    </VersionBlockWrapperActive>
    <NoteWrapper active>
      <TextAreaWrapper>
        <label htmlFor="note">Note:</label>
        <textarea
          onChange={(e) => handleChangeInput(e, "note")}
          id="note"
          name="note"
          rows={3}
          cols={30}
          placeholder={info.note}
          defaultValue={editMode ? info.note : undefined}
        />
      </TextAreaWrapper>
      <ButtonsWrapper>
        <span />
        <StyledButton type="submit" onClick={handleSave}>
          Save
        </StyledButton>
      </ButtonsWrapper>
    </NoteWrapper>
  </ActiveWrapper>
);

const VersionSaveBlock = ({ handleClick }) => (
  <VersionBlockWrapperSave onClick={handleClick}>
    <RowItemBlockIcon colSpan={3}>
      <StyledSave />
      Add current
    </RowItemBlockIcon>
  </VersionBlockWrapperSave>
);

const VersionBlock = ({
  info,
  handleClick,
  active,
  handleImport,
  handleDelete,
  handleEdit,
}) => (
  <ActiveWrapper>
    <VersionBlockWrapperActive onClick={handleClick}>
      <RowItemBlock colSpan={1}>{info.name}</RowItemBlock>
      <RowItemBlock colSpan={1}>{info.date}</RowItemBlock>
      <RowItemBlock colSpan={1}>{info.patient}</RowItemBlock>
    </VersionBlockWrapperActive>
    <NoteWrapper active={active}>
      <TextAreaWrapper>
        <label htmlFor="note">Note:</label>
        <textarea
          id="note"
          name="note"
          rows={3}
          cols={30}
          disabled
          defaultValue={info.note}
        />
      </TextAreaWrapper>
      <ButtonsWrapper>
        <StyledButton tertiary onClick={handleDelete} type="button">
          Delete
        </StyledButton>
        <ButtonsInnerWrapper>
          <StyledButton onClick={handleEdit} secondary type="button">
            Edit
          </StyledButton>
          <StyledButton onClick={handleImport} type="button">
            Import
          </StyledButton>
        </ButtonsInnerWrapper>
      </ButtonsWrapper>
    </NoteWrapper>
  </ActiveWrapper>
);

const Versions = () => {
  const [saveDetails, setSaveDetails] = useState<Details>({
    name: "",
    date: "",
    patient: "",
    note: "",
  });

  const dispatch = useDispatch();
  const versions = useSelector(selectAllVersions);
  const currentConfig = useSelector(selectWholeConfig);

  const [saveMode, setSaveMode] = useState<boolean>(false);
  const [editeMode, setEditMode] = useState<boolean>(false);
  const [activeItem, setActiveItem] = useState<number | null>();

  const fetchDB = async () => {
    async function createDB() {
      await openDB("Versions", 1, {
        upgrade(db) {
          db.createObjectStore("versions", {
            keyPath: "id",
            autoIncrement: true,
          });
        },
      });
    }
    createDB().then(() => {
      console.log("DISPATCH");
      dispatch(loadStorage());
    });
  };

  useEffect(() => {
    fetchDB();
  }, []);

  const handleSave = async (e, id?) => {
    if (saveDetails.name !== "") {
      e.preventDefault();
      const db = await openDB("Versions", 1);
      const infoObject = {
        info: {
          name: saveDetails.name,
          date:
            saveDetails.date === ""
              ? dayjs(new Date(Date.now())).format("DD/MM/YYYY")
              : saveDetails.date,
          patient: saveDetails.patient,
          note: saveDetails.note,
        },
        config: currentConfig,
      };
      if (editeMode) {
        await db.put("versions", {
          ...infoObject,
          id,
        });
        setEditMode(false);
      } else {
        await db.add("versions", infoObject);
        setSaveMode(false);
      }
      fetchDB();
    }
  };

  const handleDelete = async (id) => {
    const db = await openDB("Versions", 1);
    await db.delete("versions", id);
    setActiveItem(null);
    fetchDB();
  };

  const handleOpenEdit = async (index) => {
    setSaveDetails(versions[index].info);
    setEditMode(true);
  };

  const handleChangeInput = (e, type) => {
    const { value } = e.target;
    console.log(value);

    let inputValue = value;
    if (type === "date") {
      inputValue = dayjs(new Date(value).toString()).format("DD/MM/YYYY");
    }

    setSaveDetails((prev) => ({ ...prev, [type]: inputValue }));
  };

  const handleImport = async () => {
    await dispatch(importConfig(versions[activeItem!]));
    await dispatch(setItemUI({ type: "shownGlobalModal", payload: null }));
  };

  const handleOpenBlock = (index) => {
    let activeIndex;
    setActiveItem((prev) => {
      activeIndex = index;
      if (index === prev) {
        activeIndex = null;
      }
      return activeIndex;
    });
  };

  const handleCloseEditOrSave = () => {
    if (saveMode) {
      setSaveMode(false);
    }
    if (editeMode) {
      setEditMode(false);
    }
  };

  const openActiveBlock = () => {
    setSaveDetails({
      name: "",
      date: "",
      patient: "",
      note: "",
    });
    setSaveMode(true);
  };

  return (
    <form>
      <WrapperTable>
        <thead>
          <VersionTableHeader>
            <RowItemHeader colSpan={1}>Name</RowItemHeader>
            <RowItemHeader colSpan={1}>Date</RowItemHeader>
            <RowItemHeader colSpan={1}>MRN</RowItemHeader>
          </VersionTableHeader>
        </thead>
        <TableBody>
          {saveMode ? (
            <VersionSaveBlockActive
              handleClick={() => setSaveMode(false)}
              handleChangeInput={handleChangeInput}
              handleSave={handleSave}
              saveDetails={saveDetails}
              editMode={editeMode}
              newItem
            />
          ) : (
            <VersionSaveBlock handleClick={openActiveBlock} />
          )}
          {versions.length > 0 &&
            versions.map((version, index) =>
              editeMode && index === activeItem ? (
                <VersionSaveBlockActive
                  handleClick={handleCloseEditOrSave}
                  handleChangeInput={handleChangeInput}
                  handleSave={(e) => handleSave(e, version.id)}
                  key={version.id}
                  info={saveDetails}
                  editMode={editeMode}
                />
              ) : (
                <VersionBlock
                  handleImport={handleImport}
                  active={index === activeItem}
                  key={version.id}
                  info={version.info}
                  handleClick={() => handleOpenBlock(index)}
                  handleDelete={() => handleDelete(version.id)}
                  handleEdit={() => handleOpenEdit(index)}
                />
              )
            )}
        </TableBody>
      </WrapperTable>
    </form>
  );
};

const VersionsModal = ({ handleClose }: AboutProps) => (
  <Modal>
    <Wrapper handleClick={handleClose} header="Versions">
      <Divider />
      <Versions />
    </Wrapper>
  </Modal>
);

export default VersionsModal;
