import React from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { HeaderM } from "components/atoms/Typography/Typography";
import Button from "components/atoms/Button/Button";
import Modal from "utils/Modal/Modal";
import { disconnectDevice } from "reducers/bluetoothReducer/bluetoothHelpers/asyncThunks";
import { setItemUI } from "reducers/uiReducer";
import ModalBase from "./ModalBase";

const StyledModalBase = styled(ModalBase)`
  width: 550px;
`;

const ParagraphBold = styled.p`
  ${HeaderM}
`;

const ButtonsWrapper = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 20px;
`;
type AboutProps = {
  handleClose: Function;
};

const BootloaderModeModal = ({ handleClose }: AboutProps) => {
  const dispatch = useDispatch();

  const accepted = async () => {
    await dispatch(
      setItemUI({ type: "shownGlobalModal", payload: "firmware" })
    );
  };

  const denied = async () => {
    await dispatch(disconnectDevice());
    handleClose();
  };

  return (
    <Modal>
      <StyledModalBase header="Firmware update mode" hideCloseButton>
        <ParagraphBold>
          Device is in firmware update mode, please update to use the software.
        </ParagraphBold>
        <ButtonsWrapper>
          <Button onClick={accepted}>Yes, update</Button>
          <Button secondary onClick={denied}>
            No, disconnect
          </Button>
        </ButtonsWrapper>
      </StyledModalBase>
    </Modal>
  );
};

export default BootloaderModeModal;
