import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { openDB } from "idb/with-async-ittr";

type Version = {
  info: {
    name: string;
    date: string;
    patient: string;
    note: string;
  };
  config: {
    gripPairsConfig: number[];
    controlConfig: number[];
    gripSequentialConfig: number[];
    emgThresholds: number[] | null;
    gripsPositions: {};
    interval: number[];
    fingerStrength: number[] | false;
    emgSpike: number[] | false;
    holdOpen: number[] | false;
    softGrip: number[] | false;
    emgGains: number[] | false;
    pulseTimings: number[] | false;
    coContractionTimings: number[] | false;
  };
  id: number;
};

type SliceState = {
  versions: Version[];
};

const initialState: SliceState = {
  versions: [],
};

export const loadStorage = createAsyncThunk<any>(
  "versions/loadStorage",
  async () => {
    try {
      const db = await openDB("Versions");
      const versions = await db.getAll("versions");
      return versions;
    } catch (err) {
      console.log(err);
      return false;
    }
  }
);

export const versionsSlice = createSlice({
  name: "versions",
  initialState,
  reducers: {
    addVersion: (state, action) => {
      state.versions.push(action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadStorage.fulfilled, (state, action) => {
      state.versions = action.payload;
    });
  },
});

export const selectAllVersions = (state) => state.versions.versions;

export const { addVersion } = versionsSlice.actions;

export default versionsSlice.reducer;
