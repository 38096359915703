import React, { InputHTMLAttributes } from "react";
import styled from "styled-components";

const StyledInput = styled.input`
  font-family: ${({ theme }) => theme.typography.fonts.OpenSans};
  padding-left: 5px;
`;

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
}

const Input = ({ name = "", ...props }: InputProps) => (
  <StyledInput name={name} {...props} />
);

export default Input;
