import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import DefaultLayout from "layouts/DefaultLayout";
import Divider from "components/atoms/Divider/Divider";
import { ReactComponent as Arrow } from "assets/arrow-left-solid.svg";
import { HeaderM, HeaderXL } from "components/atoms/Typography/Typography";
import { useDispatch, useSelector } from "react-redux";
import InputOptions from "components/molecules/InputOptions/InputOptions";
import { setItem } from "reducers/bluetoothReducer/bluetoothReducer";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

const Header1 = styled.h2`
  ${HeaderXL};
  color: ${({ theme }) => theme.colorPrimary};
`;

const SettingsContainer = styled.div`
  display: grid;
  grid-gap: 40px;
  max-width: 800px;

  > * {
    padding-top: 20px;
    border-top: 2px solid ${({ theme }) => theme.colorFill};
  }
`;

const ParagraphBold = styled.p`
  ${HeaderM}
`;

const InnerWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledIcon = styled(Arrow)`
  width: 36px;
  height: 41px;
  color: ${({ theme }) => theme.colorSecondary};
  margin-right: 25px;
  cursor: pointer;
`;

const ApplicationSettings = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { bluetoothMode, device } = useSelector(
    (state: any) => state.bluetooth
  );

  const handleBluetoothChange = (id, value) => {
    dispatch(setItem({ type: "bluetoothMode", payload: value.toLowerCase() }));
  };

  return (
    <DefaultLayout>
      <InnerWrapper onClick={history.goBack}>
        <StyledIcon />
        <Header1>{t("settings.app_settings")}</Header1>
      </InnerWrapper>
      <Divider margin="40px" />
      <SettingsContainer>
        {!device.connected && (
          <InputOptions
            header={t("settings.bluetooth_mode")}
            options={["Classic", "BLE"]}
            id="bluetooth"
            onChange={handleBluetoothChange}
            value={bluetoothMode === "classic" ? "Classic" : "BLE"}
          />
        )}
        <div>
          <div>
            <ParagraphBold>
              Zeus Configurator v.{process.env.REACT_APP_SOFTWARE_VERSION}
            </ParagraphBold>
            <Divider margin="20px" />
            <p>
              Copyright (C) {dayjs().format("YYYY")} Aether Biomedical Sp. z
              o.o.
            </p>
            <Divider margin="20px" />
            <p>
              The program is provided AS IS with NO WARRANTY OF ANY
              KIND,INCLUDING THE WARRANTY OF DESIGN, MERCHANTABILITY AND FITNESS
              FOR A PARTICULAR PURPOSE.
            </p>
          </div>
        </div>
      </SettingsContainer>
    </DefaultLayout>
  );
};

export default ApplicationSettings;
