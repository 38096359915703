/* eslint-disable no-underscore-dangle */
import { delay } from "bluetooth/Bluetooth/Utilities";
import BluetoothController from "./bluetoothInterface";
import { BluetoothReader, CreateFrame } from "../bluetooth/Bluetooth/Bluetooth";
import { Commands } from "../bluetooth/Bluetooth/Defines";

const blReader = new BluetoothReader();

class BluetoothWebControllerLE implements BluetoothController {
  static _instance: any;
  characteristicRead: any;
  characteristicWrite: any;
  device: any;
  connected: boolean = false;
  continueReading: boolean = true;
  commandDataTransformed: {} = {};
  readerLoop: any;
  controller: any;

  constructor() {
    if (BluetoothWebControllerLE._instance) {
      return BluetoothWebControllerLE._instance;
    }
    BluetoothWebControllerLE._instance = this;
    this.commandDataTransformed = {};
    this.connected = false;
  }

  async initiateBluetooth(): Promise<any> {
    try {
      this.continueReading = true;
      const newNavigator: any = window.navigator;
      this.device = await newNavigator.bluetooth.requestDevice({
        filters: [{ services: ["6e400001-c352-11e5-953d-0002a5d5c51b"] }],
        optionalServices: [
          "6e400001-c352-11e5-953d-0002a5d5c51b",
          "6e400002-c352-11e5-953d-0002a5d5c51b",
          "6e400003-c352-11e5-953d-0002a5d5c51b",
        ],
      });
      const server = await this.device.gatt.connect();
      const service = await server.getPrimaryService(
        "6e400001-c352-11e5-953d-0002a5d5c51b"
      );
      this.characteristicWrite = await service.getCharacteristic(
        "6e400002-c352-11e5-953d-0002a5d5c51b"
      );
      this.characteristicRead = await service.getCharacteristic(
        "6e400003-c352-11e5-953d-0002a5d5c51b"
      );
      this.connected = true;
      await delay(2000);
      this.readerLoop = await this.readWeb();
      return { status: this.connected, device: this.device };
    } catch (err) {
      console.error("There was an error opening the serial port", err);
      return false;
    }
  }

  async disconnectBluetooth() {
    if (this.controller) {
      this.controller.abort();
    }
    this.continueReading = false;
    await this.telemetryOff();
    await this.device.gatt.disconnect();
    this.connected = false;
    blReader.mIncomingBuffer = [];
    blReader.mCRC = [];
    blReader.frameIterator = 2;
    console.log("Device disconnect");
    return true;
  }

  async readWeb() {
    this.characteristicRead =
      await this.characteristicRead.startNotifications();

    this.controller = new AbortController();

    this.characteristicRead.addEventListener(
      "characteristicvaluechanged",
      (e) =>
        handleValueChanged(Array.from(new Uint8Array(e.target.value.buffer))),
      { signal: this.controller.signal }
    );

    console.log("READWEB", this.characteristicRead);

    const handleValueChanged = (value) => {
      if (this.continueReading) {
        try {
          const commandData = blReader.receiveDataFromSerialPort(
            value.slice(1, value.length)
          );
          if (commandData) {
            this.commandDataTransformed = commandData;
            const event = new CustomEvent(`received${commandData[0].command}`, {
              detail: this.commandDataTransformed,
            });
            window.dispatchEvent(event);
          }
        } catch (error) {
          console.log(error);
        }
      }
    };
  }

  async writeWeb(command, commandData) {
    const limit = 20;
    let tries = 0;
    const sendFrame = async (data) => {
      try {
        tries += 1;
        console.log("DATA SEND IN PROGRESS", data, tries);
        if (tries < limit)
          await this.characteristicWrite.writeValueWithoutResponse(data);
        console.log("DATA SEND SUCCESSFUL", data);
      } catch (e) {
        console.log(`%c${e}`, "background: #F00; color: #FFF");
        console.log("DATA SEND FAILED, TRYING AGAIN", data);
        sendFrame(data);
      }
    };

    try {
      const frame = CreateFrame(command, commandData);
      const data = new Uint8Array([0x01, ...frame]);
      sendFrame(data);
      return true;
    } catch (err) {
      console.log(err);
      return err;
    }
  }

  async queryResponseCommand(
    command,
    commandData,
    commandAwaited,
    listenMode = false
  ): Promise<any> {
    console.log("RESPONSE BLE", command);
    if (!listenMode) await this.writeWeb(command, commandData);
    return new Promise((resolve) => {
      window.addEventListener(
        `received${commandAwaited}`,
        (data: any) => {
          console.log("RESOLVED", commandAwaited, data.detail);
          resolve(data.detail);
        },
        {
          once: true,
        }
      );
    });
  }

  async telemetryOn() {
    try {
      await this.writeWeb(Commands.kStartOrStopTransmittingTelemetryData, [1]);
      return true;
    } catch (e) {
      console.log(e);
      return false;
    }
  }

  async telemetryOff() {
    try {
      await this.writeWeb(Commands.kStartOrStopTransmittingTelemetryData, [0]);
      return true;
    } catch (e) {
      console.log(e);
      return false;
    }
  }
}

export default BluetoothWebControllerLE;
