/* eslint-disable no-unused-vars */
import React, { useState, memo, useEffect } from "react";
import { changeRoute } from "utils/funcs";
import { useHistory, NavLink } from "react-router-dom";
import styled from "styled-components";
import { ReactComponent as Logo } from "assets/logo_new.svg";
import Navbar from "components/molecules/Navbar/Navbar";
import Button from "components/atoms/Button/Button";
import Divider from "components/atoms/Divider/Divider";
import { useDispatch, useSelector } from "react-redux";
import { setItem } from "reducers/bluetoothReducer/bluetoothReducer";
import { ReactComponent as Cog } from "assets/cog-solid.svg";
import { ReactComponent as CaretLeft } from "assets/caret-left-solid.svg";
import { ReactComponent as CaretRight } from "assets/caret-right-solid.svg";
import { ReactComponent as Background } from "assets/Vector1.svg";
import { useSpring, animated } from "react-spring";
import { HeaderS, TextM } from "components/atoms/Typography/Typography";
import { selectNavbarCollapsedUI, setItemUI } from "reducers/uiReducer";
import useWindowDimensions from "hooks/useWindowDimensions";
import { useTranslation } from "react-i18next";
import useBluetooth from "hooks/bluetooth/useConnect";

const Wrapper = styled.nav`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  background-color: ${({ theme }) => theme.colorPrimary};
  width: ${({ collapsed }) => (collapsed ? "50px" : "160px")};
  padding: 0px 0 20px 0;
  overflow: hidden;
  white-space: nowrap;

  a {
    z-index: 1;
    text-decoration: none;
  }

  @media (min-height: ${({ theme }) => theme.breakpoints.mediumHeight}) {
    background-color: ${({ theme }) => theme.colorSecondary};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    width: ${({ collapsed }) => (collapsed ? "50px" : "210px")};
    padding: 0px 0 40px 0;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
    width: ${({ collapsed }) => (collapsed ? "50px" : "260px")};
  }
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CollapseButton = styled.div`
  height: 35px;
  z-index: 1;
  margin: ${({ collapsed }) => (collapsed ? "0 auto" : "0 auto 0 20px")};
  color: ${({ theme }) => theme.colorSecondary};
  cursor: pointer;
  margin-top: 5px;

  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    margin-top: 20px;
    width: 35px;
    height: 35px;
  }

  svg {
    height: 100%;
    width: 100%;
  }
`;

const BottomWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${({ theme }) => theme.typography.colors.primary};
  cursor: pointer;
  z-index: 1;

  &&:hover {
    transform: scale(1.04, 1.04);
    transition: transform 0.2s;
  }

  p {
    ${HeaderS};
    font-weight: 700;
  }
`;

const StyledCog = styled(Cog)`
  width: ${({ collapsed }) => (collapsed ? "35px" : "25px")};

  @media (min-width: ${({ theme }) => theme.breakpoints.small}) {
    width: ${({ collapsed }) => (collapsed ? "35px" : "50px")};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    width: ${({ collapsed }) => (collapsed ? "35px" : "60px")};
  }
`;

const StyledLogo = styled(Logo)`
  display: block;
  padding: 15% 0;
  width: ${({ collapsed }) => (collapsed ? "40px" : "120px")};
  color: ${({ theme }) => theme.typography.colors.primary};

  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    width: ${({ collapsed }) => (collapsed ? "40px" : "160px")};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
    width: ${({ collapsed }) => (collapsed ? "40px" : "200px")};
  }
`;

const ButtonWrapper = styled.div`
  padding: 0 15px;
  margin-top: 5px;

  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    margin-top: 20px;
    padding: 0 30px;
  }
`;

const BackgroundWrapper = styled(Background)`
  display: none;
  position: absolute;
  top: 0;
  left: -5px;
  height: 93%;
  width: 109%;

  @media (min-height: ${({ theme }) => theme.breakpoints.mediumHeight}) {
    display: block;
  }
`;

const StyledAnimatedButton = styled(animated.div)`
  width: 100%;
  z-index: 1;
`;

const Sidebar = memo(() => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { device } = useSelector((state) => state.bluetooth);
  const { width } = useWindowDimensions();
  const isNavbarCollapsed = useSelector(selectNavbarCollapsedUI);
  const { bluetoothConnect, bluetoothDisconnect } = useBluetooth();

  useEffect(() => {
    if (width < 800 && !isNavbarCollapsed) {
      dispatch(
        setItemUI({
          type: "isNavbarCollapsed",
          payload: true,
        })
      );
    }
  }, [width]);

  setItem({});

  const props = useSpring({
    opacity: isNavbarCollapsed ? 0 : 1,
  });

  return (
    <Wrapper collapsed={isNavbarCollapsed} data-tour-general="sidebar">
      <InnerWrapper>
        <NavLink to="/choose-grips">
          <StyledLogo collapsed={isNavbarCollapsed} />
        </NavLink>
        <Navbar collapsed={isNavbarCollapsed} />
        <StyledAnimatedButton style={props}>
          {!isNavbarCollapsed && (
            <>
              <ButtonWrapper>
                <Button
                  data-tour-general="connect"
                  onClick={
                    device.connected ? bluetoothDisconnect : bluetoothConnect
                  }
                >
                  {device.connected ? t("disconnect") : t("connect")}
                </Button>
              </ButtonWrapper>
            </>
          )}
        </StyledAnimatedButton>
        <CollapseButton
          data-tour-general="collapse"
          collapsed={isNavbarCollapsed}
          onClick={() =>
            dispatch(
              setItemUI({
                type: "isNavbarCollapsed",
                payload: !isNavbarCollapsed,
              })
            )
          }
        >
          {isNavbarCollapsed ? <CaretRight /> : <CaretLeft />}
        </CollapseButton>
      </InnerWrapper>
      <BottomWrapper
        onClick={() => changeRoute(history, "/application-settings")}
      >
        <StyledCog collapsed={isNavbarCollapsed} />
        {!isNavbarCollapsed && (
          <>
            <Divider margin="15px" />
            <p>{t("settings.self")}</p>
          </>
        )}
      </BottomWrapper>
      <BackgroundWrapper />
    </Wrapper>
  );
});

export default Sidebar;
