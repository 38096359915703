import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const Wrapper = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #00000066;
  z-index: 999;
`;

const Modal = ({ children }) => <Wrapper>{children}</Wrapper>;

Modal.propTypes = {
  children: PropTypes.node.isRequired
};

export default Modal;
