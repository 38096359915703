/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
export enum InputSites {
  kSingleElectrode = 0,
  kDualDirectElectrodes = 1,
  kDualInvertedElectrodes = 2,
}

export enum SpeedControlStrategies {
  kThreshold = 0,
  kProportional = 1,
}

export enum GripSwitchingModes {
  kCoContraction = 0,
  kOpenOpen = 1,
  kSingleGripSwitching = 2,
  kHoldOpen = 3,
}

export enum InputOptions {
  kEMG = 0,
  kLinearTransducer = 1,
}

export enum ControlModes {
  kGripPairs = 0,
  kGripSequence = 1,
  kMorseCode = 2,
}

export enum StartOnStartup {
  kWaitForFirstCoContraction = 0,
  kNotWaitForFirstCoContraction = 1,
}

export enum SingleElectrodeMode {
  kFastRising = 0,
  kAlternating = 1,
}
